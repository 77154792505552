import axios from 'axios'


export default axios.create({
    baseURL: `${process.env.REACT_APP_BASEURL}`,
    headers: {
        'platform': "external-sales",
        'language':   localStorage.getItem("LANG")?localStorage.getItem("LANG"):"ar",
        'Content-type': 'application/json'
    }
}) 