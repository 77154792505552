import '../styles/OrderDetails.css';
 
import React, {useState,useEffect,useRef} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { edit_Address,saved_address,Clear_cart,Get_Basket ,userBranches,delete_address, allBranches} from '../api';
import { RiDeleteBin5Line } from 'react-icons/ri';
import FiEdit  from 'react-icons/fi'; 
import { Link } from 'react-router-dom';
import CleartCart from '../components/ClearCart';
import DeleteAddress from '../components/DeleteAddress';
import ErrorModal from '../components/ErrorModal';
import { BeatLoader } from 'react-spinners';
import addOrder from "../images/addOrder.svg"
import { HiOutlineBuildingOffice2} from 'react-icons/hi2';
import {FiAlertCircle} from 'react-icons/fi';
import { TbBuildingBank} from 'react-icons/tb';

import { IoLocationOutline } from 'react-icons/io5';
function AddOrder() {
const { t, i18n } = useTranslation();
const [arMsg, setArMsg]=useState();
const [enMsg, setEnMsg]=useState();
const [errModal, setErrModal]=useState(false);
const[now,setNow]=useState((localStorage.getItem("now")) ? localStorage.getItem("now") : true)
const [deleteId, setDeleteId]=useState(false);
const [deleteModal, setDeleteModal]=useState(false);
const [doneDelete, setDoneDelete]=useState(false);
const [cart, setCart]=useState(null);
const [clearModal, setClearModal]=useState(false);
const [clear, setClear]=useState();
const [arAddress, setArAddress]=useState(localStorage.getItem("addressId")?localStorage.getItem("arAddress"):"اختر من العناوين السابقة");
const [enAddress, setEnAddress]=useState(localStorage.getItem("addressId")?localStorage.getItem("enAddress"):"Choose from saved addresses");
const [addressId,setAddressId]=useState(localStorage.getItem("addressId"))

const [toggle, setToggle]=useState(false);
const [toggleBranch, setToggleBranch]=useState(false);
const [branchErr,setBranchErr]=useState(false);

const [loading, setLoading]=useState(true);
const [addresses, setAddresses]=useState([]);
const [branches, setBranches]=useState([]);
const[branchId,setBranchId]=useState(localStorage.getItem("branchId"))
const [arBranch, setArBranch]=useState(localStorage.getItem("branchId")?localStorage.getItem("arBranch"):" اختر الفرع القريب منك");
const [enBranch, setEnBranch]=useState(localStorage.getItem("branchId")?localStorage.getItem("enBranch"):"Choose a near branch");
const[branchData,setBranchData]=useState({
  branchNameEn: localStorage.getItem("branchNameEn"),
  branchNameAr: localStorage.getItem("branchNameAr"),
  enCurrency: localStorage.getItem("enCurrency"),
  arCurrency: localStorage.getItem("arCurrency")

}) 
const token= localStorage.getItem("token")

let history=useNavigate()
const [time,setTime]=useState()
const [datePicker,setDatePicker]=useState(false);
const[startDate,setStartDate]=useState(new Date().setHours(new Date().getHours() ));
const[minTime,setMinTime]=useState(new Date().setHours(new Date().getHours() + 2));
const[date,setDate]=useState(new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate());
let current = new Date();
let d = current.getDate();
let m = current.getMonth() + 1;
let y = current.getFullYear();
const [addressesIds, setAddressesIds]=useState([])


useEffect(() => {
const token= localStorage.getItem("token")
if(String(now) === "false"){
  setDatePicker(true)  
}
    if(localStorage.getItem("orderDate")){
      setStartDate(new Date(localStorage.getItem("orderDate")))
  
    }

    saved_address(token).then(response => {
      const data=response.data.data;
let arr=[]
     response.data.data.map((data)=>
     arr.push(  data.id ))

     setAddressesIds(arr);

      setAddresses(data);
}).catch(function(error){
 
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")
  
    history("/")
 }

 
 }); 
  
if(localStorage.getItem("userType")=='prepayment'){
  userBranches(token,localStorage.getItem("id")).then(response => {
    setBranches(response.data)
    setLoading(false)
  }).catch(function(error){
 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }
  
   
   }); }
  else{
    allBranches(token).then(response => {
      setBranches(response.data.data)
      setLoading(false)

    }).catch(function(error){
 
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     
     }); }


  
  //for setting default selected date
   if(d<10){
    d = '0'+d;
  }
  if(m<10){
    m = '0'+m;
  }
  let selectedDate = y + '-' + m + '-' + d;
  setDate(selectedDate); 
  
  //for setting default selected time
  let hh = current.getHours()+2;
  let mm = current.getMinutes();
  
  if(hh<10){
    hh = '0'+hh;
  }
  if(mm<10){
    mm = '0'+mm;
  }
  let selectedTime = hh + ':' + mm ;
  setTime(selectedTime)
    

  Get_Basket(token).then(response => {
    localStorage.setItem('CartId',response.data.data.cart.id) 
    localStorage.setItem("clear",false)
    localStorage.setItem("branchId",response.data.data.cart.branchId)
    localStorage.setItem("addressId",response.data.data.cart.address.id)
    setBranchId(response.data.data.cart.branchId)
    setArBranch(response.data.data.cart.branch.arName)
    setEnBranch(response.data.data.cart.branch.enName)
    localStorage.setItem("arBranch",response.data.data.cart.branch.enName)
    localStorage.setItem("enBranch",response.data.data.cart.branch.enName)

    setCart(response.data.data.cart)
if(response.data.data.cart.address.id){
  setAddressId(response.data.data.cart.address.id)
setArAddress(response.data.data.cart.address.arAddress)
setEnAddress(response.data.data.cart.address.enAddress)
}

  }).catch(function(error){
 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }
  
   
   }); 
   },[doneDelete]);
  
  
  //sets selected date
  //then sets selected time and date sperately
  const   handleChangeTime = (date) => {
  
        if (date=== new Date().toLocaleDateString()) {
          
            setStartDate(date);
            setMinTime (new Date().setHours(new Date().getHours() + 2))
         
        } else { 
           
                setStartDate(date);
                setMinTime( new Date(new Date().setHours(9, 30)));
    
         
        }
    
        let d = date.getDate();
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
      
      
        if(d<10){
            d = '0'+d;
        }
        if(m<10){
            m = '0'+m;
        }
        let selectedDate = y + '-' + m + '-' + d;
    
        let hh = date.getHours();
        let mm = date.getMinutes();
    
        if(hh<10){
            hh = '0'+hh;
        }
        if(mm<10){
            mm = '0'+mm;
        }
    
        let selectedTime = hh + ':' + mm ;
    
       
            setDate(selectedDate);
            setTime( selectedTime);
            localStorage.setItem("date",selectedDate)
            localStorage.setItem("time",selectedTime)

    
    };
  

    function handleOptionChange(e){
        const val= e.target.value
        if( val=="true"){
         setNow(true)
          setDatePicker(false);
          localStorage.setItem("now",true)
        }
        else{
          setNow(false)
         setDatePicker(true);
         localStorage.setItem("now",false)

        }
       
       } 





  


//on selecting address
//address data is stored 
function  selectAddress (addressId,arAddress,enAddress) {


  setAddressId(addressId)     
  localStorage.setItem("addressId", addressId);
  localStorage.setItem("arAddress", arAddress);
  localStorage.setItem("enAddress", enAddress);
  setArAddress(arAddress)
  setEnAddress(enAddress)
  setToggle(false)
  if(cart ){
    edit_Address(token,parseInt(addressId),localStorage.getItem('CartId')).then(response => {


    }).catch(function(error){
 
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     
     }); 


    }
 
}

function selectBranch(id,arName,enName){

  if((cart && cart.branchId!==id ) && localStorage.getItem("clear")=='false'){
    setClear({
      type:"branch",
      branchId: id,
      branchNameAr:arName,
      branchNameEn:enName

    })
setClearModal(true)
  }
  else{
    setBranchErr(false)

    localStorage.setItem("clear",false)
    setBranchId(id); 
    setArBranch(arName)
    setEnBranch(enName)
    setToggleBranch(false)

    localStorage.setItem("arBranch",arName)
    localStorage.setItem("enBranch",enName)
    localStorage.setItem("branchId",id)
  }
}
   
   //on confirimg delete address in DelAddress modal 
   //delete_address api takes address id (this id gets set when user clicks on an AddressCard delete button)
function handleDelete(){

  const token= localStorage.getItem("token");
  setLoading(true);
  delete_address(deleteId,token).then(response => {
  
    setDoneDelete(!doneDelete); //to rerender screen and update displayed addresses
    setDeleteModal(false)
 }).catch(function(error){
 
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")
  
    history("/")
 }

 
 }); 

}

function handleProceed(){

  if((String(localStorage.getItem("now")) === "false" && localStorage.getItem("date") === "") ||(String(localStorage.getItem("now")) === "false" && localStorage.getItem("date") === null)  || (String(localStorage.getItem("now")) === "false" && localStorage.getItem("time") === "") || (String(localStorage.getItem("now")) === "false" && localStorage.getItem("time") === null)){
         
    setArMsg("أختر تاريخ وميعاد الطلب")
    setEnMsg("Choose Order Date & Time")
      setErrModal(true);
      setLoading(false)
      setTimeout(function() {
    
          setErrModal(false)
        }, 2500)
         
}
else if((String(localStorage.getItem("now")) === "false" && localStorage.getItem("date") !== "") || (String(localStorage.getItem("now")) === "false" && localStorage.getItem("time") !== "")){
    let pickedDate = new Date(localStorage.getItem("date")+" "+localStorage.getItem("time")).getTime();

    let todaysDate = new Date();

    let day = todaysDate.getDate();
    if(day < 10){
        day = '0'+day;
    }

    let month = todaysDate.getMonth()+1;
    if(month < 10){
        month = '0'+month;
    }

    let hours = todaysDate.getHours();
    if(hours < 10){
        hours = '0'+hours;
    }

    let mins = todaysDate.getMinutes();
    if(mins < 10){
        mins = '0'+mins;
    }

    let todaysDateFormated = todaysDate.getFullYear()+"-"+month+"-"+day;
    let currentDate = new Date(todaysDateFormated+" "+hours+":"+mins+":00").getTime();

    if(parseInt(pickedDate) < parseInt(currentDate)){
        
        setArMsg("أختر تاريخ و وقت صحيح")
        setEnMsg("Choose a valid date & time")
          setErrModal(true);
          setLoading(false)
          setTimeout(function() {
        
              setErrModal(false)
            }, 2500)
      
    }
  else{ 
    if(branchId === "" || branchId === null ){

      setBranchErr(true)
      setArMsg( "أختر الفرع أولا")
      setEnMsg("Choose Branch First")
            setLoading(false)
          
        }
      
        else{
          history("/menu")
        }}
      }
      else{ 
        if(branchId === "" || branchId === null){
          setBranchErr(true)

          setArMsg( "أختر الفرع أولا")
                    setEnMsg("Choose Branch First")
                setLoading(false)
              
            }
          
            else{
              history("/menu")
            }}
}


function handleClear(){
  localStorage.setItem("clear",true)

  const token= localStorage.getItem('token');
Clear_cart(localStorage.getItem('CartId') ,token).then(response => {
setCart(null)
setClearModal(false)

if(clear.type=="branch"){

 selectBranch(clear.branchId,clear.branchNameAr,clear.branchNameEn,clear.enCurrency,clear.arCurrency)
}
else if(clear.type=="address"){

 selectAddress(clear.addressId,clear.arAddress,clear.enAddress)
}

 }).catch(function(error){
 
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")
  
    history("/")
 }

 
 }); 
 



 }


 function handleUncheck(index,addressId,arAddress,enAddress){

  let radio = document.getElementsByName('orderAddress');
  if( radio[index].checked=true){
    localStorage.removeItem("addressId");
    radio[index].checked=false
    setAddressId(null)
    setArAddress("اختر من العناوين السابقة")
    setEnAddress("Choose from saved addresses")
  }

  else if(cart ){
    edit_Address(token,addressId,localStorage.getItem('CartId')).then(response => {
      radio[index].checked=true
      setAddressId(addressId)
      localStorage.setItem("addressId",addressId);
      setArAddress(arAddress)
      setEnAddress(enAddress)

    }).catch(function(error){
 
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     
     }); 


    }


}
function handleClick(address){
  document.getElementById(address).click();
}

   return (
     <>
     <div className="addOrder">

         {deleteModal?<DeleteAddress setModal={setDeleteModal} handleDelete={handleDelete}/>:null}
         {errModal? <ErrorModal setModal={setErrModal} msg={i18n.language=="ar"? arMsg:enMsg}/>:null}
         {clearModal?<CleartCart handleClear={handleClear} setModal={setClearModal} />:null}
<div className='addOrderBckg' style={{ backgroundImage: `url(${addOrder})` }}>
<p className='addOrderTitle'> {t("process.addOrder")}</p>
<div className='addCard'>
<p className='orderTitle'> {t("process.details")}</p> 



                                            <div className="row gy-5">
     <div  className="col-lg-6">
       <div onClick={()=>(setToggle(!toggle))} className={i18n.language=="ar"?'addressInput':'addressInput addressInputEn'}>
      
      <div style={{display:"contents"}}>
      <IoLocationOutline color='#FF7E00' size={30}/>
 
      <p style={i18n.language=="ar"?{marginLeft:"auto"}:{marginRight:"auto"}} className='overFlowElips' >{i18n.language=="ar"?arAddress:enAddress}</p>    

    
       </div> 
       <Link to="/profile/my-addresses" onClick={localStorage.setItem("prevScreen","order")}>
<button>{t("addOrder.addAddress")}</button></Link>
       </div>
       {toggle?
     <div className='addressChoice'>
    {addresses.length==0?
   <p className='noResult'>{t("addOrder.noResult")}</p>
   : 
     <div className='row gy-4' style={{marginTop:"0"}}>
 
{
addresses && addresses.map((data,index)=>
 
  <div className='col-12 flexBetween' style={addresses.length>1?{marginBottom:"16px"}:null}>
  <div  style={{cursor:"pointer"}} onClick={()=>handleClick(`${"address"+data.id}`)}>
  <HiOutlineBuildingOffice2 style={{position:"relative",bottom:"2px"}} color='#BDBDBD' size={24}/>
<input  id={"address"+data.id} onClick={()=>handleUncheck(index,data.id,data.arAddress,  data.enAddress)}  value={data.id} type="radio"  name="orderAddress" checked={parseInt(localStorage.getItem("addressId"))==data.id}  onChange={()=>{ selectAddress(data.id, data.arAddress,  data.enAddress); }}  defaultChecked={parseInt(addressId) === data.id ? true: false}/>

<p  > {i18n.language=="ar"? data.arAddress  : data.enAddress } </p>
</div> 

<div style={{whiteSpace:"nowrap"}}>
<button className='editBtn' style={{color:"#db095b"}} onClick={()=>{setDeleteModal(true)
    setDeleteId(data.id)
    }} > <RiDeleteBin5Line/> {t("profile.del")}</button>  

</div>



</div>
  )}

     </div>}
    
     </div>:null}</div>
     <div  className="col-lg-6">
       <div style={ i18n.language=="ar"?{marginRight:"auto"}:{marginLeft:"auto"}} onClick={()=>(setToggleBranch(!toggleBranch))} className={branchErr? i18n.language=="ar"?'addressInput addressInputErr':'addressInput addressInputEn addressInputErr':i18n.language=="ar"?'addressInput ':'addressInput addressInputEn '}>
      
      <div>
      <IoLocationOutline color='#FF7E00' size={30}/>
       <p>{i18n.language=="ar"?arBranch:enBranch}</p>    
       </div> 
<button onClick={()=>(setToggleBranch(!toggleBranch))}>{t("addOrder.choose")}</button>
       </div>
       {toggleBranch?
     <div style={ i18n.language=="ar"?{marginRight:"auto"}:{marginLeft:"auto"}} className="addressChoice">
    {branches.length==0?
   <p className='noResult'>{t("addOrder.noResult")}</p>
   : 
     <div className='row gy-4' style={{marginTop:"0"}}>
 
{
branches && branches.map((data,index)=>
 
  <div className='col-12 flexBetween' style={branches.length>1?{marginBottom:"16px"}:null}>
  <div style={{cursor:"pointer"}} onClick={()=>{selectBranch(data.id,data.nameAr? data.nameAr:data.arName , data.nameEn?data.nameEn:data.enName )}}>
  <TbBuildingBank style={{position:"relative",bottom:"2px"}} color='#BDBDBD' size={24}/>

<p  >{i18n.language=="ar"?data.nameAr? data.nameAr:data.arName : data.nameEn?data.nameEn:data.enName  }</p>
</div> 




</div>
  )}

     </div>}
    
     </div>:null}
    { branchErr?  <p className='errorMsg'> <FiAlertCircle/><span>{i18n.language=="ar"?arMsg:enMsg}</span> </p>:null}
     </div>
     
     </div>
     <div className="cpRadio" style={{marginTop:"0"}}> 
    <p className="cpForm" >  {t("singleOrder.order_time")}</p>
    <div className="row">
    <div  className="col-xs-8 col-6 alignCenter">
    <input value={true} type="radio" name="time"  defaultChecked={now === true || now === "true"? true: false}   onChange={(e)=>handleOptionChange(e)} />
    <label>{t("process.now")}</label>
    </div>
    <div className="col alignCenter">
    <input value={false} type="radio" name="time" defaultChecked={now==false || now === "false"?true:false}  onChange={(e)=>handleOptionChange(e)} />
    <label>{t("process.another")}</label></div></div>
    </div>
    {datePicker==true?
     <div className="row">
     <div  className="col-xs-8 col-6">
     
     </div>
     <div className="col-xs-4 col-6">
    <div style={{marginTop:"1em"}} className={i18n.language=="ar"?"cpRadio datePicker datePickerAr":"cpRadio datePicker"}>
    <DatePicker
        required
                                              selected={startDate}
                                                showTimeSelect
                                                dateFormat=" h:mm ,yyyy/MM/dd"
                                                minTime={minTime}
                                                maxTime={new Date(new Date().setHours(23, 59))}
                                                minDate={new Date()}
                                                timeFormat="HH:mm"
                                                onChange={handleChangeTime}
                                            /></div> </div></div>   :null}
                                            

                                            <button className='continue' onClick={handleProceed}>{t("process.next")}</button>

                                            </div>
</div>
</div>
  
     {/*
    <div className="addOrder">
         {deleteModal?<DeleteAddress setModal={setDeleteModal} handleDelete={handleDelete}/>:null}
         {errModal? <ErrorModal setModal={setErrModal} msg={i18n.language=="ar"? arMsg:enMsg}/>:null}
         {clearModal?<CleartCart handleClear={handleClear} setModal={setClearModal} />:null}

    <div className="container-fluid fluid ">
        <div className="cpMiddle-title2">
       
       <p> {t("process.details")}</p> 
       </div>
       <div className="cpMiddle-options">
           <div className="cpRadio" style={{marginTop:"0"}}> 
    <p className="cpForm" >  {t("process.order_time")}</p>
    <div className="row">
    <div  className="col-md-4">
    <input value={true} type="radio" name="time"  defaultChecked={now === true || now === "true"? true: false}   onChange={(e)=>handleOptionChange(e)} />
    <label>{t("process.now")}</label>
    </div>
    <div className="col">
    <input value={false} type="radio" name="time" defaultChecked={now==false || now === "false"?true:false}  onChange={(e)=>handleOptionChange(e)} />
    <label>{t("process.another")}</label></div></div>
    </div>
    {datePicker==true?
    <div style={{marginTop:"1em"}} className={i18n.language=="ar"?"cpRadio datePicker datePickerAr":"cpRadio datePicker"}>
    <DatePicker
        required
                                              selected={startDate}
                                                showTimeSelect
                                                dateFormat=" h:mm ,yyyy/MM/dd"
                                                minTime={minTime}
                                                maxTime={new Date(new Date().setHours(23, 59))}
                                                minDate={new Date()}
                                                timeFormat="HH:mm"
                                                onChange={handleChangeTime}
                                            /></div>
    :null}
    
       
   
    <div className="cpRadio " style={{marginTop:"1em"}}> 
    <p className="cpForm" >  {t("process.order_address")}</p>
  

<div className='row gy-4'>
  <div className='col-12'>
  <Link to="/profile/my-addresses" onClick={localStorage.setItem("prevScreen","order")}><button className='addBtn' style={{width:"100%"}}>{t("addOrder.addAddress")}</button></Link>

  </div>
{
addresses && addresses.map((data,index)=>
    
    <div className='col-6' style={{display:"flex",flexDirection:"column"}}>
    <div  >
<input  id={"address"+data.id} onClick={()=>handleUncheck(index,data.id)}  value={data.id} type="radio"  name="orderAddress" checked={parseInt(localStorage.getItem("addressId"))==data.id}  onChange={()=>{ selectAddress(data.id, data.arAddress,  data.enAddress); }}  defaultChecked={parseInt(addressId) === data.id ? true: false}/>

<label  htmlFor={"address"+data.id} >
  

  {i18n.language=="ar"? data.arAddress  : data.enAddress } </label>
</div> 

<div style={{flex:"1",alignItems:"end",display:"flex"}}>
  <button className='editBtn' style={{color:"#db095b"}} onClick={()=>{setDeleteModal(true)
      setDeleteId(data.id)
      }} > <RiDeleteBin5Line/> {t("profile.del")}</button>  

</div>



</div>

    )}
    </div>

    </div>
 
    <p className="cpForm" style={{textAlign:"start",marginTop:"1em"}}>  {t("addOrder.branches")}</p>

<div className='container-fluid'>
{loading?
               <div style={{height:"40vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
  <div className='row'>

 
{branches.length>0?


branches && branches.map((data, index) => 


<div className={data.id==branchId?'branchSelectActive branch col-lg-3 col-sm-4 col-6':'branch col-lg-3 col-sm-4 col-6'} onClick={()=>{selectBranch(data.id)}}>
  <div>
  {i18n.language=="ar"?data.nameAr? data.nameAr:data.arName : data.nameEn?data.nameEn:data.enName  }</div></div>

):
<p className='noResult'>{t("addOrder.noResult")}</p>
}

</div>}


</div>
</div> 
       <div className="cartNext">

     <button onClick={handleProceed}>{t("process.next")}</button>

     </div>
    </div>
    
</div>*/}
          </>

    );
}

export default AddOrder;