import AxiosProvider from "./AxiosProvider";

export const countries = (data) => {
    return AxiosProvider.get('countries', data)
}

export const terms = (values) => {
    return AxiosProvider.get('infos/terms', values,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }})
}
export const editPicture = (photo,token) => {
  let body={
      photo,
      _method: "PUT"
  }
              return AxiosProvider.post('customers/edit-photo',photo,{
                  headers: {
                      'Authorization': 'Bearer ' + token,
                    
                      'Content-Type': 'multipart/form-data',
                      'Accept': 'application/json'
                  }})
          }
          export const verifyPhone = (values,token) => {
            return AxiosProvider.put('customers/edit-phone', values,{
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }})
        }
        export const getAddresses = (token) => {
          return AxiosProvider.get('/external-sales-panel/addresses',{
              headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' +token,
              },
              params: {
                applicationId: 6
              }
          
            }
              
              )
        }
        export const create_order = (data,token,lang) => {
        
          return AxiosProvider.post(`/external-sales-panel/cart/order`, data,
          {   headers: {
              Authorization:  `Bearer ${token}`
             , Accept: 'application/json'
          },  params:{
            lang:lang
          }})
      }

      export const check_offer = (data,token) => { 
        var body={
   
            paymentMethod:data.payment,
            code:data.code,
              
              }
        return AxiosProvider.post(`/cart/offer`, body,
        {   headers: {
            Authorization:  `Bearer ${token}`
           , Accept: 'application/json'
        }})
    }

        export const updateCustomer = (values,token) => {
          return AxiosProvider.put('customers', values, {
              headers: {
                
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
              }})
      }
      
      export const repeat_order = (token,orderId) => {
        return AxiosProvider.post(`orders/${orderId}/repeat`,{},{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }})
    } 
       
    export const order_track = (id,token) => {
      return AxiosProvider.get('/external-sales-panel/orders/'+id,{
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }})
  }
  export const pay_again = (orderId,token) => {
    return AxiosProvider.get( 'orders/' + orderId + '/payment-url', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }})
} 
export const payment = (id,token) => {
  return AxiosProvider.get('/orders/tap/payment-status/'+id,{
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      }})
}
export const invoiceData = (id,code) => {
  return AxiosProvider.get(`/orders/invoice/${id}/${code}`)
}
export const cancel_request = (message,token) => {
  return AxiosProvider.post( 'chats', message, {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      }})
} 
export const Clear_cart = (cartId,token) => {
  return AxiosProvider.delete(`external-sales-panel/cart/${cartId}`,  {   headers: {
      Authorization:  `Bearer ${token}`
     , Accept: 'application/json'
  },
 params:{
   applicationId:6
 }})
}

export const addToCart = (token,data) => {
   
    return AxiosProvider.post(`external-sales-panel/cart`, data,
    {   headers: {
      Authorization:  `Bearer ${token}`
     , Accept: 'application/json'
    }})
    }

    export const Get_Basket = (token) => {
        return AxiosProvider.get(`/external-sales-panel/cart`,  {   headers: {
            Authorization:  `Bearer ${token}`
           , Accept: 'application/json'
        }})
    }



export const products = (token,categoryId,branchId,) => {

    let params = {
      categoryId: categoryId,
      branchId: branchId,
      type: "delivery",
      applicationId: 6
  };
  
    return AxiosProvider.get('external-sales-panel/products',{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
    },
    params
  }
        
        )
  } 
      
  export const saved_address = (token) => {
    return AxiosProvider.get('external-sales-panel/addresses',{
        headers: {
            'Authorization': `Bearer ${token}`,
        
            'Accept': 'application/json'
        }})
}

export const forgetOTP = (data) => {
  let headers = {
      'Content-Type': 'application/json',
  };
  let params = {
      type: "OTP",
  };

  return AxiosProvider.post('external-sales-panel/external-sales/login',data, {
      params,
      headers})
}
export const resendCode = (params) => {
  
  return AxiosProvider.get('external-sales-panel/external-sales/OTP-SMS-send',{params, 
      headers: {
    
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        
      }})
}  
export const checkPhone = (params) => {
  
  let  headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    return AxiosProvider.get('external-sales-panel/external-sales/check-phone',{params, headers})
}
   

export const usercode = (values,token) => {

  return AxiosProvider.post('customers/verify-user', values,{
      headers: {
        'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        
      }})
}

export const forgetPassChange = (token,values) => {
  return AxiosProvider.put('external-sales-panel/external-sales', values,{
      headers: {
        
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
      }})
}

  export const delete_address = (id,token) => {
    return AxiosProvider.delete(`/external-sales-panel/addresses/` + id,
      {   headers: {
        Authorization:  `Bearer ${token}`
       , Accept: 'application/json'
    }})
}
  export const Delete_Item = (cartId,id,token) => {
    return AxiosProvider.delete(`external-sales-panel/cart/${cartId}/meal/${id}`,  {   headers: {
        Authorization:  `Bearer ${token}`
       , Accept: 'application/json'
    }})
  }
  
  export const categories = (token) => {
    let params = {
      applicationId: 6,
    
  };
  
    return AxiosProvider.get('external-sales-panel/categories',{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
    },
    params
  }
        
        )
  }
  
    export const product = (token,productId,branchId) => {
  
     
    let params = {
      branchId: branchId,
      applicationId: 6,
  }
    
      return AxiosProvider.get('products/'+productId,{
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
      },
      params
    }
          
          )
    }
  
    export const application = () => {
  
     
      
        return AxiosProvider.get('application',{
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
      }
            
            )
      }


   
export const login = (values) => {
  return AxiosProvider.post('/external-sales-panel/external-sales/login', values,{
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      }})
} 
export const SMS = (headers) => {
  return AxiosProvider.get('customers/phone-verification-SMS-send',{
      headers})
}




export const CustomerInfo = (token) => {
  return AxiosProvider.get('external-sales-panel/external-sales/account-info',{
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      }})
} 
export const orderBranches = (token,params) => {
  return AxiosProvider.get('branches',{
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
  },
  params
}
      
      )
}


export const district = (token) => {
  return AxiosProvider.get('customer-service-panel/districts',{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' +token,
      }
  
    }
      
      )
} 

  
  export const myOrders = (token,page,statusArr) => {
  
      let queryParams = `statusIDArr=${encodeURIComponent(JSON.stringify(statusArr))}`;
      return AxiosProvider.get( `external-sales-panel/orders`, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          },
          params:{  statusIDArr:statusArr,
              page:page}
      
      
      })
  } 

 

  export const Get_Wallet = (token) => {
    return AxiosProvider.get(`/external-sales-panel/external-sales/wallets/wallet-balance`,  {   headers: {
        Authorization:  `Bearer ${token}`
       , Accept: 'application/json'
    }})
}

export const charge_wallet = (token,data) => {
    return AxiosProvider.post(`external-sales-panel/wallets`,data, 
     {   headers: {
        Authorization:  `Bearer ${token}`
       , Accept: 'application/json',
       countryId:1
    }})
}

export const Edit_Quantity = (cartId,mealId,quantity,token) => {
    var body={
        quantity: quantity
    }
        return AxiosProvider.put(`external-sales-panel/cart/${cartId}/meal/${mealId}/quantity` ,body,{   headers: {
            Authorization:  `Bearer ${token}`
           , Accept: 'application/json'
        }})
    }
  
    export const branches = (cityId) => {
      return AxiosProvider.get('branches',{
        headers: {
          'Accept': 'application/json',
          
          'Content-Type': 'application/json',
      },
      params: {
          type: 'city',
          class: 'branch',
          cityId: cityId,
      }
    }
          
          )
    }
    export const addAddress = (token,values) => {
      return AxiosProvider.post('external-sales-panel/addresses',values,{
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' +token,
              'Accept': 'application/json',
          }})
    }  

export const areaByBranch = (token,branchId) => {
  return AxiosProvider.get(`customer-service-panel/branches/${branchId}/areas`,{
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
  }
}
      
      )
}

export const areas = (cityId,token) => {
  return AxiosProvider.get('external-sales-panel/cities/all/areas',{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token

      } ,
    params: {
      cityId:cityId,
       
    }}
      
      )
}

export const register = (values,lang) => {
  return AxiosProvider.post('external-sales-panel/external-sales/register',values,{
      headers: {
          'Content-Type': 'application/json',
       
          'Accept': 'application/json'
      },
    params:{
      lang:lang
    }})
} 
export const registerAdv = (token,id,values,lang) => {
  return AxiosProvider.put(`external-sales-panel/external-sales/prepayment`,values,{
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json'
      },  params:{
        lang:lang
      }})
}  


export const companyActivities = () => {
  return AxiosProvider.get(`external-sales-panel/external-sales/get-activities`,{
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
  }
}
      
      )
} 
export const activityTypes = () => {
  return AxiosProvider.get(`external-sales-panel/external-sales/get-facility-types`,{
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
  }
}
      
      )
}


export const register1 = (token,id,values,lang) => {
  return AxiosProvider.put(`external-sales-panel/external-sales/postpaid/first-step`,values,{
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,

          'Accept': 'application/json'
      },  params:{
        lang:lang
      }})
} 

export const register2 = (token,id,values,lang) => {
  return AxiosProvider.put(`external-sales-panel/external-sales/postpaid/second-step`,values,{
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,

          'Accept': 'application/json'
      },  params:{
        lang:lang
      }})
} 

export const register3 = (token,id,values,lang) => {
  return AxiosProvider.post(`external-sales-panel/external-sales/postpaid/third-step`,values,{
      headers: {
        'Authorization': 'Bearer ' + token,
                  
                    'Content-Type': 'undefined',
                    'Accept': 'application/json'
      },  params:{
        lang:lang
      }})
} 

export const   sendCode= (token,id) => {
  return AxiosProvider.put(`external-sales-panel/external-sales/delegate/verification-code/${id}`,null,{
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,

          'Accept': 'application/json'
      }}) 
} 
export const otp = (token,id,code,lang) => {
  return AxiosProvider.get(`external-sales-panel/external-sales/verify-code/${id}`,{
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,

          'Accept': 'application/json'
      },
    params:{
      verificationCode:code,
      lang:lang
    }})
} 
export const userData = (token) => {
  return AxiosProvider.get(`/external-sales-panel/external-sales`,{
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      }})
} 
export const updateBasicData = (token,id,data,lang) => {
  return AxiosProvider.put(`/external-sales-panel/external-sales/update-register-info`,data,{
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
      },  params:{
        lang:lang
      }})
} 


export const userBranches = (token,userId) => {
  return AxiosProvider.get(`external-sales-panel/external-sales/prepayment-branches`,{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token

      }}
      
      )
}
export const allBranches = (token) => {
  return AxiosProvider.get(`/external-sales-panel/branches?type=all&applicationId=6`,{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token

      }}
      
      )
}

export const payment_methods = (token,cartId) => {
 
  return AxiosProvider.get(`/external-sales-panel/cart/${cartId}/payment-methods?deliveryType=ordinary&applicationId=6`,{
   headers: {
      Authorization:  `Bearer ${token}`
     , Accept: 'application/json'
  }})
}

export const payAgain_checkout = (token,paymentToken,orderId) => { 
  var body={
      token:paymentToken,
      applicationId:6
        }
  return AxiosProvider.post(`/orders/${orderId}/repay`, body,
  {   headers: {
      Authorization:  `Bearer ${token}`
     , Accept: 'application/json'
  }})
}

export const checkPayment = (token,sessionId,type) => {
  return AxiosProvider.get( `/external-sales-panel/orders/checkout/get-paymentlink-details?paymentLinkId=${sessionId}`, {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          'countryId': 1
      },params:{
          type:type,
          platform: "external-sales",
         
      }})
} 


export const recieved = (token,orderId) => { 

  return AxiosProvider.get(`/external-sales-panel/orders/recieve-order/${orderId}`,
  {   headers: {
      Authorization:  `Bearer ${token}`
     , Accept: 'application/json'
  }})
}


export const changePassword = (values,token) => {
  
  return AxiosProvider.put( 'external-sales-panel/external-sales/change-password',values, {
      headers: {
    
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token,
        
      }})
}

export const edit_Address = (token,addressId,cartId) => {
  let  body=
  {
    addressId:addressId
    }
  return AxiosProvider.put(`external-sales-panel/cart/${cartId}/address`,body,
  {   headers: {
      Authorization:  `Bearer ${token}`
     , Accept: 'application/json'
  }})
}

export const myTransactions = (token) => {
  return AxiosProvider.get('external-sales-panel/wallets',{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' +token,
      }
  
    }
      
      )
}

export const requestDocument = (token,data,lang) => {
  return AxiosProvider.post('external-sales-panel/external-sales/make-request',data,{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' +token,
      },  params:{
        lang:lang
      }
  
    }
      
      )
}
export const getDocuments = (token,page) => {
  return AxiosProvider.get('external-sales-panel/external-sales/all-requests',{
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' +token,
      },
      params:{
        page:page
      }
  
    }
      
      )
}