import '../styles/Login.css';

import decoration from "../images/decoration.svg"

import chicken2 from "../images/newChicken.png"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
 import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate,useLocation } from "react-router-dom";
import { login } from '../api';
import { SMS, userData } from '../api';
function Login(props) {
const [hidden,setHidden]=useState(false);
const { t, i18n } = useTranslation();
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [loading, setLoading]=useState(true);
var dt = new Date();
const year= dt.getFullYear()
const [errorMsgModel, setErrorMsgModel]=useState(false);
let history = useNavigate();



let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
 
const Schema= yup.object().shape({
phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
password: yup.string().min(6,("validation.at_least")).required(("validation.password_required")),

})


const formik = useFormik({
initialValues:{
  phone:"",
  password:"",
},
validationSchema: Schema,
onSubmit

});

useEffect(() => {
 const token= localStorage.getItem("token")
if(token){
  setLoading(true)
   userData(token,localStorage.getItem("id")).then(response => {
  
    if(response.data.data.externalSaleType!=="" && response.data.data.externalSaleType!==null){
      if(response.data.data.externalSaleType=="prepayment"){
          if(response.data.data.lastCompletedStep==null){
            history("/adv-payment")
        
          }
          else{
            history("/add-order")
       
              localStorage.removeItem("branchId");
              localStorage.removeItem("addressId")
    

          }
        }
        else{
          if(response.data.data.lastCompletedStep==null){
            history("/register-1")
          }
          else if(response.data.data.lastCompletedStep==1){
            history("/register-2")
        
          }
          else if(response.data.data.lastCompletedStep==2){
            history("/register-3")
        
          }
          else{
            history("/add-order")
              localStorage.removeItem("branchId");
              localStorage.removeItem("addressId")
    
 
          }
        }}

   }).catch(function(error){
  
            
                localStorage.removeItem("token");
                localStorage.removeItem("phone");
                localStorage.removeItem("name");
                localStorage.removeItem("fieldsArr")
              
                setLoading(false)

             
           })
          }
          else{
            setLoading(false)

          }
          },[]);


const passRef=useRef();
    const phoneRef=useRef();





    useEffect(() => {
   
      window.scrollTo(0, 0);

    },[])

//onSubmitting for login (takes: phone number and password):
//if user data is old (determined by api) user is redirected to "/code" , and his phone number,token and verification state are stored for use in other screens
//if user is verified his data is fetched (using CustomerInfo api call) then stored then he's directed to the home screen
//if user is not verified, user is redircted to "/verify" and sent an otp
  

 async function onSubmit(event){
      setLoading(true)
      const data={
   
       phone: phoneRef.current.value,
       password: passRef.current.value,

      }
        
//postpaid =>3 steps
         login(data).then((response) => {
          localStorage.setItem("token",response.data.data.access_token)
          props.setUserName(response.data.data.name)
localStorage.setItem("name",response.data.data.name)
props.setUserName(response.data.data.name)
localStorage.setItem("phone",response.data.data.phone)
localStorage.setItem("userType",response.data.data.type)
localStorage.setItem("lastStep",response.data.data.lastCompletedStep)
localStorage.setItem("id",response.data.data.id)



      if(response.data.data.type=="" || response.data.data.type==null){

        localStorage.setItem("userType","")
        history("/confirm")
      }
      else{
if(response.data.data.type=="prepayment"){
  if(response.data.data.lastCompletedStep==null){
    history("/adv-payment")

  }
  else{
    history("/add-order")
      localStorage.removeItem("branchId");
      localStorage.removeItem("addressId")


  }
}
else{
  if(response.data.data.lastCompletedStep==null){
    history("/register-1")
  }
  else if(response.data.data.lastCompletedStep=="first"  ){
    history("/register-2")

  }
  else if(response.data.data.lastCompletedStep=="second"  ){
    history("/register-3")

  }
  else{
    history("/add-order")
      localStorage.removeItem("branchId");
      localStorage.removeItem("addressId")


  }
}
      }
                 
                 }).catch(function(error){
                
                           setMsgAr("خطأ في رقم الهاتف او كلمة المرور");
   
                           setMsgEn("Invalid phone or password");
                           setErrorMsgModel(true);
                           setLoading(false)
     
                   })
     
                   }


//for hiding and showing password

function show() {

    setHidden(!hidden);
    var x = document.getElementById("pass");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

   return (
        <div> 
       
         
       

<div  >

<div style={{minHeight:"calc(100vh - 41px)"}} className='row'>
<div style={{maxHeight:"calc(100vh - 41px)"}} className='col-md-6 homeContainer loginAlign'>
{loading?
      <div style={{height:"60vh",width:"100%"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<div style={{width:"100%"}}>

    <div className='loginTitle'>
<p > {t("Login.login")}</p></div>
<form className='loginForm'  name="loginForm" onSubmit={formik.handleSubmit}>
<label>  {t("Login.byPhone")}</label>
<input type="text" maxLength={10}  placeholder= {t("Login.byPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.phone && formik.touched.phone || errorMsgModel?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
<label> {t("Login.pass")} </label>
<div className='loginPass'> 
<input id="pass" style={{width:"100%"}}  type="password"  value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password || errorMsgModel?"invalid":null}/>
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span>
{hidden?
  <AiOutlineEyeInvisible onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<Link to="/otp"><p style={i18n.language=="ar"?{right:"auto",left:"0"}:null}  className={formik.errors.password && formik.touched.password?"forgot forgotInvalid":"forgot"}> {t("Login.forget")}</p></Link>
</div>

{i18n.language=="ar"?
<span  style={{margin:"2em 0"}}>{errorMsgModel? msgAr:null}</span>:
<span  style={{margin:"2em 0"}}>{errorMsgModel? msgEn:null}</span>}
<button style={!errorMsgModel? {marginTop:"3em "} :null}type="submit"> {t("Login.start")}</button>
</form>
<div className='register'>
<Link to="/register">
<p>    {t("Login.dontHave")} <span className='regColor'>{t("Login.register")} </span><span className='underline'>t</span></p></Link>
</div></div>}
</div> 
<div style={{maxHeight:"calc(100vh - 41px)"}}  className='col-md-6 loginBckg'>
<div  className='bckg'  >
<div  className={i18n.language=="ar"?'decorationLogin':'decorationLogin decorationLoginEn'}  style={{backgroundImage:`url(${decoration})`}}>
</div>
<div className="chickenDecore" >
  <p>{t("Login.bckgText")}</p>
<div   style={{backgroundImage:`url(${chicken2})`}}>
</div>
</div>


</div>
</div>
</div>
<div className="footerLink" style={ {backgroundColor:"transparent"}} >
<p >{t("Login.copy_write_parg")}<span style={i18n.language=="ar"? {marginRight:"5px"}:{marginLeft:"5px"}}>
            © {year}   </span></p>

        <p >{t("Login.powered")} <a target="_blank"  style={i18n.language=="ar"? {marginRight:"5px"}:{marginLeft:"5px"}} href="https://www.bdaiat.com/"> {t("Login.bdaiat")}</a></p>

         

        </div>

</div>
</div>

    );
}

export default Login;