 
import "../styles/OrderDetails.css";

import op from "../images/opLogo.svg";
import { useState,useEffect } from "react";
import { useTranslation } from 'react-i18next';
import PaginationItem from '@mui/material/PaginationItem';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import so1 from "../images/so1.svg"
import so2 from "../images/so2.svg"
import so3 from "../images/so3.svg"
import so4 from "../images/so4.svg"
import so1g from "../images/so1g.svg"
import so2g from "../images/so2g.svg"
import so3g from "../images/so3g.svg"
import so4g from "../images/so4g.svg"
import { useParams } from "react-router-dom";
import OrderSummary from "../components/OrderSummery";
import { BeatLoader } from "react-spinners";
import { AiFillCar } from "react-icons/ai";
import { repeat_order,order_track,pay_again ,recieved } from "../api";
import { useNavigate } from "react-router-dom";
import ConfirmOrderModal from "../components/ConfirmOrderModal";
import Invoice from "../components/Invoice";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineOtherHouses } from "react-icons/md";
import { Link } from "react-router-dom";
import { myOrders } from "../api";
function OrderDetails(props) {
  let history=useNavigate()
  const token= localStorage.getItem('token');

  const [nav,setNav]=useState(null);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [singleOrderLoad,setSingleOrderLoad]=useState(false);
  const [activeTab,setActiveTab]=useState(0);
  const [activeTabArr,setActiveTabArr]=useState([1,2,3,6]);

  const [loading,setLoading]=useState(true);
  const [walletPay,setWalletPay]=useState(false);
  const [orderData,setOrderData]=useState();
  const [products,setProducts]=useState([]);
  const [modal,setModal]=useState(false);
  const [confirm,setConfirm]=useState(false);
  const [recive,setRecive]=useState(false);
  const [activeId,setActiveId]=useState(0);

 const [phone,setPhone]=useState("");
 const [changeModal,setChangeModal]=useState(false);
 const [order,setOrder]=useState([]);
 const [page,setPage]=useState(1);
 const[resp,setResp]=useState()



 //CustomerInfo api call to get user phone (for analytics and webengage purposes)
 //order_track api call (takes id from url using useParams) to get order data 
 //paymentMethod is checked  to change screen view accordingly
  useEffect(()=>{
    window.scrollTo(0, 0);
  setLoading(true)
    myOrders(token,1,activeTabArr).then(response => {
    
      setResp(response.data.data)
 

       if( response.data.data.data.toString()==[]){ //otherwise causes an error
        setOrder([])
        setLoading(false)
       }
       else{
    
    

            setOrder(response.data.data.data)
            getSingleOrder(response.data.data.data[0].id)

          }
         
  
        
      }).catch(function(error){
   
     if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("phone");
          localStorage.removeItem("name");
          localStorage.removeItem("fieldsArr")
        
          history("/")
       }
      
       
       }); 
   
  },[activeTabArr])


 function getSingleOrder(id){
  window.scrollTo(0, 0);

  setSingleOrderLoad(true)
  setActiveId(id)
  order_track(id,token).then(response => {
     

    setOrderData(response.data.data)
    if(response.data.data.paymentMethod=="wallet"){
      setWalletPay(true)
    
    
    }
    setProducts(response.data.data.products)
 
    let arr=[]

    response.data.data.products.map((data)=>
    arr.push(
    
    {
    name: data.sizeEnName,
    quantity: data.quantity,
    
    
    }
    
       ))
      
       
     




   //for changing screen status
    if(response.data.data.statusId===1){
//ACCEPTED
      setNav(0);
    
      }

   
      else if(response.data.data.statusId===2){
        //IN_KITCHEN
        setNav(1);
      
      }
      else if(response.data.data.statusId===3){
            //WITH_DRIVER
        setNav(2);
      
      }
      else  if(response.data.data.statusId===4){
            //DELIVERED
        setNav(3);
    
      } 
    
      else if( response.data.data.statusId===6 ){
            //WAITING_PAYMENT
        setNav(null);
     
      } 
  

setLoading(false)
setSingleOrderLoad(false)
}).catch(function(error){

if (error.response.status === 401) {
  localStorage.removeItem("token");
  localStorage.removeItem("phone");
  localStorage.removeItem("name");
  localStorage.removeItem("fieldsArr")

  history("/")
}
}); 
 }




  function  print(){
  
      window.print();

  }
function handleRecieve(){
  setLoading(true)
recieved(localStorage.getItem("token"),id).then(response => {
setRecive(!recive)
  
}).catch(function(error){
 
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")
  
    history("/")
 }

 
 }); 
}
   

function changePage(page){
  window.scrollTo(0, 0);

  setLoading(true)
setPage(page)
myOrders(token,page).then((response) => {
 
  setResp(response.data.data)

  if( response.data.data.toString()==[]){ //otherwise causes an error
    setOrder([])

   }
   else{
    setOrder(Object.values(response.data.data.data))
  }

  setLoading(false)
}).catch(function(error){
  if (error.response.status === 401) {
     history("/")
  }

});

}
function handleDate(date){
  date = date.replace("pm", " م");
 date = date.replace("am", "ص");
  return date;

}

    return (
        <>
        { loading?  <div className='loader'>
         <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
         <>
           <div className="cartProcess">
       {/*modal? <CancelReason phone={phone} orderData={orderData} products={products} setModal={setModal}/> :null*/} 
              {confirm? <ConfirmOrderModal handleRecieve={handleRecieve} setModal={setConfirm}/> :null} 


              <div className="orderDetTop">
                <div className="mobileFlex">

            <div className="mobileOrange">

 
                <p className="orderDt1">{t("orderDetails.profile")}</p>
                <p className="orderDt2">{t("orderDetails.orders")}</p>           </div>
                <div className="orderDetNav">
              <Link to="/add-order" onClick={()=>{
          localStorage.removeItem("branchId");
          localStorage.removeItem("addressId")

   }}>  <MdOutlineOtherHouses style={{position:"relative",bottom:"1.5px"}} size={20}/> </Link> 
              <IoIosArrowForward className="arrow" color="#98A2B3"/>
              <Link to="/profile/my-profile">{t("orderDetails.profile")}</Link> 
              <IoIosArrowForward className="arrow"  color="#98A2B3"/>
 
              <a style={{  fontWeight: '700'}}>{t("orderDetails.orderDet")}</a> 

                </div>    </div>
              </div>
              <div className="orderDetContainer">
              <p className="yourOrders">{t("orderDetails.yourOrders")}</p>
              <div className="orderDetType">
             <p onClick={()=>{setActiveTabArr([1,2,3,6]); setActiveTab(0)}} className={activeTab==0?"activeType":null}>{t("orderDetails.upcoming")}</p>
            <span></span>
             <p onClick={()=>{setActiveTabArr([4]); setActiveTab(1)}} className={activeTab==1?"activeType":null} >{t("orderDetails.old")}</p>
              </div>
              <div className="container-fluid">
              {order.length==0?
 <p className="noAddress">
{t("profile.noOrders")}
 </p>:
<div className="row gy-3" style={{marginTop:"10px"}}>
<div className="col-lg-4 order-last order-lg-first">


    {order && order.map((data,index)=>
<div onClick={()=>{getSingleOrder(data.id); setSingleOrderLoad(true)}} className={data.id==activeId?"ordersCard ordersCardActive":"ordersCard"}>
<div>
  <p>{t("profile.orderStatus")}</p>
  <p className="orderStatus">{data.statusId==1?t("process.recieve"):data.statusId==2?t("process.prepare"):data.statusId==3? t("process.delivery"):data.statusId==4? t("process.delivered"): t("process.waiting")}</p>
</div>
<div>
  <p>{t("profile.orderId")}</p>
  <p className="detData">{data.id}</p>
</div>
<div>
  <p>{t("profile.date")}</p>
  <p className="detData">{i18n.language=="ar"?handleDate(orderData.createdAt):orderData.createdAt}</p>
</div>
<div>
  <p>{t("profile.order-total")}</p>
  <p className="detData">{data.totalPrice + " " + `${i18n.language=="ar"?  data.arCurrency: data.enCurrency}`}</p>
</div>
<div>
  <p>{t("profile.branch")}</p>
  <p className="detData">{i18n.language== 'ar' ? data.branchArName : data.branchEnName}</p>
</div>
</div>
  )}

</div>
{ singleOrderLoad? 
  <div className="col-lg-8   ">  <div className='loader' style={{height:"30vh"}}>
         <BeatLoader color={'#E47100'} loading={singleOrderLoad}  size={30} /></div> </div>:


<div className="col-lg-8  ">
<div className="singleOrderCard">
  <div style={{backgroundColor:"#FCFCFD",padding: "26px 16px"}}>
<div className="singleOrderNav">
<div className={nav==0 ||nav==1 ||nav==2 ||nav==3 ? "statusActive":"statusNotActive"} >
  <div className="statusImg">
    <img src={nav==0 ||nav==1 ||nav==2 ||nav==3 ?so1:so1g}/>
  </div>
<p>{t("process.recieve")}</p>
  </div>
<div className="line"></div>
<div className={nav==1 ||nav==2 ||nav==3 ? "statusActive":"statusNotActive"} >
<div>  <div className="statusImg">
    <img src={nav==1 ||nav==2 ||nav==3 ?so2:so2g}/>
  </div></div>

  <p> {t("process.prepare")}</p>
</div>
<div className="line"></div>
<div className={nav==2 ||nav==3 ? "statusActive":"statusNotActive"} >
<div>  <div className="statusImg">
    <img src={nav==2 ||nav==3 ?so3: so3g}/>
  </div></div>
  <p>{t("process.delivery")}</p>
</div>
<div className="line"></div>
<div className={nav==3 ? "statusActive":"statusNotActive"} >
<div>  <div className="statusImg">
    <img src={nav==3 ?so4:so4g}/>
  </div></div>
  <p>{t("process.delivered")}</p>
</div>

</div>
<div className="orderTop">

<div className="orderTopTitle">
  <p className="otderTh">{t("orderDetails.orderDet")}</p>
  <p className="otderTd">#{orderData.id}</p>
</div>
<div>
  <p className="otderTh">{t("process.type")}</p>
  <p className="otderTd"> {orderData.typeId==1?  t("home.option-branch"): orderData.typeId==2?   t("addOrder.home"): t("home.option-car")}</p>
</div>

<div>
  <p className="otderTh">{t("profile.branch")}:</p>
  <p className="otderTd">{i18n.language=="ar"?orderData.branchArName:orderData.branchEnName}</p>
</div>

<div>
  <p className="otderTh">{t("addOrder.time")}:</p>
  <p className="otderTd">{orderData.now==false? `${orderData.time} ` + "  "+ ` ${ orderData.date}`: t("addOrder.now")}</p>
</div>

<div>
  <p className="otderTh">{t("process.creation")}</p>
  <p className="otderTd">{i18n.language=="ar"?handleDate(orderData.createdAt):orderData.createdAt}</p>
</div>




</div>
<div className="orderTopTotal">
  <p className="otderTh">{t("process.total")}</p>
  <p className="otderTd">{orderData.totalPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
</div>
</div>

<div className="orderSummary">
<div className="ot-summary">
   <p className="ot-addressTitle"><span> {t("process.summary")}</span></p>

   <div className="row">



   {products && products.map((data,index)=>
   <>
   <div className="col-md-6">
   <OrderSummary key={index} currency={i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}  mealId={data.id} price={data.price}  name={i18n.language=="ar"? data.productArName:data.productEnName}  image={data.image} extras={data.extras}  additionals={data.additionals}  description={i18n.language=="ar"? data.productArDescription:data.productEnDescription} quantity={data.quantity}  />
   <div className="rowMagin"></div>
   </div>
    
   </>
   )}
   
   </div>
      
   </div>
   
   
   
   
   <div className="ot-total">
   <div className="orderTopTitle">
   <div className="ot-totalDiv">
   <p className="otderTh"> {t("profile.payment")}</p>
   <p className="otderTd">{orderData.paymentMethod=="cash"?t("menu.arrivalPayment"):orderData.paymentMethod=="tap"?t("process.credit"):orderData.paymentMethod=="later"?t("menu.later"):t("process.wallet")}</p>
   
   </div></div>
   <div className="ot-totalDiv">
   <p className="otderTh"> {t("process.price")}</p>
   <p className="otderTd">{orderData.originalPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   </div> 
   
   
   
   
   
   <div className="ot-totalDiv">
   
       
   <p className="otderTh">{t("process.tax")}	&nbsp;({orderData.tax}%) </p>
   
   <p className="otderTd">{orderData.taxValue} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   </div>
   {orderData.typeId==2?
   <div className="ot-totalDiv">
        <p className="otderTh"> {t("process.delivery_charge")} </p>

   <p className="otderTd">{orderData.deliveryPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   
   </div>
   :null
   }
   
   {walletPay? 
   <>
   <div className="ot-totalDiv">
   <p className="otderTh"> {t("process.total")} </p>
   <p className="otderTd">{orderData.totalPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   
   </div>
   
   <div className="ot-totalDiv">
   <p className="otderTh"> {t("process.walletPay")} </p>
   <p className="otderTd">{orderData.walletPay} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   
   </div>
   
   <div style={{transform:"unset"}}  className="cpTotaldiv">
      
   <p  className="cpTotalP"> {t("process.remainPrice")}</p>
   <div>
   <p  className="cpCurrency"> {orderData.remainPrice==""?0 :orderData.remainPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   </div>
   </div>
   
   </>
   :
   <div style={{transform:"unset"}}  className="cpTotaldiv">
      
   <p  className="cpTotalP"> {t("process.total")}</p>
   <div>
   <p  className="cpCurrency"> {orderData.totalPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   </div>
   </div>}
   </div>
   <div className="printInvoice">
   <button  onClick={print}>{t("process.print")} </button>

   </div>

   </div>


</div></div>}

{ resp.last_page>1?
<div className="col-12 order-last">
<Stack spacing={0}>
      <Pagination 
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: i18n.language=="ar"?'prevAr':"prevEn" , next: i18n.language=="ar"?'nextAr':"nextEn" }}
          {...item}
        />)}

      className={i18n.language=="ar"?'arArrow':null} defaultPage={1} page={page}  count={ resp.last_page} onChange={(e,value)=>{ changePage(value)}} shape="rounded" variant="outlined" 
      
      />
      </Stack>
</div>:null}
</div>}
              </div>
              </div>

              
          
{/*
             <div className="cpTop">
   
             <div className={nav==0 ||nav==1 ||nav==2 ||nav==3 ? "activeStep":null}>
   <div  style={{zIndex:"3"}}  className={i18n.language=="en"?"step1 ":"step1Ar "} > <div>
     <img src={nav==0 ||nav==1 ||nav==2 ||nav==3 ? s1:s1g}/>
     <p>{t("process.recieve")} </p>
     </div></div>
   </div>
    
     <BsChevronDoubleDown className='processDown' size={30} />
     <div className={nav==1 ||nav==2 ||nav==3 ? "activeStep":null}>
     <div style={{zIndex:"2"}} className={i18n.language=="en"?"step ":"stepAr"} > <div>
   <img src={nav==1 ||nav==2 ||nav==3 ? s2:s2g}/>
   
     <p style={i18n.language=="en"?{lineHeight:"20px"}:null}>  {t("process.prepare")}</p>
     </div></div></div>
    {orderData.typeId==2? <> 
     <BsChevronDoubleDown className='processDown' size={30} />
     <div className={nav==2 ||nav==3 ? "activeStep":null}>
     <div   className={i18n.language=="en"?"step  ":"stepAr"}  > <div>
     <img  src={nav==2 ||nav==3 ? s3:s3g}/>
     <p >  {t("process.delivery")}</p>
     </div></div></div> </>: null}
     
     <BsChevronDoubleDown className='processDown' size={30} />
     <div className={nav==3 ? "activeStep":null}>
     <div  className={i18n.language=="en"?"step4 ":"step4Ar "}>
     <div>
     <img src= {nav==3 ? s4:s4g}/>
     <p>  {t("process.delivered")}</p>
     </div></div>
   
   </div>
    
   
   </div>
   <div className="container-fluid fluid">
   <div className="order-track">
   <div>
   <div className="ot-top">
   <p> {t("process.details")}</p>
    
    
 <button style={{padding:"0",display:"unset"}} onClick={print}>{t("process.print")} </button>
  
   </div>
    
    
   <div className="mobileButtons row">
  
 

  
    <div className="col-sm-4">
   <button onClick={print}>{t("process.print")} </button>
</div>
 
   </div>
   <div className="row ot-div1" style={{marginTop:'1em'}}>
   <div  className="col-md-2 col-sm-3 col-4 ot-icon">
   <div  style={orderData.now==true?{ backgroundImage: `url(${op})`,height:"5em"}:{ backgroundImage: `url(${op})` }}>
   
   </div>
   </div>
   <div  className="col-md-10 col-sm-9 col-8">
   <div className="row ot-details" >
   <div className="col-sm-6">
   <p>{t("process.id")}<span>{orderData.id}</span></p>
   </div>
   <div className="col-sm-6">
   <p>{t("process.type")} <span>{orderData.typeId==1?  t("home.option-branch"): orderData.typeId==2?   t("addOrder.home"): t("home.option-car")}</span></p>
   </div>
   
   
   {orderData.now==false?
   <div className="col-sm-6">
   <p>{t("process.date")} <span>{orderData.date}</span></p>
   </div>:null}
   
   {orderData.now==false?
   <div className="col-sm-6">
   <p>{t("process.time")}<span>{orderData.time}</span></p>
   </div>
   :null}
  <div className="col-sm-6">
   <p>{t("profile.branch")}:<span>{i18n.language=="ar"?orderData.branchArName:orderData.branchEnName}</span></p>
   </div>
  
 
   
   <div className="col-sm-6">
   <p>{t("process.creation")}<span>{orderData.createdAt}</span></p>
   </div>
   
   </div>
   </div>
   </div>
   </div>
   {orderData.address!==""?
   <div className="ot-address">
   <p className="ot-addressTitle"> <RiMotorbikeFill className={i18n.language=="ar"?"iconDirection":null} size={25}/><span> {t("process.address")}</span></p>
   <div className="row ot-addressDetail" >
   <div className="col-sm-6">
   <p>{orderData.address}</p>
   </div>
  
    
   </div>
   </div>:null}
    
  
   {orderData.notes!==""?
  <div className="ot-address">
   <p className="ot-addressTitle"><MdOutlineEventNote size={25}/><span> {t("singleOrder.notes")}</span></p>

<div className="row ot-addressDetail" >
   <div className="col-sm-6">
   <p>{orderData.notes}</p>
   </div>
   </div>
</div>:null}
  
   <div className="ot-summary">
   <p className="ot-addressTitle"><MdOutlineEventNote size={25}/><span> {t("process.summary")}</span></p>
   {console.log(products)} 
   
   {products && products.map((data,index)=>
   
   <>
   <OrderSummary key={index} currency={i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}  mealId={data.id} price={data.price}  name={i18n.language=="ar"? data.productArName:data.productEnName}  image={data.image} extras={data.extras}  additionals={data.additionals}  description={i18n.language=="ar"? data.productArDescription:data.productEnDescription} quantity={data.quantity}  />
   <div className="rowMagin"></div>
   </>
   )}
   
    
      
   </div>
   
   
   
   
   <div className="ot-total">
   <div className="ot-totalDiv">
   <p> {t("profile.payment")}</p>
   <p className="ot-value">{orderData.paymentMethod=="cash"?t("menu.arrivalPayment"):orderData.paymentMethod=="tap"?t("process.credit"):orderData.paymentMethod=="later"?t("menu.later"):t("process.wallet")}</p>
   
   </div>
   <div className="ot-totalDiv">
   <p> {t("process.price")}</p>
   <p className="ot-value">{orderData.originalPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   </div> 
   
   
   
   
   
   <div className="ot-totalDiv">
   
       
   <p>{t("process.tax")}	&nbsp;({orderData.tax}%) </p>
   
   <p className="ot-value">{orderData.taxValue} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   </div>
   {orderData.typeId==2?
   <div className="ot-totalDiv">
   <p> {t("process.delivery_charge")} </p>
   <p className="ot-value">{orderData.deliveryPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   
   </div>
   :null
   }
   
   {walletPay? 
   <>
   <div className="ot-totalDiv">
   <p> {t("process.total")} </p>
   <p className="ot-value">{orderData.totalPrice} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   
   </div>
   
   <div className="ot-totalDiv">
   <p> {t("process.walletPay")} </p>
   <p className="ot-value">{orderData.walletPay} {i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   
   
   </div>
   
   <div style={{transform:"unset"}}  className="cpTotaldiv">
      
   <p  className="cpTotalP"> {t("process.remainPrice")}</p>
   <div>
   <p className="cpTotal">{orderData.remainPrice==""?0 :orderData.remainPrice}</p>
   <p  className="cpCurrency">{i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   </div>
   </div>
   
   </>
   :
   <div style={{transform:"unset"}}  className="cpTotaldiv">
      
   <p  className="cpTotalP"> {t("process.total")}</p>
   <div>
   <p className="cpTotal">{orderData.totalPrice}</p>
   <p  className="cpCurrency">{i18n.language=="ar"? orderData.arCurrency:orderData.enCurrency}</p>
   </div>
   </div>}
   </div>
   
   </div></div>
   */}
   

   </div>
   {order.length==0?null: <Invoice data={orderData}/>}
         </>
        }

     
         </>
    );
  }
  
  export default OrderDetails;
