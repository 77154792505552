   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomerInfo } from "../api";
import { BeatLoader } from 'react-spinners';
import logout from "../images/logout.gif"
function LogoutModal(props) {

const { t, i18n } = useTranslation();
const history= useNavigate();



//logout removes certain items from local storage 
   //user is then directed to login screen
function handleLogout(){


    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")

    props.setModal(false)
    if(props.setToggle){
    props.setToggle(false)}
    history("/");

    
    } 

    return ( 
      
 
 
        <div  className="foodModal">
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content logoutModal" style={{height:"auto",width:"54vw ", maxWidth:"400px",minWidth:"337px"}}>
          
            <div >
            <button className="close-modal" onClick={()=>props.setModal(false)}>
              <IoCloseSharp size={40}/>
            </button>
             <div style={{height:"20vh",display:"contents"}} className='modalBody'>
             
             <div className="logoutImg" style={{ backgroundImage: `url(${logout})` }}></div>

         <p className='cancelOrder'>
          {t("profile.logout-modal")}</p>
           <div className='modalBtns'>
           <button className='confirm' onClick={handleLogout}>     {t("profile.yes")}</button>
           <button  onClick={()=>props.setModal(false)}  className='cancel'>     {t("profile.no")}</button></div>
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default LogoutModal;
 