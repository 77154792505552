   

 
import React, {useEffect, useState,useRef} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomerInfo } from "../api";
import { Link } from 'react-router-dom';
import success from "../images/success.svg"
import { requestDocument } from '../api';
import { BeatLoader } from 'react-spinners';
import { BsChevronDown } from 'react-icons/bs';

function AddDocument(props) {

const { t, i18n } = useTranslation();
const history= useNavigate();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [errMsg, setErrMsg]=useState("");
const [loading, setLoading]=useState(false);

const typeRef=useRef()
const noteRef=useRef()

function handleSubmit(){
    setLoading(true)
let body= {
    type:typeRef.current.value,
    note:noteRef.current.value
}
const token= window.localStorage.getItem('token');
requestDocument(token,body,i18n.language).then(response => {

  props.setSuccess(true)

props.setModal(false)
 }).catch(function(error){

  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")
  
    history("/")
 }
else{
    setLoading(false)
    setErrorMsgModel(true)
    if(error.response.data.errors.type){
      setErrMsg(error.response.data.errors.type[0])

    }
    else{
      setErrMsg(error.response.data.errors.note[0])

    }
}
 
 }); 

}
    return ( 
      
 
 
        <div  className="foodModal">
    
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content " style={{height:"auto",width:"90vw ", maxWidth:"525px"}}>
          
            <div >
           
             <div style={{height:"20vh",display:"contents"}} className='modalBody addDoc'>
             
<p className='docTitle'>{t("docs.request")}</p>
<label>{t("docs.docType")}</label>
<div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

<select  defaultValue=""   ref={typeRef}>
<option value="" disabled>{t("process.choose")}</option>

  <option value="association request">{t("docs.type2")}</option>
  <option value="account statement">{t("docs.type3")}</option>
  <option value="other">{t("docs.type1")}</option>

</select>
<div class="select-arrow">
<BsChevronDown size={20}/>

</div>
</div>
<label>{t("docs.notes")}</label>

<textarea ref={noteRef} placeholder={t("profile.enterNote")}/>

<span>{errorMsgModel? errMsg:null}</span>

{loading?
      <div style={{margin:"0",height:"100px"}} className='loader '>
      <BeatLoader color={'#E47100'}   size={30} /></div>:
           <div className='modalBtns'>
    

           <button className='confirm' onClick={handleSubmit}>     {t("profile.save")}</button>

           <button  className='cancel' onClick={()=>{props.setModal(false)}}>     {t("profile.cancel")}</button>

           
           </div>}
            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default AddDocument;
 