import React, {useEffect, useState} from 'react';
import "../styles/Navbar.css"
import { useTranslation } from 'react-i18next';

import arLogoBlack from "../images/arLogoBlack.svg"
import arLogoWhite from "../images/arLogoWhite.svg"
import enLogoBlack from "../images/enLogoBlack.svg"
import enLogoWhite from "../images/enLogoWhite.svg"

import {BsGlobe} from"react-icons/bs"
import { RiHome3Line} from "react-icons/ri";
import { NavLink,Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { FiLogOut } from "react-icons/fi";
import LogoutModal from './LogoutModal';
import { AiOutlineUser } from "react-icons/ai";
import pp from"../images/defaultImg.jpeg"
import { HiOutlineMenuAlt1} from "react-icons/hi";
import { GrFormClose} from "react-icons/gr";

import { useLocation } from 'react-router';
function Navbar(props) {
    const { t, i18n } = useTranslation();
let history=useNavigate()
const location= useLocation();

    let navigate = useNavigate();
    const [lang,setLang]=useState("ar");
    const [modal,setModal]=useState(false);
    const [toggle,setToggle]=useState(false)
    const [firstName,setFirstName]=useState(localStorage.getItem("name")?localStorage.getItem("name").split(' ').slice(0,1).join(' '):null)

    const token= localStorage.getItem("token");

    // to display first name only

 
    //on changing language => takes "en" or "ar" as parameter
    //sets i18n language 
    //changes direction of document depending on language
    const changeLanguage = (e) => {
      //props.handleChangeLang() //triggers loading in (App.js) to show loading on whole screen when changing language 
        var language = e;
         i18n.changeLanguage(language);
         localStorage.setItem("LANG",JSON.stringify(language ));
         setLang(language);
       if(language==="en"){
       document.getElementById("root").style.direction="ltr";
     
       }
       else{
         document.getElementById("root").style.direction="rtl";
       }
       props.setLoading(true)
       setTimeout(function() {
    
        props.setLoading(false)
      }, 500)
       };
  
      //to presist langunage and document direction and website name
    
       useEffect(() => {
window.scrollTo(0,0)
  

const data=  JSON.parse(localStorage.getItem("LANG"));
i18n.changeLanguage(data);
setLang(data);


if(i18n.language==="en"){
  document.getElementById("root").style.direction="ltr";

  }
  else if(i18n.language==="ar"){
    document.getElementById("root").style.direction="rtl";
  
  }
  else{
   document.getElementById("root").style.direction="ltr";
  }

  if(i18n.language==="ar"){
   document.title="  مطاعم الرومانسية"
}else {
   document.title="Al Romansiah restaurants "

}
     },[lang,window.location.pathname]); 
 





     useEffect(() => {
       console.log()
      setFirstName(localStorage.getItem("name")?localStorage.getItem("name").split(' ').slice(0,1).join(' '):"")
     },[props.userName])

    
    



   


    return (
      <div className={i18n.language=="ar" && location.pathname.split("/")[1]!=="profile"?"navrAr":null}>
         {modal?
      <LogoutModal setToggle={setToggle} setModal={setModal}/>
      :null
    }
    <div className={ location.pathname=="/" ||location.pathname=="/register"?null:'navShadow'}>

    <div id="webNavbar"  className={location.pathname=="/add-order" ? "secondaryNav":location.pathname=="/"||location.pathname=="/register" ?"loginNav":null} style={location.pathname=="/" ||location.pathname=="/register"?{position:"absolute",width:"100%"}:location.pathname.split("/")[1]=="profile"?i18n.language=="ar"?{marginBottom:"30px",paddingRight:"264px"}:{marginBottom:"30px",paddingLeft:"264px"}:{marginBottom:"30px"}}>
    
    <div style={location.pathname=="/" ||location.pathname=="/register"?{background:"transparent"}:null} className={i18n.language=="ar"?"navbarContainer container":" container navbarContainer navbarContainerEn"}>
    <div className={location.pathname.split("/")[1]=="profile"?"navbarTop navbarTopProfile":" navbarTop "}>
    
    
   {location.pathname.split("/")[1]=="profile"?
   <div className='profileLogo'>

{
    token && localStorage.getItem("lastStep")=="completed"? 
    <Link to="/add-order" onClick={()=>{
      localStorage.removeItem("branchId");
      localStorage.removeItem("addressId")

}}> 
    <div className='navLogo' style={location.pathname=="/add-order"?{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }:{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
    </div></Link>:
    <div className='navLogo' style={location.pathname=="/add-order"?{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }:{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
    </div>}
   </div>:


    token && localStorage.getItem("lastStep")=="completed"? 
    <Link to="/add-order" onClick={()=>{
      localStorage.removeItem("branchId");
      localStorage.removeItem("addressId")

}}> 
    <div className='navLogo' style={location.pathname=="/add-order"?{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }:{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
    </div></Link>:
        <Link to="/" >

    <div className='navLogo' style={location.pathname=="/add-order"?{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }:{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
 </div>  </Link> }



    <div className="navbarOptions">
    { 
         token? 
    <>
    <Link to="/profile/my-profile" className='alignName'> 
    <div className='usrProfile' style={{ backgroundImage: `url(${pp})` }}>
    </div>
    <p>{firstName}</p>
    </Link>
    
    <button onClick={()=>setModal(true)} id="logoutBtn"> <FiLogOut color='#ED9434' size={20}/> </button></>
    :
   
   null
    }
    <button style={ token? {padding:"8px 10px"}:{padding:"8px 10px"}} id="globe" onClick={()=>changeLanguage(i18n.language=="ar"?"en":"ar")}><BsGlobe  size={20}  color='#ED9434'/> {i18n.language=="ar"? "العربية": "EN"} </button>
    {token && localStorage.getItem("lastStep")=="completed"? 
    <Link to="/add-order" onClick={()=>{
              localStorage.removeItem("branchId");
              localStorage.removeItem("addressId")
    
       }}><button className='orderNow'>{t("dashboard.orderNow")}</button>
    </Link>:null}
    
    </div>
   
    </div> 
    
    
    </div>
    
    
    
    </div>
    <div id="navbarMobile" className={i18n.language=="en"?"navbarEn":null}>
            <button className='toggleBtn' onClick={()=>setToggle(true)}> <HiOutlineMenuAlt1 color={location.pathname=="/add-order"?"white":'#667085'}  size={30} /></button>
       
       {/* <div onClick={()=>history("/dashboard")}  id='navLogo' style={toggle?{ backgroundImage: `url(${navLogo})`, cursor:"pointer" }:{ backgroundImage: `url(${navLogo})`, cursor:"pointer",zIndex:"20"}}>
        </div>
      */}
        {token && localStorage.getItem("lastStep")=="completed"? 
    <Link to="/add-order" onClick={()=>{
      localStorage.removeItem("branchId");
      localStorage.removeItem("addressId")

}}> 
    <div className='navLogo' style={location.pathname=="/add-order"?{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }:{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
    </div></Link>:
            <Link to="/" >

    <div className='navLogo' style={location.pathname=="/add-order"?{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }:{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
    </div></Link>}
    
    <div  className={toggle?'sideNav ':'sideNav navClosed'}>
    
    </div>
    <button className={toggle?'closeNav ':'closeNav closeNavHidden'} onClick={()=>setToggle(false)} >
    <GrFormClose color='white'   size={35} />
    </button>
    
    <div className={toggle?'mobileLinks':'mobileLinksClose mobileLinks'}>
    {token && localStorage.getItem("lastStep")=="completed"? 
    <Link to="/add-order" onClick={()=>{
      localStorage.removeItem("branchId");
      localStorage.removeItem("addressId")

}}> 
    <div className='navLogo' style={{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
    </div></Link>:
            <Link to="/" >

    <div className='navLogo' style={{ backgroundImage: `url(${i18n.language=="ar"?arLogoBlack:enLogoBlack})` }}>
    </div></Link>}
    {/*
    <div id="srchInput" className='navSearch'>
            <FiSearch size={20}  color='#667085'/>
    
    <input  placeholder={t("dashboard.search")}/>
    
      </div>*/}
            <ul id="navList">
        <li >
        {token && localStorage.getItem("lastStep")=="completed"? 
    
    
        <NavLink to="/add-order" onClick={()=>{
          localStorage.removeItem("branchId");
          localStorage.removeItem("addressId")
        }}>
        <RiHome3Line size={20} /> {t("dashboard.orderNow")}
            </NavLink> :null }
        </li>
        <li>
        <p onClick={()=>changeLanguage(i18n.language=="ar"?"en":"ar")}>    <BsGlobe  size={20} /> {i18n.language=="ar"? "العربية": "EN"}
    </p></li>
      
    </ul>
    
    <div className='userNav'>
        <div id="navFlex">
    <div className="navFlex">
    {token?
    
         <Link to="/profile/my-profile"> 
        <div className='usrProfile' style={{ backgroundImage: `url(${pp})` }}>
    </div></Link>:<div className='usrProfile' style={{ backgroundImage: `url(${pp})` }}>
    </div>}
    {token?
      <Link to="/profile/my-profile"> 
            <p >{firstName}</p></Link>
    :
    <Link to="/"  onClick={()=>setToggle(false)}>{t("Login.login")}</Link>
    }
    </div>
    {token?
    <button onClick={()=>setModal(true)}>
            <FiLogOut color='#667085' size={20}/>
    
            </button>:null}
          </div>
           
    
    
    </div>
    
    </div>
    
    </div>
    </div>
    
</div>);}
      export default Navbar; 