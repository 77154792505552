import '../styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

function RemoveItem(props) {
   
    const { t, i18n } = useTranslation();

  function handleRemove(){

props.handleRemove()
  }
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"300px",paddingTop:"2em",position:"fixed"}}>
        

<p className='alrt'>{t("menu.remove")}</p>
<div className='modalBtns'>
<button  onClick={handleRemove}  className='del'>{t("menu.confirm")}</button>
<button  onClick={()=>props.setModal(false)}  className='cls'>{t("menu.cancel")}</button>

</div>
        </div>
      </div>
  
    );
  }
  
  export default RemoveItem;
  
  