import "../styles/OrderDetails.css";
import { useTranslation } from 'react-i18next';
function OrderSummary(props) {
  const { t, i18n } = useTranslation();
    return (
     
    
  
  <div className="flexSummery osItem">

    
    <div >
      <div style={i18n.language=="ar"?{marginLeft:"12px"}: {marginRight:"12px"}} className="alignV">
    <div  className='itemImg' style={{ backgroundImage: `url(${props.image})` }}>
    </div>
</div> 
    </div>  
    <div style={{textAlign:"initial"}}>
    <p className="cpMiddle-p1"> {props.name}</p>
    <p className="cpMiddle-p2">{props.description}</p>

    {
    props.additionals && props.additionals.length>0?
    <> 
    <p className="cpMiddle-p3">{t("meals.additions")}</p>
  
  
{props.additionals &&  props.additionals.map((data,index)=>

   
<>
<p className="cpMiddle-p4">{i18n.language=="ar"? data.additionalArName:data.additionalEnName}</p>
{index !== props.additionals.length-1?
<span>	&#160; - 	&#160; </span>: null}
</>

     )
}
</>  
  :null
    }

    {
    props.extras && props.extras.length>0?
    <>
    <p className="cpMiddle-p3">{t("process.extras")}</p>
  
  
{props.extras &&  props.extras.map((data,index)=>

   
<>
<p className="cpMiddle-p4">{i18n.language=="ar"? data.arName:data.enName}</p>
{index !== props.extras.length-1?
<span>	&#160; - 	&#160; </span>: null}
</>

     )
}
</>  
  :null
    }
<div className="productNmbrs">
    <p >{t("process.price") }	&#160;  {  props.price/props.quantity}{props.currency}</p>
    <span>	&#160; &#160; 	&#160; </span>
    <p style={{lineHeight:"20px"}} className="cpMiddle-quantity"> {t("process.quantity")}&#160; {props.quantity}</p>
   
  
   
</div>

    

    </div>


   

  </div>

    
       
    );
  }
  
  export default OrderSummary;