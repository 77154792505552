import '../styles/Menu.css';
import React, {useState,useEffect,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import prepare from "../images/prepare.svg"
import { generatePath } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
function OrderReceived() {
    const { t, i18n } = useTranslation();
    let history = useNavigate();

    function handleProceed(){
       
        history("/my-orders");
        
      }; 
   return (
  
        

   
        <div style={{height:"91vh",maxWidth:"90vw",margin:"auto"}} className="cartProcess">
        
    
        <div style={{position:"relative",top:"10%"}} className="container-fluid fluid ">
        <div className="cpMiddle-title2"> 
         
          <div className="cpPayment">
              <div className="row">   
              <div className="col-md-6 cpPayment-div2 ">
              <img src={prepare} alt="image"/>
         
          
           
          </div>
          <div className="col-md-6 cpPaymentDone">
      
          <div>
        <p className="paymentDone-p1"> {t("process.success")} &#x1F389;</p>
        
        
        <button onClick={handleProceed}> {t("process.track")} </button>
        </div>
        
      
        
        
        
          </div>
         </div>
          </div>
          </div>
        </div>
        
              </div>



    );
}

export default OrderReceived;