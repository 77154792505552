import notFound from"../images/404.png";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; 

function NotFound(){
 const { t, i18n } = useTranslation();

        return (
          <div>
   

<div style={{marginTop:"2em",maxWidth:"1300px",height:"auto"}} className='container  orderSuccess'>
  

  <div className='shareForm foundUrl'  style={{ backgroundImage: `url(${notFound})`}}>
<p>{t("Login.notFound")}</p>

</div></div>

    
   

                 

   
                
                </div>
           
        )
    }


    export default NotFound;
