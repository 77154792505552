
import i18next from "i18next";
import "../styles/UserProfile.css";
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import pp from"../images/defaultImg.jpeg"
import { CustomerInfo } from "../api";
import navLogo2 from "../images/navLogo2.svg"

import arLogoWhite from "../images/arLogoWhite.svg"
import enLogoWhite from "../images/enLogoWhite.svg"

import { HiOutlineLocationMarker} from "react-icons/hi";
import {IoWalletOutline} from "react-icons/io5";
import {IoLogOutOutline} from "react-icons/io5";
function UserNav(props) {
  const token= localStorage.getItem("token");
  const { t, i18n } = useTranslation();
 
    const [name,setName]=useState("");
    const [phone,setPhone]=useState("");
    const[image,setImage]=useState();
  
 
    //fetch user data
    //rerenders when personal data is edited 
    useEffect(() => {
        window.scrollTo(0, 0);
  
    },[]) 

    return ( 
     <>
         <div className="mobile-upSideNav">
             <div className="mobile-upSideNav-top">
       

             <div className="brownDiv">
               </div>
               <div className="positionProfile">

            
<div className="upTop">
    <div className="upImageBorder">
    <div className='profileImage' style={{backgroundImage:`url(${image?image:pp})`}}></div>
       
    </div>
    <div className="upInfo">
<p className="upInfo-name"> {localStorage.getItem("name")}</p>
<p className="upInfo-number">{localStorage.getItem("phone")}</p></div>
</div>

<div className="mobile-upLinks">
  <div>


<Link to="/profile/my-profile">
<p  className={props.nav==5|| props.nav==6?"mobile-upLinksActive":null}>{t("profile.personal")}</p> 
</Link>
<span></span>
<Link to="/my-orders">
<p   className={props.nav==0?"mobile-upLinksActive":null}>{t("profile.orders")}</p>
</Link>
<span></span>

<Link to="/profile/my-addresses">
<p   className={props.nav==1?"mobile-upLinksActive":null}>{t("profile.address")}</p>
</Link>
<span></span>

{localStorage.getItem("userType")=='prepayment'?
<>
<Link to="/profile/my-wallet">
<p   className={props.nav==2?"mobile-upLinksActive":null}>{t("profile.wallet")}</p>
</Link><span></span></>
 :null}
 

<p onClick={()=>{props.setModal(true)}} >  {t("profile.logout")}</p>
{/*
<p  onClick={()=>props.setNav(0)} className={props.nav==0?"mobile-upLinksActive":null}>المنتجات المفضلة </p>*/}

</div>  
</div>  </div>
  


</div>

         </div>
<div className={i18n.language=="ar"?"upSideNave upSideNaveAr":"upSideNave upSideNaveEn"}>
    <div className='usrNavLogo'>

    {
    token && localStorage.getItem("lastStep")=="completed"? 
    <Link to="/add-order" onClick={()=>{
          localStorage.removeItem("branchId");
          localStorage.removeItem("addressId")

   }}>

    <div  style={{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }}>
    </div></Link>

:  <div  style={{ backgroundImage: `url(${i18n.language=="ar"?arLogoWhite:enLogoWhite})` }}>
</div>}
    </div>

<div className="upTop">
    <div className="upImageBorder">
    <div className='profileImage' style={{backgroundImage:`url(${image?image:pp})`}}></div>
        
    </div></div>
    <div >
<p className="upName">{localStorage.getItem("name")}</p>
<p className="upPhone">{localStorage.getItem("phone")}</p></div> 

 
 
<div className={i18n.language=="ar"?"upLinks ":"upLinks upLinksEn"}>
<Link to="/profile/my-profile">
<p  className={props.nav==5|| props.nav==6?"upLinksActive":null}> 
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M11 15L8 15C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7M11.9999 21.5L14.025 21.095C14.2015 21.0597 14.2898 21.042 14.3721 21.0097C14.4452 20.9811 14.5147 20.9439 14.579 20.899C14.6516 20.8484 14.7152 20.7848 14.8426 20.6574L21.5 14C22.0524 13.4477 22.0523 12.5523 21.5 12C20.9477 11.4477 20.0523 11.4477 19.5 12L12.8425 18.6575C12.7152 18.7848 12.6516 18.8484 12.601 18.921C12.5561 18.9853 12.5189 19.0548 12.4902 19.1278C12.458 19.2102 12.4403 19.2984 12.405 19.475L11.9999 21.5ZM13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z" stroke="#D0C1C2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  {t("profile.personal")}</p>
</Link>
<Link to="/my-orders" className={localStorage.getItem("lastStep")=="completed"?null:"disabledLink"}>
<p className={props.nav==0?"upLinksActive":null}> 
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
  <path d="M20.5 12.5V6.8C20.5 5.11984 20.5 4.27976 20.173 3.63803C19.8854 3.07354 19.4265 2.6146 18.862 2.32698C18.2202 2 17.3802 2 15.7 2H9.3C7.61984 2 6.77976 2 6.13803 2.32698C5.57354 2.6146 5.1146 3.07354 4.82698 3.63803C4.5 4.27976 4.5 5.11984 4.5 6.8V17.2C4.5 18.8802 4.5 19.7202 4.82698 20.362C5.1146 20.9265 5.57354 21.3854 6.13803 21.673C6.77976 22 7.61984 22 9.3 22H12.5M14.5 11H8.5M10.5 15H8.5M16.5 7H8.5M15 19L17 21L21.5 16.5" stroke="#D0C1C2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
 {t("profile.orders")}</p>
</Link>
<Link to="/profile/my-addresses" className={localStorage.getItem("lastStep")=="completed"?null:"disabledLink"}>

<p  className={props.nav==1?"upLinksActive":null}>    <HiOutlineLocationMarker size={24}/>  {t("profile.address")}</p>
</Link>
{localStorage.getItem("userType")=='prepayment'?

<Link to="/profile/my-wallet" className={localStorage.getItem("lastStep")=="completed"?null:"disabledLink"}>

<p className={props.nav==2?"upLinksActive":null}>    <IoWalletOutline size={24}/>  {t("profile.wallet")}</p>
</Link>:null}


<p onClick={()=>{props.setModal(true)}}>  <IoLogOutOutline size={24}/> {t("profile.logout")}</p>
{/*
<p onClick={()=>props.setNav(4)} className={props.nav==4?"upLinksActive":null}>  المنتجات المفضلة</p>
*/}
</div>
</div>
</>
   
    );
  }
  
  export default UserNav;