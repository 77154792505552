   

 
import React, {useEffect, useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomerInfo } from "../api";
import { Link } from 'react-router-dom';
import success from "../images/success.svg"
import { GrRefresh } from 'react-icons/gr';
function SuccessModal(props) {

const { t, i18n } = useTranslation();
const history= useNavigate();

function refresh(){
  props.setModal(false)
  props.setUpdate(!props.update)
}

    return ( 
      
 
 
        <div  className="foodModal">
    
          <div onClick={()=>  {props.type=="document"?refresh() :props.setModal(false)}} className="modalOverlay"></div>
          <div  className="modal-content successModal logoutModal" style={{height:"auto",width:"90vw ", maxWidth:"400px",minWidth:"max-content"}}>
          
            <div >
            <button className="close-modal" onClick={()=>  {props.type=="document"?refresh() :props.setModal(false)}}>
              <IoCloseSharp size={40}/>
            </button>
             <div style={{height:"20vh",display:"contents"}} className='modalBody'>
             
             <div className="logoutImg" style={{ backgroundImage: `url(${success})` }}></div>

         <p style={{textAlign:"center"}} className='cancelOrder'>
          {props.txt}</p>


           <div className='modalBtns'>
             {props.type=="document"?

                          <button style={{width:"fit-content"}} className='confirm' onClick={()=>{props.setActive(0); props.setModal(false);}}>     {t("profile.back")}</button>
                   :
             <>
             <button className='confirm' onClick={()=>{props.type=="pass"?props.setChangePass(false) :props.setModal(false); props.setEdit(true)}}>     {t("profile.editMore")}</button>
           <button  className='cancel'  onClick={()=>{props.setActive(0); props.setModal(false);}}>     {t("profile.back")}</button>
        </>
            }
          
           
           </div>

            </div>
           
     
            </div>
          
          </div>
        </div>
    

    );
}

export default SuccessModal;
 