import '../styles/Dashboard.css';
import chicken from "../images/chicken.svg"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Dashboard() {
    const { t, i18n } = useTranslation();

  
    return (
      <div style={{overflowX:"hidden"}}> 
         

          <div className='clientSearch'>
            <div className='container-fluid'>
<div className='row'>
   
<div style={i18n.language=="ar"?{paddingRight:"2em"}:{paddingLeft:"2em"}} className='col-lg-6 ordrNw '> 
   <Link to="/add-order" onClick={()=>{
          localStorage.removeItem("branchId");
          localStorage.removeItem("addressId")

   }}><button>order now</button>
</Link>
</div>

<div className='col-lg-6'>
<div className={i18n.language=="ar"?"chicken":"chicken chickenEn"} style={{ backgroundImage: `url(${chicken})` }}></div>

 
</div>
</div></div>

          </div>
       </div>
    );
  }
  
  export default Dashboard;
  
  