 
import React, {useState,useRef,useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';

import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
import ChangePass from "../ChangePass"
import SuccessModal from '../../../components/SuccessModal';
import { countries,companyActivities,updateBasicData, changePassword} from '../../../api';
import { BsChevronDown } from 'react-icons/bs';

function BasicData(props) {
  const [cahngePass,setChangePass]=useState(false)

    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(true);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [success, setSuccess]=useState(false);

const [errorMsgModel, setErrorMsgModel]=useState(false);
const [msgArp, setMsgArp]=useState();
const [msgEnp, setMsgEnp]=useState();
const [activities,setActivities]=useState([]);
const [edit, setEdit]=useState(false);

const [city,setCity]=useState([]);
const [validation,setValidation]=useState(
  {
    name: {
      err:false,
      text:""
    },
    phone: {
      err:false,
      text:""
    }, facilityName: {
      err:false,
      text:""
    }, activityId: {
      err:false,
      text:""
    },
    cityId: {
      err:false,
      text:""
    }
  
  })

      const history=useNavigate();
  
      let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
      let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

      const Schema= yup.object().shape({
      phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
      name: yup.string().required(("validation.name_required")).matches(nameRegEx,{message:("validation.name_not_valid")}).min(3, ("validation.name_atleast")),
     
      company: yup.string().required(("Login.required_company")).matches(nameRegEx,{message:("validation.name_not_valid")}),
      activity: yup.string().required(("Login.required_companyActiv")),
      city: yup.string().required(("Login.required_city")),


      })
      

      
  
      const formik = useFormik( {
          
      initialValues:{
        name: props.data.user.name,
       phone: props.data.user.phone,
       company:props.data.facilityName,
       activity:props.data.externalSaleType=="prepayment"? parseInt(props.data.clientActivity.id):parseInt(props.data.facilityActivity.id),
       city:parseInt(props.data.city.id),
    
      },
      validationSchema: Schema,
      enableReinitialize: true,

      onSubmit
      });
   
      const passRef=useRef();
          const phoneRef=useRef();
          const passConfirmRef=useRef();
          const nameRef=useRef();

          const cityRef=useRef();
          const companyRef=useRef();
          const activityRef=useRef();




          useEffect(() => {


            companyActivities().then(response => {

                setActivities(response.data)
                     
                     })
            countries().then(response => {
         
         
        setCity(response.data.data[0].cities)
        setLoading(false)
             
             }).catch(function(error){
 
              if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("phone");
                localStorage.removeItem("name");
                localStorage.removeItem("fieldsArr")
              
                history("/")
             }
            
             
             }); 
       
                    },[]);




      async function onSubmit(event){
const token=localStorage.getItem("token")
const id=localStorage.getItem("id")

        setLoading(true);
        const data={
     
      
          name:nameRef.current.value,
          phone:phoneRef.current.value,
          facilityName:companyRef.current.value,
          activityId: parseInt(activityRef.current.value),
          cityId: parseInt(cityRef.current.value),
  
        }
        updateBasicData(token,id,data,i18n.language).then((response) => {
         localStorage.setItem("name",data.name)
          localStorage.setItem("phone",data.phone)
          props.setUserName(data.name)
          console.log(response)
        setLoading(false)
         
props.setEdit(false)
 setSuccess(true)

}).catch(function (error) {
       
  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)
     })}


      
     useEffect(() => {
  
      formik.setValues({
        name: props.data.user.name,
        phone: props.data.user.phone,
        company:props.data.facilityName,
        activity:props.data.externalSaleType=="prepayment"? parseInt(props.data.clientActivity.id): props.data.facilityActivity!==null? parseInt(props.data.facilityActivity.id):null,
        city:parseInt(props.data.city.id),
        password:null,
        password_confirmation:null
      });
    
    }, [props.data]);

 
   return (
    <div className='container-fluid '>
      {success? <SuccessModal setActive={props.setActive} setEdit={props.setEdit} txt={t("profile.succss1")} setModal={setSuccess}/>:null}
 {loading?
    <div style={{height:"50vh",margin:"0"}} className='loader '>
    <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<>
{props.edit?null:
<div className='formTopper row'>
<p> {t("profile.myData")}</p>

</div>}
<div className={props.edit?null: "dataContainer row"}>


 <form className={props.edit?"row gy-3 ":"row gy-3 disabledForm"} style={loading?{display:"none"}:null} onSubmit={formik.handleSubmit}>
       <div className='col-xl-4 col-sm-6'>
       <div className="inputDiv">
    <label> {t("Login.yourName")}:</label>
    <input type="text" placeholder= {t("Login.yourName")} value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.name && formik.touched.name) || validation.name.err==true?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>
<span>{validation.name.err=true? validation.name.text:null}</span>

    </div></div>
    <div className='col-xl-4 col-sm-6'>
    
    <div className="inputDiv">
    <label> {t("register2.mobile")}:</label>

    <input type="text" placeholder={t("register2.mobile")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.phone && formik.touched.phone) || validation.phone.err==true?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
<span>{validation.phone.err=true? validation.phone.text:null}</span>

    </div></div>
    <div className='col-xl-4 col-sm-6'>
    
    <div className="inputDiv">
    <label> {t("register.company")}:</label>
    <input  placeholder={t("Login.company")} value={formik.values.company} id="company" name="company"  ref={companyRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.company && formik.touched.company) || validation.facilityName.err== true ?"invalid":null}/>
<span>{formik.errors.company && formik.touched.company?t(`${formik.errors.company}`):null}</span>
<span>{validation.facilityName.err=true? validation.facilityName.text:null}</span>

    </div></div>
 
    <div className='col-xl-4 col-sm-6'>
    
    <div className="inputDiv">
    <label> {t("register.companyActiv")}:</label>
    <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

    <select defaultValue={parseInt(formik.values.activity)}  value={formik.values.activity} id="activity" name="activity"  ref={activityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.activity && formik.touched.activity) ||validation.activityId.err==true?"invalid chosenSelect":"chosenSelect"}>
<option selected disabled value="">{t("Login.companyActivChoose")}</option>

{activities && activities.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.name_ar:data.name_en} </option>

  
  )
}

</select>
<div class="select-arrow">
<BsChevronDown size={20}/>

</div>
</div>
<span>{formik.errors.activity && formik.touched.activity?t(`${formik.errors.activity}`):null}</span>
<span>{validation.activityId.err=true? validation.activityId.text:null}</span>

    
    </div>
    </div>
    <div className='col-xl-4 col-sm-6'>
  
    <div className=  {props.type=="prepayed"?"inputDiv inputDivDisabled":"inputDiv"}>
    
    <label> {t("register.city")}:</label>
    <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

    <select disabled={props.data.externalSaleType=="prepayment"} value={formik.values.city} id="city" name="city"  ref={cityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.city && formik.touched.city) ||validation.cityId.err==true?"invalid chosenSelect":"chosenSelect"}>
<option selected disabled value="">{t("Login.cityChoose")}</option>
{city && city.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

  
  )
} 

</select><div class="select-arrow">
<BsChevronDown size={20}/>

</div></div>

<span>{formik.errors.city && formik.touched.city?t(`${formik.errors.city}`):null}</span>
<span>{validation.cityId.err=true? validation.cityId.text:null}</span>

    </div>
    
    </div>
  
    <div> 
   </div>
    {i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}

{props.edit?
    <div className="formBtns" style={{marginTop:"4em"}}>
    <button type="submit"  className="dataEdit">{t("profile.save")}</button>
    <span> </span>


    <button type="button" className="cancelEdit" onClick={()=> props.setUpdate(!props.update)}>  {t("profile.cancel")}</button></div>
   :
  null

}
    </form></div>
    {props.edit? null:

    <>
    {cahngePass?
    <div>
<ChangePass setActive={props.setActive} setEdit={props.setEdit} setChangePass={setChangePass}/>
    </div>
:
    <div className="inputDiv" style={{width:"95%"}}>
    <button  type="button" className="changePass" onClick={()=>setChangePass(true)}><p>  {t("profile.change_passQ")}</p></button>
    </div>
}
    </>
    
    
    }
</>}
</div>


    );
}

export default BasicData;