
import "../../styles/card.css";

import { useRef,useState,useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { useTranslation } from 'react-i18next';
import visa from "../../images/visa.png"
import master from "../../images/masterc.png"
import { useNavigate } from "react-router-dom";
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import { Get_Wallet ,charge_wallet} from "../../api";
import { Link } from "react-router-dom";
function ChargeWallet() {
    const { t, i18n } = useTranslation();
    let history = useNavigate();
    const [submitLoading,setSubmitLoading]=useState(false)

const [loading,setLoading]=useState(true)
const [cardValid,setCardValid]=useState(false)
const [paymentType,setPaymentType]=useState("")

 const[maxChargeWallet,setMaxChargeWallet]=useState()
 const[minChargeWallet,setMinChargeWallet]=useState()

const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();

const [errorMsgModel, setErrorMsgModel]=useState(false);
const [errorMsgModel2, setErrorMsgModel2]=useState(false);

 const numRef=useRef()


 //Get_Wallet api call to get maximum amount allowed to be charged by the user
  useEffect(()=>{
 
    window.scrollTo(0, 0);
 
    const token= window.localStorage.getItem('token');
  

    Get_Wallet(token).then(response => {
      console.log(response)
      setMinChargeWallet(response.data.data.minChargeWallet)

      setMaxChargeWallet(response.data.data.maxChargeWallet)     
       setPaymentType(response.data.data.paymentOnlineType)
   setLoading(false)
    }).catch(function (error) {
        if (error.response.status === 403) {
            window.location.pathname = "/verify"
        } else if (error.response.status === 401) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("phone");
                  localStorage.removeItem("name");
                  localStorage.removeItem("fieldsArr")
                
                  history("/")
               }
              
               
           
        

  })},[ ])
    

//takes amount to be charged
// checks if that amount is more than the maximum allowed (allowed amount is known from Get_Wallet api call above)
//user is then redirected to third party website
//third party website redirects user to payment-success screen (PaymnetSuccess component)
function handleSubmit(e){
//e.preventDefault()
setLoading(true)
const token= window.localStorage.getItem('token');

if(!e.token){
    e.preventDefault()}
    

else{
    localStorage.setItem("walletToken",JSON.stringify(e))
}  
  
if(parseInt(numRef.current.value) < minChargeWallet){
    setMsgAr("الحد الادنى لشحن رصيدك "+ minChargeWallet)
    setMsgEn("Minimum  Amount for charging wallet "+ minChargeWallet)
    setErrorMsgModel2(true)
    setLoading(false)
    setSubmitLoading(false)

}
else if(parseInt(numRef.current.value) > maxChargeWallet){

    setMsgAr("الحد الاقصي لشحن رصيدك "+ maxChargeWallet)
    setMsgEn("Max Amount for charging wallet "+ maxChargeWallet)
    setErrorMsgModel2(true)
    setLoading(false)
    setSubmitLoading(false)

}else{

    let data = {
        token:paymentType=="checkout"? e.token: "",
        amount: numRef.current.value,
        paymentMethod: paymentType,
        platform: "external-sales",
      
    };


    charge_wallet(token,data).then((response) => {
        localStorage.setItem("tapPayment","wallet")
        localStorage.setItem("transactionType","wallet")

     
        if(paymentType=="checkout"){
                if(response.data.data.url!=="" && response.data.data.url!==null){
                    window.location.href = response.data.data.url
                    localStorage.removeItem("walletToken")
                    localStorage.setItem("checkPayment","wallet")
                
                }
                
                else {
                    localStorage.removeItem("walletToken")
                
                
                    history("/profile/my-wallet") 
                }
                        }
                
                       else if (response.data.message === "success") {
                            localStorage.setItem("payment","wallet")
                            
                            window.location.href = response.data.data.url
                        } else {

            setMsgAr("لا يمكن إتمام العملية")
            setMsgEn("The operation could not be completed")
            setErrorMsgModel(true)
            setLoading(false)
        }

    }).catch(function (error) {
 
            if (error.response.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("phone");
              localStorage.removeItem("name");
              localStorage.removeItem("fieldsArr")
            
              history("/")
           }
          
           
       
        setMsgAr("حدث خطأ, لا يمكن إتمام العملية")
        setMsgEn("Something wrong, The operation could not be completed")
        setErrorMsgModel(true)
        setLoading(false)
      
    })


}


}

    return (
 
        <div>
      
       


<div style={{marginTop:"2em"}} className="cpMiddle-title2"> 
 
 <div  style={{textAlign:"center"}} className="cpPayment chargeWallet">
 {loading?
      <div style={{height:"28vh",margin:"auto"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
  <div >
      <form onSubmit={(e)=>handleSubmit(e)}>
  <p className="addTitle">{t("profile.charge")}</p>

<input ref={numRef} type="number" placeholder={t("profile.enterCredit")} required={true} className={errorMsgModel2?"invalid":null}/>
{i18n.language=="ar"?
<span className="errMsg">{errorMsgModel2? msgAr:null}</span>:
<span  className="errMsg">{errorMsgModel2? msgEn:null}</span>}


{paymentType=="checkout"?
<div className="creditCard" style={{margin:"auto",marginTop:"1.5em",maxWidth:"600px"}}>
              <Frames
 
 cardValidationChanged= {(e) => {


    setCardValid(e.isValid)
   
}}
                config={{ 
                    publicKey: `${process.env.REACT_APP_PAYMENT_KEY}`,
                    schemeChoice: true,
                }}
                cardTokenized={(e) => {
                    handleSubmit(e)
                }}
            >
                <CardNumber />
                <div className="date-and-code">
                    <ExpiryDate />
                    <Cvv />
                </div>

       

                { submitLoading?  <div  className='loader walletLoader'>
    <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} />
    <p className="paymentLoad">{t("payment.processing")}</p>
    </div>:  
   <button disabled={!(cardValid)}                    id="pay-button"
                     
                    onClick={() => {
                        setSubmitLoading(true)

                        if( localStorage.getItem("walletToken")){
                            handleSubmit(JSON.parse(localStorage.getItem("walletToken")))
                          }
                          else{
                            Frames.submitCard();}

                    }}
                >
                  {t("profile.charge")}
                </button>}
            </Frames>
            </div>

:


<div style={{marginTop:"1em"}} className="chrgCenter">
<input type="radio" id="payment" defaultChecked/>
<label htmlFor="payment">{t("process.credit")}</label>
 </div>}





{i18n.language=="ar"?
<span className="errMsg">{errorMsgModel? msgAr:null}</span>:
<span  className="errMsg">{errorMsgModel? msgEn:null}</span>}

{paymentType=="checkout"?null:

<div className="formBtns" style={{marginTop:"2em"}}>
<button type="submit" className="dataEdit chargeWalletBtn">{t("process.continue")}</button>


</div>
}
</form>
</div>
}
 

 </div>





        </div>
        
  


</div>

  
    );
  } 
  
  
  export default ChargeWallet;