import '../styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

function DeleteAddress(props) {
   
    const { t, i18n } = useTranslation();

  function handleDelete(){

props.handleDelete()
  }
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"auto",paddingTop:"2em",position:"fixed"}}>
        

<p className='alrt'>{t("profile.delete_address")}</p>
<div className='modalBtns'>
<button  onClick={handleDelete}  className='del'>{t("menu.confirm")}</button>
<button  onClick={()=>props.setModal(false)}  className='cls'>{t("menu.cancel")}</button>

</div>
        </div>
      </div>
  
    );
  }
  
  export default DeleteAddress;
  
  