import { useTranslation } from 'react-i18next';
import '../styles/Menu.css';
import { useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useState,useRef } from 'react';
import img from "../images/login/logo.svg"
import {ImGift, ImInfo} from 'react-icons/im';
import { RiShoppingCartFill,RiDeleteBin6Line } from 'react-icons/ri';
import AddMealModal from '../components/AddMealModal';
import { application,payment_methods,categories,products,product,Get_Basket ,Edit_Quantity,Clear_cart,Delete_Item,create_order,check_offer} from '../api';
import { useNavigate } from 'react-router';
import { BeatLoader } from 'react-spinners';
import CleartCart from '../components/ClearCart';
import RemoveItem from '../components/RemoveItem';
import ErrorModal from '../components/ErrorModal';
import DiscountModal from '../components/DiscountModal';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";

import { BsCart2 } from "react-icons/bs";

import { MdArrowForwardIos } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import LimitError from '../components/LimitError';
function Menu() {
  const [toggleCart,setToggleCart]=useState(false);
  const[note,setNote]=useState("")
  const[paymentMethods,setPaymentMethods]=useState({})
  const[noteText,setNoteText]=useState()
  const[notesSearch,setNotesSearch]=useState("")
  const[noNotes,setNoNotes]=useState(false)
  const[errorLimit,setErrorLimit]=useState(false)

    const { t, i18n } = useTranslation();
    const [searchTerm,SetSearchTerm]=useState('');
    const[errModal2,setErrModal2]=useState(false)
    const[arMsg,setArMsg]=useState()
    const[enMsg,setEnMsg]=useState()
    const[modal,setModal]=useState(false)
    const[categoryId,setCategoryId]=useState()
    const[productList,setProductList]=useState()
    const[productData,setProductData]=useState()
    const[noProduct,setNoProduct]=useState()
    const[loading,setLoading]=useState(true)
    const[menuLoading,setMenuLoading]=useState(false)
    const[cart,setCart]=useState()
    const[delId,setDelId]=useState()
    const[removeModal,setRemoveModal]=useState()
    const[cartChange,setCartChange]=useState()
    
    const[submitLoading,setSubmitLoading]=useState(false)
const [selectedPayment,setSelectedPayment]=useState(localStorage.getItem("payment")?localStorage.getItem("payment"):"")
    const[clearModal,setClearModal]=useState(false)
    const[errModal,setErrModal]=useState(false)
    const[mealId,setMealId]=useState()
    const[quanLoading,setQuanLoading]=useState()
    const[freeMeals,setFreeMeals]=useState([])
    const [notes, setNotes]=useState([]);

    const[discount,setDiscount]=useState(false)

    const[menuCategories,setMenuCategories]=useState()
let history= useNavigate()
const codeRef=useRef()
    var checkList;
    function dropDown(){
    
    
      checkList = document.getElementById('list1');
      if (checkList.classList.contains('visible')){
      checkList.classList.remove('visible');}
      else{
        checkList.classList.add('visible');}
     
    
    
}
   
function handleNotes(e){

  var checkArr=[]
  var check = document.getElementsByClassName('checkNotes');
  for(var i=0; i<check.length; i++) { 
    if(check[i].checked){
   
      checkArr.push(""+check[i].value )
  
  }
  }
  const notes= checkArr.toString()
setNote(notes)
localStorage.setItem("notesCheckbox",notes)

if(localStorage.getItem("editNotes")){
localStorage.setItem("editNotes",notes)}

}

var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)


useEffect(() => {

  
    const token= localStorage.getItem("token");
    SetSearchTerm("")
    setNoProduct(false) 

    application().then((response) => {
      console.log(response)
    })
      
  categories(token,1).then((response) => {
    setMenuCategories(response.data.data)
    
  if(categoryId==null){
    setCategoryId(response.data.data[0].id)
    getProducts(response.data.data[0].id);
  }
  else{
    getProducts(categoryId);
  }
  
  
  }).catch(function(error){
 
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     

   setArMsg(error.response.data.error)
   setEnMsg( error.response.data.error)
     setErrModal(true);
  
     setLoading(false)
     setTimeout(function() {
   
         setErrModal(false)
       }, 2500)
   
   });
  
   Get_Basket(token).then(response => {

    localStorage.setItem('CartId',response.data.data.cart.id);



    localStorage.setItem("currencyAr",response.data.data.cart.currencyAr)
    localStorage.setItem("currencyEn",response.data.data.cart.currencyEn)

    setCart(response.data.data.cart);
    setNotes(response.data.data.cart.notes)

    payment_methods(token,response.data.data.cart.id).then(response => {
      setPaymentMethods(response.data.data)
      setLoading(false)
      setSelectedPayment(response.data.data.paymentMethods)
    })
 
  }).catch(error => {

    setLoading(false)
    setCart({});

  })
  
 
  
  },[cartChange])
  
   
 
  //called on changing category
  //set active category
  //products api call takes token, chosen category id, branch id and order source code
  //fetch products
  const getProducts = (categoryId) => {
    localStorage.setItem("categoryId",categoryId)
  
    const token= localStorage.getItem("token");
  
  
    products(token,categoryId,localStorage.getItem("branchId"), localStorage.getItem("orderSource")).then((response) => {
      setCategoryId(categoryId)
      setProductList(response.data.data)
   
      setMenuLoading(false)
   
     //(false)
    }).catch(function(error){
 
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("phone");
          localStorage.removeItem("name");
          localStorage.removeItem("fieldsArr")
        
          history("/")
       }
      
       
     
    
     setArMsg(error.response.data.error)
     setEnMsg( error.response.data.error)
       setErrModal(true);
    
       setMenuLoading(false)
       setLoading(false)
       setTimeout(function() {
     
           setErrModal(false)
         }, 2500)
     
     });
    
  };
  //to presist category
  useEffect(() => {
  
  
    setCategoryId(localStorage.getItem("categoryId"))
  
  },[])
  
  
  //called on choosing product
  //product api call takes token, chosen product id, branch id and order source code ()=> fetch product data
  const getProduct = (productId) => {
   
    const token= localStorage.getItem("token");
  
  
    product(token,productId,localStorage.getItem("branchId"), localStorage.getItem("orderSource")).then((response) => {
    
      setProductData(response.data.data)
      
    setModal(true);
    
    }).catch(function(error){
 
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("phone");
          localStorage.removeItem("name");
          localStorage.removeItem("fieldsArr")
        
          history("/")
       }
    
     setArMsg(error.response.data.error)
     setEnMsg( error.response.data.error)
       setErrModal(true);
    
       setTimeout(function() {
     
           setErrModal(false)
         }, 2500)
     
     });
    
  };
  
  
  
  
  


//on searching
//checks if products exist matching search input value
//if not (not products are available) is displayed  "setNoProduct(true)"
function handleSearch(e){
  SetSearchTerm(e.target.value)


 const products= productList && productList.filter((data)=>{
    if(e.target.value===""){
        return data;
    } 
    else if(data.enName.toLowerCase().includes(e.target.value.toLowerCase())|| data.arName.toLowerCase().includes(e.target.value.toLowerCase()) || data.arDescription.toLowerCase().includes(e.target.value.toLowerCase())|| data.enDescription.toLowerCase().includes(e.target.value.toLowerCase())){
   
   return data
   }
  
  
 })
if(products.length==0){
 setNoProduct(true) 

}
else{
  setNoProduct(false) 
 
}
}


//on confirming clear in ClearCart component
//Clear_cart api call takes cart id and token
 
function handleClear(){
  setClearModal(false)
  setLoading(true)
       const token= window.localStorage.getItem('token');
       Clear_cart(localStorage.getItem('CartId') ,token).then(response => {
       

        setCartChange(!cartChange) //called to rerender useEffect
     

     
      }).catch(err =>{
        setLoading(false)
 
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("fieldsArr")
          
            history("/")
         }
        
         
   

      })


      }
//on confirming clear in RemoveItem component
//Delete_Item api call takes cart id and meal id (set when clicking delete on a meal) token
      function handleRemove(){

        setRemoveModal(false)
        setLoading(true)
        const token= window.localStorage.getItem('token');

         Delete_Item(localStorage.getItem('CartId') ,delId,token).then(response => {
             
          setCartChange(!cartChange) //called to rerender useEffect
   
       
     
        }).catch(err =>{
          setLoading(false)
 
            if (err.response.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("phone");
              localStorage.removeItem("name");
              localStorage.removeItem("fieldsArr")
            
              history("/")
           }
          
           
   
  
        })
 
 
        }

  
  //onChanging meal quantity 
//Edit_Quantity api call takes cart id, meal id, new quantity and token
function handleChangeQuantity(mealId,quantity,oldQuantity){
  setQuanLoading(true)
  const token= window.localStorage.getItem('token');
  setMealId(mealId)

  if(quantity<1){
 
   document.getElementById(`${mealId}`).value = oldQuantity;
    setQuanLoading(false)
  }
  else{
    document.getElementById(`${mealId}`).value = quantity;

    Edit_Quantity(localStorage.getItem('CartId'),mealId,quantity,token).then(response => {
    
      setQuanLoading(false)

      setCartChange(!cartChange)  //called to rerender useEffect

  
    }).catch(function(error){
 
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     
     }); 
  }

 
  
}  






function createOrder(){

  if(paymentMethods.paymentMethods=="wallet" &&(parseInt(paymentMethods.walletBalance)< paymentMethods.totalPrice)){

    setErrModal(true);
    setEnMsg("your balance is not sufficient");
    setArMsg("رصيدك غير كافٍ");
    setSubmitLoading(false);
    setTimeout(function() {
     
      setErrModal(false);
     }, 4000)
  }
  else{
  setSubmitLoading(true);
  const token= localStorage.getItem("token");


  var arr=[]
  var elements = document.getElementsByClassName('checkNotes');
  for(var i=0; i<elements.length; i++) { 
    if(elements[i].checked){
   
  arr.push(elements[i].value)
  
  }
  }
  
  arr.push(noteText)
  const notes= arr.toString()

  const data2={

    paymentMethod:selectedPayment,
    notes:notes,
    source:"external-sales",
    date:localStorage.getItem("now")=="true"?"":localStorage.getItem("date"),
    now: localStorage.getItem("now")?localStorage.getItem("now"):"true",
    time:localStorage.getItem("now")=="true"?"":localStorage.getItem("time"),
    applicationId:6
      }
create_order(data2,token,i18n.language).then(response => {
  localStorage.setItem("orderId", response.data.data.orderId); 
  localStorage.removeItem("now")
  localStorage.removeItem("addressId")
  localStorage.removeItem("branchId")


if(selectedPayment === "tap"){
if (response.data.data.url === null) {
console.log("tap");

}
else {
  localStorage.setItem("tapPayment","order")


window.location.href = response.data.data.url
}
}
else {
history("/order-received")
}


}).catch(error => {

 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }
  
   
 else if(error.response.data.error=="للأسف, لا يمكنك انشاء الطلب يلزم ان يكون الحد اكبر من او يساوي قيمه الطلب، الحد الخاص بك :0" || error.response.data.error=="Sorry, you can’t create the order, must your limitation be equal to or bigger than the order amount , your limit is :0"){
  setErrorLimit(true);
  setEnMsg(error.response.data.error);
  setArMsg(error.response.data.error);
  setSubmitLoading(false);
 }
else{

  getError(error.response.data.error)
}
  

})



  }

}



function getError(msg){
  if (msg === "الرجاء الانتظار ، هناك طلب قيد التنفيذ") {
  setErrModal(true);
  setEnMsg("Please wait, there is a request under process");
  setArMsg("الرجاء الانتظار ، هناك طلب قيد التنفيذ");
  setSubmitLoading(false);
  setTimeout(function() {
   
    setErrModal(false);
   }, 4000)}
  else if (msg === "empty_wallet") {
    setErrModal(true);
    setEnMsg("Your balance is not sufficient");
    setArMsg("رصيدك غير كافٍ");
    setSubmitLoading(false);
    setTimeout(function() {
     
      setErrModal(false);
     }, 4000)}
 else if (msg === "branch does not support branch pick up") {
    setErrModal(true);
    setEnMsg( "branch does not support branch pick up");
    setArMsg( "الفرع لا يدعم خدمة توصيل الطالبات");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
     
  } else if (msg === "branch not support delivery") {

    setErrModal(true);
    setArMsg( "الفرع لا يدعم خدمة توصيل المنزل");
    setEnMsg( "branch does not support delivery");
    setSubmitLoading(false);

    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } else if (msg === "branch not exist") {
    setErrModal(true);
    setArMsg( "الفرع خارج نطاق الخدمة");
    setEnMsg( "branch does not exist");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
     
  } else if (msg === "branch not active") {
    setErrModal(true);
    setArMsg(  "الفرع غير متاح حاليا");
    setEnMsg("branch is not active");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
    setSubmitLoading(false);
  } else if (msg === "branch not open") {
    setErrModal(true);
    setArMsg( "الفرع مغلق الأن");
    setEnMsg("branch does not open");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } else if (msg === "meal not exist") {
    setErrModal(true);
    setArMsg("الوجبة غير متاحة حاليا");
    setEnMsg( "meal does not exist");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } else if (msg === "meal not active") {
    setErrModal(true);
 
    setArMsg("الوجبة غير متاحة حاليا");
    setEnMsg( "meal is not active");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } else if (msg === "product not delivery") {
    setErrModal(true);
    setArMsg( "الوجبة غير متاحة للتوصيل");
    setEnMsg( "product not available for delivery");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } else if (msg === "additional not delivery") {
    setErrModal(true);
    setArMsg( "الإضافات غير متاحة للتوصيل");
    setEnMsg( "additional not available for delivery");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } else if (msg === "extra not exist") {
    setErrModal(true);
    setArMsg(  "الزيادات غير متاحة");
    setEnMsg(  "extra does not exist");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } else if (msg === "extra not belong to product") {
    setErrModal(true);
    setArMsg(   "الزيادات غير متوفرة مع الوجبة");
    setEnMsg(  "extra not belong to product");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  }


  else if (msg === "address not valid") {
    setErrModal(true);
    setArMsg(  "العنوان غير صحيح");
    setEnMsg(   "address not valid");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  }
  else if (msg === "time not in branch time") {
    setErrModal(true);
    setArMsg( "الفرع مغلق في الوقت المحدد");
    setEnMsg(  "time not in branch time");
     
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  } 
  else if (msg === "branch not support fast delivery") {
    setErrModal(true);
    setArMsg( "الفرع لا يوفر التوصيل السريع");
    setEnMsg( "branch not support fast delivery");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  }
  else if (msg === "branch busy") {
    setErrModal(true);
    setArMsg( "الفرع مشغول، تقدر تطلب بعد الزحام.");
    setEnMsg("This branch is currently busy. You can order for later when branch is not busy.");
     
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  }
  else if (msg === "branch not support ordinary delivery") {
    setErrModal(true);
    setArMsg(  "الفرع لا يوفر التوصيل العادي");
    setEnMsg("branch not support ordinary delivery");
    setSubmitLoading(false);
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  }
  
  else {

    setErrModal(true);
    setArMsg(msg);
    setEnMsg(msg);
    setSubmitLoading(false)
    setTimeout(function() {
         
      setErrModal(false);
     }, 4000)
  }
};



function handleSearchNotes(e){
  setNotesSearch(e.target.value)


 const cartNotes= notes && notes.filter((data)=>{
    if(e.target.value===""){
        return data;
    } 
    else if(data.toLowerCase().includes(e.target.value.toLowerCase())){
   
   return data
   }
  
  
 })
if(cartNotes.length==0){
  setNoNotes(true) 

}
else{
  setNoNotes(false) 
 
}
}
    return (
      <div className={i18n.language=="ar"?null:"menuEn"}>
      
      <div className="orderDetTop" style={{position:"relative",bottom:"30px"}}>
                <div>

            
                <p className="orderDt1">{t("orderDetails.profile")}</p>
                <p className="orderDt2">{t("orderDetails.orders")}</p>
                <div className="orderDetNav">
              <Link to="/add-order" onClick={()=>{
          localStorage.removeItem("branchId");
          localStorage.removeItem("addressId")

   }}> {t("addOrder.details")} </Link> 
      
              { i18n.language=="ar"?<IoIosArrowBack className="arrow"  color="#98A2B3"/>:<IoIosArrowForward className="arrow"  color="#98A2B3"/> } <a style={{fontWeight:"bold"}}>{t("meals.menu")}</a>
                </div>    </div>
              </div>
        <div style={{marginTop:"2em"}} className='container-fluid fluid customPadding'>
{errorLimit? <LimitError txt={i18n.language=="ar"? arMsg:enMsg} setModal={setErrorLimit}/>:null}
{discount? <DiscountModal createOrder={createOrder} setModal={setDiscount}  freeMeals={freeMeals}/>:null}
           {removeModal?<RemoveItem handleRemove={handleRemove} setModal={setRemoveModal} />:null}
 {errModal? <ErrorModal setModal={setErrModal} msg={i18n.language=="ar"? arMsg:enMsg}/>:null}
 {clearModal?<CleartCart handleClear={handleClear} setModal={setClearModal} />:null}

            {modal?
            
        <AddMealModal setModal={setModal} setLoading={setLoading} data={productData} cartChange={cartChange} setCartChange={setCartChange}/>:null}
           {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
<>

<div className='container-fluid menuContainer'>



        <div className='row g-1 menu'>
        <div className='col-lg-2 col-md-2 col-sm-12'>
     
     
  <div style={{position:"sticky",top:"20px",width:"100%"}}>
    <div className='categAlign'>
<p className='menuTitle'>{t("menu.categories")}:</p>


<div className='srchPos srchSmall' style={{marginTop:"7px"}}>
<input style={{maxWidth:"300px"}} value={searchTerm} onChange={(e)=>handleSearch(e)} placeholder={t("dashboard.search")}/> 
  <AiOutlineSearch className={i18n.language=="ar"?'srchIcn':'srchIcn srchIcnEn'} size={30}/>
</div>


</div>
<div className={i18n.language=="ar"?'categories':'categories categoriesEn'}>
 
{menuCategories && menuCategories.map((data, index) => 
  <div key={index} onClick={()=>{setCategoryId(data.id)
    getProducts(data.id)
    setMenuLoading(true)
  }} className={touchsupport? data.id==categoryId?'activeCategory':null:  data.id==categoryId?'non-touch activeCategory':'non-touch'}>
<p>{i18n.language=="ar"? data.arName:data.enName}</p>
</div> 
)}

   
   </div>

</div>
</div> 

<div   className={cart.meals && cart.meals.length>0?'col-lg-6 col-md-9 col-sm-11 col-11 meals':'col-lg-10 col-md-10 col-sm-12 col-12 meals'}>

{!(cart.meals && cart.meals.length)>0?
<div className='srchPos' style={{marginTop:"7px"}}>
<input style={{maxWidth:"300px"}} value={searchTerm} onChange={(e)=>handleSearch(e)} placeholder={t("dashboard.search")}/> 
  <AiOutlineSearch className={i18n.language=="ar"?'srchIcn':'srchIcn srchIcnEn'} size={30}/>
</div>:
        <div className='menuSrch'>
<div className='menuMargin'></div>

</div>}

<div className='srchPos srchMobile' style={{marginTop:"7px"}}>
<input style={{maxWidth:"300px"}} value={searchTerm} onChange={(e)=>handleSearch(e)} placeholder={t("dashboard.search")}/> 
  <AiOutlineSearch className={i18n.language=="ar"?'srchIcn':'srchIcn srchIcnEn'} size={30}/>
</div>


{menuLoading?
               <div style={{height:"60vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={menuLoading}  size={30} /></div>:
      <div className={cart.meals && cart.meals.length>0?'container-fluid customPadding':'container-fluid'}>
<div className='row gy-4'>


{productList && productList.filter((data)=>{
    if(searchTerm===""){ //for filtering products on search
        return data;
    } 
    else if(data.enName.toLowerCase().includes(searchTerm.toLowerCase())|| data.arName.toLowerCase().includes(searchTerm.toLowerCase())){
   
   return data
   }
  
  
 }).map((data, index) => 

<div className={cart.meals && cart.meals.length>0?' col-sm-6 colMobile  ':'col-lg-3 col-md-4 col-sm-6 colMobile'} key={index}>
<div className='mealDiv'>

<div className='itemImg' onClick={()=>parseInt(data.active) === 0?null:getProduct(data.id) } style={{ backgroundImage: `url(${data.image})` }}></div>
<div className='menuItemBottom'>

<p  onClick={()=>parseInt(data.active) === 0?null:getProduct(data.id) } className='mealName'> {i18n.language=="ar"?data.arName:data.enName} </p>
<p style={{width:"104%"}} onClick={()=>parseInt(data.active) === 0?null:getProduct(data.id) } className='mealDescribe overFlowElips'> {i18n.language=="ar"?data.arDescription:data.enDescription} </p>


<div className='itemBottom'>
<button disabled={(parseInt(data.active) === 0)} onClick={()=>parseInt(data.active) === 0?null:getProduct(data.id)}><RiShoppingCartFill size={25}/> {t("menu.addToCart")} </button>

<p className='mealPrice'>{data.price} {i18n.language=="ar"?data.arCurrency:data.enCurrency}</p>

 </div> </div>
</div>

</div>

)}

</div></div>

}

{

  noProduct?
  <div className='noProd'>
<p>{t("menu.noProd")}</p>
  </div>:null
}




</div>

{cart.meals && cart.meals.length>0?
  <div  className={i18n.language=="ar"?'col-lg-4 col-md-1 col-1':'col-lg-4 col-md-1 col-1 carEn'}>

    <div className='srchPos'>
<input value={searchTerm} onChange={(e)=>handleSearch(e)} placeholder={t("dashboard.search")}/> 
  <AiOutlineSearch className={i18n.language=="ar"?'srchIcn':'srchIcn srchIcnEn'} size={30}/>
</div>


  <div style={toggleCart?{display:"none"}:null} className='cartActn' onClick={()=>setToggleCart(!toggleCart)}>

  <button className='cartBtn carIcn'  >
  <BsCart2  size={25} />
</button>
<p className='crtTxt'>{t("menu.cart")}</p>

<MdArrowForwardIos size={15} className="carArrow"/>

</div>
<div  className={toggleCart?'cart cartOpen':' cart cartClosed'}>
<button className='cartBtn'  onClick={()=>setToggleCart(!toggleCart)}>

<IoClose  size={30} />

</button>
 <div className='toggleCart'>

  <div className='cartTop'>
<p>{t("menu.orderData")}</p>
<button onClick={()=>setClearModal(true)}> 
{t("menu.clear")}  <RiDeleteBin6Line style={{color:"#e11857"}}/></button>
</div>





<div  id="list1" className="dropdown-check-list" tabIndex="100">
  <div style={i18n.language=="ar"?{paddingLeft:"0.5em"}:{paddingRight:"0.5em"}} className='srchNote'>
  <span onClick={dropDown} className="anchor">{note==""? t("profile.choose"): note}  
  
  </span>

  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M5 7.5L10 12.5L15 7.5" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  </div>
 
  <ul className="items"> 
  <li style={{position:"relative"}}>
  <input type="text" placeholder={t("dashboard.search")} value={notesSearch} onChange={(e)=>handleSearchNotes(e)}/>
  <AiOutlineSearch className={i18n.language=="ar"?'srchIcn':'srchIcn srchIcnEn'} size={25}/>
</li>
{notes? notes.filter((data)=>{
    if(notesSearch===""){ //for filtering products on search
        return data;
    } 
    else if(data.toLowerCase().includes(notesSearch.toLowerCase())){
   
   return data
   }
  
  
 }).map((data,index)=>
      <li key={index} >
                <input type="checkbox" className='checkNotes'  value={data} onChange={(e)=>handleNotes(e)}/>
        <span> {data}</span> 
</li>
    
     )
:null}
{noNotes? 
<li>{t("addOrder.noResult")}</li>:null
}


        </ul>
</div>
<textarea placeholder={t("profile.enterNote")}  name='notes' className='noteText' value={noteText} onChange={(e)=>{setNoteText(e.target.value)}}/>
 




 
<p className='cartTitle'>{t("menu.cart")}:</p>

  {cart.meals && cart.meals.map((data,index) =>

<div className='row cartItem' key={index}>
  <div className='col-4'>

  <div  className='cartItem-Img' style={{ backgroundImage: `url(${data.image})` }}></div>
 
  </div>
  <div className='col-8'>
    <p className='cartItem-name' >{i18n.language=="ar"?data.product.arName:data.product.enName}</p>
    <p style={{width:"99%"}} className='cartItem-detail overFlowElips' >{i18n.language=="ar"?data.product.arDescription:data.product.enDescription}</p>

    <p  className='cartItem-detail' >{i18n.language=="ar"?data.size.arName:data.size.enName}   </p>
    {data.additional.arName?<>
    <p className="cpMiddle-p3">{t("meals.additions")}</p>
   <p className='cpMiddle-p4'>{i18n.language=="ar"?data.additional.arName:data.additional.enName}</p> 
   </>
   :null}

{data.extras.length!==0?<>
    <p className="cpMiddle-p3">{t("meals.extras")}</p>
  
    {data.extras && data.extras.map((data,index) =>

<>
<p className="cpMiddle-p4">{i18n.language=="ar"? data.arName:data.enName} <span style={{margin:"0 10px"}}> </span></p> 

</>)}  </>
:null}

   <p className='cartItem-price'>
<span style={{fontWeight:"600"}}>{t("menu.price")}</span> {data.price* data.quantity} {i18n.language=="ar"?cart.currencyAr:cart.currencyEn}
</p>

<div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>

<div className='cartItem-quan'>
<button  onClick={()=>handleChangeQuantity(data.id,data.quantity+1,data.quantity)}>+</button>
<div style={{position:"relative",display:"inline-block"}}> 
{mealId == data.id && quanLoading?
<div style={{height:"18px"}} className='loader quantityLoader'> 
      <BeatLoader color={'#E47100'} loading={quanLoading}  size={10} /></div>:null}
  
<input disabled={data.quantity>=data.avalilableQuantity}  type={"number"} id={data.id} className='quanBtn' value={data.quantity} onBlur={(e)=>handleChangeQuantity(data.id,e.target.value,data.quantity)}/></div>
<button disabled={data.quantity==1} onClick={()=>handleChangeQuantity(data.id,data.quantity-1,data.quantity)}>-</button>
</div>

<button onClick={()=>{
      setDelId(data.id)

      setRemoveModal(true)
    }} className="removeItem"><RiDeleteBin6Line style={{color:"#e11857"}} size={16}/>{t("profile.delete")} </button> 
</div>
</div>
</div>


)}



 


<p  className='cartTitle'>{t("menu.paymentMethod")}:</p>


{paymentMethods.paymentMethods=="wallet"?
<div className='paymentType'>
<input type="radio" name='payment' value="wallet" id="wallet" defaultChecked={true} />

<label htmlFor='wallet' id="walletMethod" style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}>{t("menu.balance") } <span>{paymentMethods.walletBalance} {i18n.language=="ar"? cart.currencyAr:cart.currencyEn}</span>

</label>
</div>:
<div className='paymentType'>
<input type="radio" name='payment' value="later" id="later" defaultChecked={true} />

<label htmlFor='later' style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}>{t("menu.later") }

</label>
</div>

}



{/*
<p  className='cartDivTitle'>{t("menu.promoCode")}</p>
<div className='promoCode' >

  <div style={{position:"relative"}}>
<input style={{width:"92%"}} ref={codeRef} />

</div>
<button >{t("menu.confirm")}</button></div>
{errModal2? <span className='err'>{i18n.language=="ar"?arMsg:enMsg}</span> :null}

<hr></hr>
*/}
<div className='cartBreakdown' style={{marginTop:"36px",paddingTop:"16px"}}>
<p>{t("menu.total")}</p>
<p className='bdData'>{cart.price} {i18n.language=="ar"? cart.currencyAr:cart.currencyEn}  </p>

</div>
<div className='cartBreakdown'>
<p>{t("singleOrder.tax")+" ( "+ cart.tax +"% )" }</p>
<p className='bdData'>{parseInt(cart.taxValue).toFixed(2)} {i18n.language=="ar"? cart.currencyAr:cart.currencyEn}  </p>
</div>

<div className='cartBreakdown'>
<p>{t("menu.deliveryPrice")}</p>
<p className='bdData'>{paymentMethods.deliveryPrice} {i18n.language=="ar"? cart.currencyAr:cart.currencyEn}</p>
</div>{/*
<div className='cartBreakdown'>
<p>{t("menu.disc")}</p>
<p  className='bdData'>30 sar </p>
</div>
*/}
<div style={{backgroundColor:"rgb(225, 24, 87)"}} className='cartBreakdown cartBreakdownTotal'>
<p>{t("menu.Total")}</p>

<p className='bdData'><span>{paymentMethods.totalPrice}</span> {i18n.language=="ar"? cart.currencyAr:cart.currencyEn}  </p>
</div>


{submitLoading?
               <div style={{height:"5vh",marginTop:"1em"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={20} /></div>:

<button  className='sendOrder' onClick={createOrder}>{t("menu.send")}</button>}
</div></div>
</div>:null}
        
        </div></div></>}
        

              </div></div>)


}

export default Menu