import '../styles/Login.css';
import food from "../images/login/Food.svg"
import logo from "../images/login/logo.svg"
import brown from "../images/login/background.svg"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { terms ,register} from '../api';
import { register2,userData } from '../api';
import { BeatLoader } from 'react-spinners';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import { CiSquareRemove } from 'react-icons/ci';
import * as yup from "yup"
import { IoAddCircleOutline } from "react-icons/io5";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsChevronDown } from 'react-icons/bs';

import { GrAdd } from "react-icons/gr";

import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
/*import TermsModal from '../Components/TermsModal';*/
import { Link } from 'react-router-dom';
import { IoConstructOutline } from 'react-icons/io5';
function RegisterStep2() {

    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(false);
    const [loadField,setLoadField]=useState(false);

const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);



const [arr, setArr] = useState([{name:"",mobile:"",role:""}]);
const [data,setData]=useState(
  {
    owner: '',
    mobile: '',
    id:'',
    idSource:'',
    manager: '',
    purchasing: '',
 
  }
);

const [validation,setValidation]=useState(
  {
    ownerName: {
      err:false,
      text:""
    },
    ownerPhone: {
      err:false,
      text:""
    },
    identityNo: {
      err:false,
      text:""
    },
    identitySource: {
      err:false,
      text:""
    },
    companyManagerName: {
      err:false,
      text:""
    },
    purchasingOfficierName: {
      err:false,
      text:""
    },
    delegates: {
      err:false,
      text:""
    }
  }
);

      const history=useNavigate();
      const maxD= `${new Date().getFullYear()}-${parseInt(new Date().getMonth()+1)<10?`0${new Date().getMonth()+1}`:new Date().getMonth()+1}-${parseInt(new Date().getDate()-1)<10?`0${new Date().getDate()-1}`:new Date().getDate()-1}` //to set maximum date in datePicker which is 3 days after today's date
      let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
      let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
      const Schema= yup.object().shape({
        owner: yup.string().required(("register2.required_owner")).matches(nameRegEx,{message:("validation.char")}),
        ownerMobile: yup.string().required(("register2.required_mobile")).matches(phoneRegEx,("register2.invalid_mobile")),
        id: yup.string().required(("register2.required_id")).max(12,("register2.max_id")).min(11,("register2.min_id")),
        idSource: yup.string().required(("register2.required_idSource")).max(150,("register2.max_idSource")),
     
        manager: yup.string().required(("register2.required_manager")).max(150,("register2.max_manager")).matches(nameRegEx,{message:("validation.char")}),
   
        purchasing: yup.string().required(("register2.required_purchasing")).max(150,("register2.max_purchasing")).matches(nameRegEx,{message:("validation.char")}),
    
      
     
      })
      



      useEffect(() => {
        const token = localStorage.getItem("token")
  
        userData(token,localStorage.getItem("id")).then(response => {
         
if(response.data.data.lastCompletedStep!==null && response.data.data.lastCompletedStep!=="first"){
  setData(
    {
      owner: response.data.data.ownerName,
      mobile:  response.data.data.ownerPhone,
      id:response.data.data.IdentityNo,
      idSource:response.data.data.IdentitySource,
      manager:response.data.data.companyManagerName,
      purchasing:response.data.data.purchasingOfficierName,
     }
  )
  setArr(response.data.data.delegates)

      
 }
 setLoading(false)

        }).catch(function(error){
 
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("fieldsArr")
          
            history("/")
         }
        
         
         }); 
         },[]);



      const formik = useFormik({
      initialValues:{
        owner: data.owner,
        ownerMobile:  data.mobile,
        id: data.id,
        idSource: data.idSource,
      
        manager:  data.manager,
        purchasing:  data.purchasing,
     
     
      },
      validationSchema: Schema,
      enableReinitialize: true,

      onSubmit
      });
   
      const ownerRef=useRef();
      const mobileRef=useRef();
      const idRef=useRef();
      const idSourceRef=useRef();
      const managerRef=useRef();
      const purchasingRef=useRef();
   
   

       



function addField(){
  setLoadField(true)

 let indexArr=arr; 
  indexArr.push({name:"",mobile:"",role:""});
  setArr(indexArr);
  setTimeout(function() {
    
    setLoadField(false)
  }, 100)
}


function removeIndex(index){

  setLoadField(true)


  let indexArr=arr; 
   indexArr.splice(index,1);
   
    
   setArr(indexArr);

   
   if(!(index== indexArr.length)){
   document.getElementsByName("authName")[index].value = indexArr[index].name;
  
   }
   setTimeout(function() {
     
    setLoadField(false)

   }, 100)

 
 
} 

      async function onSubmit(event){
        const token = localStorage.getItem("token")
let invalid=false
        var nameElement = document.getElementsByName("authName");
        var phoneElement = document.getElementsByName("authMobile");
        var positionElement = document.getElementsByName("position");

// Iterate through the elements and check if any have the class "invalid"
for (var i = 0; i < nameElement.length; i++) {
  var element = nameElement[i];
  var element2 = phoneElement[i];
  var element3 = positionElement[i];

  if (element.classList.contains("invalid") || element2.classList.contains("invalid") || element3.classList.contains("invalid")) {
invalid=true  
console.log("true")
}
 
}

if(invalid==false){
        setLoading(true);
        const data={
          ownerName: ownerRef.current.value,
          ownerPhone:  mobileRef.current.value,
          identityNo: parseInt( idRef.current.value),
          identitySource: idSourceRef.current.value,
          companyManagerName: managerRef.current.value,
          purchasingOfficierName:purchasingRef.current.value,
          delegates:arr,
       
        
        }
        register2(token,localStorage.getItem("id"),data,i18n.language).then((response) => {
          if( localStorage.getItem("lastStep")!=="completed"){
            localStorage.setItem("lastStep","second")}
            history("/register-3");
         

 

}).catch(function (error) {
 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }

   
   
  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)
    
                                  }
                                    
                                    )

                                }

    
                                 }




function handleChange(index,e,type){
if(type=="name"){
  if(e.target.value!==""){
    document.getElementsByName("authName")[index].classList.remove("invalid");

    document.getElementsByClassName("nameErr")[index].style.display = "none";
  }

  if(e.target.value.match(nameRegEx)!==null){
    document.getElementsByName("authName")[index].classList.remove("invalid");

    document.getElementsByClassName("nameErr2")[index].style.display = "none";
  }

  let indexArr=arr; 
   indexArr[index].name = e.target.value
  setArr(indexArr)

}

else if(type=="mobile"){
 
  if(e.target.value!==""){
    document.getElementsByName("authMobile")[index].classList.remove("invalid");

    document.getElementsByClassName("mobileErr")[index].style.display = "none";
  }
  if(e.target.value.match(phoneRegEx)!==null){
    document.getElementsByClassName("mobileErr2")[index].style.display = "none";

    document.getElementsByName("authMobile")[index].classList.remove("invalid");
  }

  let indexArr=arr; 
   indexArr[index].mobile = e.target.value
  setArr(indexArr)




}
else{
  if(e.target.value!==""){
    document.getElementsByName("position")[index].classList.remove("invalid");

    document.getElementsByClassName("positionErr")[index].style.display = "none";
  }

  if(e.target.value.match(nameRegEx)!==null){
    document.getElementsByName("position")[index].classList.remove("invalid");

    document.getElementsByClassName("positionErr2")[index].style.display = "none";
  }

  let indexArr=arr; 
   indexArr[index].role = e.target.value
  setArr(indexArr)




}
}

 function handleValidate(index,e,type){
  if(type=="name"){
 
if(e.target.value==""){
  document.getElementsByName("authName")[index].classList.add("invalid");
  document.getElementsByClassName("nameErr")[index].style.display = "block";
  document.getElementsByClassName("nameErr2")[index].style.display = "none";

}

else if(e.target.value.match(nameRegEx)==null && !e.target.value==""){
  document.getElementsByName("authName")[index].classList.add("invalid");
  document.getElementsByClassName("nameErr2")[index].style.display = "block";
}

}
else if(type=="mobile"){
 
  if(e.target.value==""){
    document.getElementsByName("authMobile")[index].classList.add("invalid");
    document.getElementsByClassName("mobileErr")[index].style.display = "block";
    document.getElementsByClassName("mobileErr2")[index].style.display = "none";

  }
else if(e.target.value.match(phoneRegEx)==null && !e.target.value==""){
  document.getElementsByName("authMobile")[index].classList.add("invalid");
  document.getElementsByClassName("mobileErr2")[index].style.display = "block";
}
}
  else{
    if(e.target.value==""){
      document.getElementsByName("position")[index].classList.add("invalid");
      document.getElementsByClassName("positionErr")[index].style.display = "block";
      document.getElementsByClassName("positionErr2")[index].style.display = "none";

    }
    else if(e.target.value.match(nameRegEx)==null && !e.target.value==""){
      document.getElementsByName("position")[index].classList.add("invalid");
      document.getElementsByClassName("positionErr2")[index].style.display = "block";
    }
  }
  
}
 
function handleChangeNavigation(value){
  if(value==1){
    history('/register-1')
  }
  else if(value==2){
    return null
  }
    else{
      if(localStorage.getItem("lastStep")!=="second"){
        return null
      }
      else{
        history('/register-3')  
      }
    }  }
   return (
        <div>
       <div className=' container'>
</div>
        <div className='loginContainer regesterStepsContainer container ' style={{paddingTop:"2em"}}>
 
<div className=' login regStepsContainer'>
{loading?
      <div style={{height:"110vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null}  className='row'>
<div className='col'>
<div className='regTitle'>
<Link to='/register-1'>
<p  >{t("register.step1")} </p></Link>
<Link to='/register-2'>
<p className='regTitleActive' style={{margin:"0 1.5em"}}>{t("register2.step2")} </p></Link>
<Link to={localStorage.getItem("lastStep")!=="second"?'/register-2':'/register-3'} >
<p >{t("register3.step3")} </p></Link>
</div>


<div className={i18n.language=="ar"?'select-container selectMobile':' selectMobile select-container select-containerEn'}>

<select defaultValue={2} onChange={(e)=>handleChangeNavigation(e.target.value)}>
  <option value={1}>{t("register.step1")}</option>

  <option  value={2}>{t("register2.step2")}</option>

  <option  value={3}>{t("register3.step3")}</option>
  
</select><div class="select-arrow">
<BsChevronDown size={16}/>

</div></div>


<div className='container-fluid userDataExt'style={{padding: "2em 1em"}} >
<form className="row" onSubmit={formik.handleSubmit}>
<div className='col-md-4 col-sm-6'>

   <div className="inputDiv">
<label>  {t("register2.owner")}</label>
<input type="text" placeholder={t("register2.owner")} value={formik.values.owner} id="owner" name="owner"  ref={ownerRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.owner && formik.touched.owner) || validation.ownerName.err==true ?"invalid":null}/>
<span>{formik.errors.owner && formik.touched.owner?t(`${formik.errors.owner}`):null}</span>
<span>{validation.ownerName.err=true? validation.ownerName.text:null}</span>

</div></div>


<div className='col-md-4 col-sm-6'>
   <div className="inputDiv">
<label>  {t("register2.mobile")}</label>
<input type="text" placeholder={t("register2.mobile")}  value={formik.values.ownerMobile} id="ownerMobile" name="ownerMobile" ref={mobileRef}  required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.ownerMobile && formik.touched.ownerMobile) || validation.ownerPhone.err==true ?"invalid":null}/>
<span>{formik.errors.ownerMobile && formik.touched.ownerMobile?t(`${formik.errors.ownerMobile}`):null}</span>
<span>{validation.ownerPhone.err=true? validation.ownerPhone.text:null}</span>

</div></div>
<div className='col-md-4 col-sm-6'>

   <div className="inputDiv">
<label>  {t("register2.id")}</label>
<input type="number" placeholder={t("register2.id")} value={formik.values.id} id="id" name="id"  ref={idRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.id && formik.touched.id) || validation.identityNo.err==true ?"invalid":null}/>
<span>{formik.errors.id && formik.touched.id?t(`${formik.errors.id}`):null}</span>
<span>{validation.identityNo.err=true? validation.identityNo.text:null}</span>

</div></div>

<div className='col-md-4 col-sm-6'>
 <div className="inputDiv">
<label>  {t("register2.idSource")}</label>
<input type="text" placeholder={t("register2.idSource")} value={formik.values.idSource} id="idSource" name="idSource"  ref={idSourceRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.idSource && formik.touched.idSource) || validation.identitySource.err==true ?"invalid":null}/>
<span>{formik.errors.idSource && formik.touched.idSource?t(`${formik.errors.idSource}`):null}</span>
<span>{validation.identitySource.err=true? validation.identitySource.text:null}</span>

</div></div>
<div className='col-md-4 col-sm-6'>

   <div className="inputDiv">
<label>  {t("register2.manager")}</label>
<input type="text" placeholder={t("register2.manager")} value={formik.values.manager} id="manager" name="manager"  ref={managerRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.manager && formik.touched.manager) || validation.companyManagerName.err==true  ?"invalid":null}/>
<span>{formik.errors.manager && formik.touched.manager?t(`${formik.errors.manager}`):null}</span>
<span>{validation.companyManagerName.err=true? validation.companyManagerName.text:null}</span>

</div></div>

<div className='col-md-4 col-sm-6'>
 
   <div className="inputDiv">
<label>  {t("register2.purchasing")}</label>
<input type="text" placeholder={t("register2.purchasing")} value={formik.values.purchasing} id="purchasing" name="purchasing"  ref={purchasingRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.purchasing && formik.touched.purchasing) || validation.purchasingOfficierName.err==true  ?"invalid":null}/>
<span>{formik.errors.purchasing && formik.touched.purchasing?t(`${formik.errors.purchasing}`):null}</span>
<span>{validation.purchasingOfficierName.err=true? validation.purchasingOfficierName.text:null}</span>

</div></div>


<div className='col-12 '>

<div className='auth regStep2Auth' >
<p  className='authTitle'>{t("register2.authorized")} </p>

<button onClick={addField} type="button"   className="addAuth"><GrAdd size={20} color="white"/> {t("register2.add")}</button>

</div></div>

 
{/*<button onClick={addField}   className="upTable-button"><GrAdd size={20} color="white"/> {t("register2.add")}</button>
* */}
{arr && arr.map((data,index)=>

<div key={index} className="col-12 authPadding" style={{marginTop:"57px"}}>
 <div className='container-fluid'>
   <div className='authTop row'>
   <div key={index} className="col-12 noPadding">
   <p  className='authTitle'>{t("register2.auth")} {index+1} </p>
<button onClick={()=>removeIndex(index)}   className="upTable-button"><RiDeleteBin5Line size={20}/> {t("register2.delete")}</button>
</div>
   </div>

    <div  className='row'>
    <div className='col-md-4 col-sm-6 noPadding'>

<div className="inputDiv">

<label> {t("register2.name")} </label>
<input type="text" placeholder={t("register2.name")} defaultValue={data.name}  name={`authName`}   required={true} onChange={(e)=>{handleChange(index,e,"name")}} onBlur={(e)=>{handleValidate(index,e,"name")}}/>
<span className='nameErr' style={{display:"none"}}>{t(`register2.required_name`)}</span>
<span className='nameErr2' style={{display:"none"}}>{t("validation.char")}</span>

</div></div>

<div className='col-md-4 col-sm-6 authNoPadding'>

<div className="inputDiv">
<label>  {t("register2.authMobile")}</label>
<input type="number" placeholder={t("register2.authMobile")} defaultValue={data.mobile}  name={`authMobile`} onChange={(e)=>{handleChange(index,e,"mobile")}} onBlur={(e)=>{handleValidate(index,e,"mobile")}} required={true} />
<span className='mobileErr' style={{display:"none"}}>{t(`register2.required_authMobile`)}</span>
<span className='mobileErr2' style={{display:"none"}}>{t(`register2.invalid_authMobile`)}</span>

</div></div>

<div className='col-md-4 col-sm-6 noPadding'>

<div className="inputDiv">
<label>  {t("register2.position")}</label>
<input type="text" placeholder={t("register2.position")} defaultValue={data.role} name={`position`}   required={true} onChange={(e)=>{handleChange(index,e,"position")}} onBlur={(e)=>{handleValidate(index,e,"position")}} />
<span className='positionErr' style={{display:"none"}}>{t(`register2.required_position`)}</span>
<span className='positionErr2' style={{display:"none"}}>{t("validation.char")}</span>

</div>
  </div></div></div></div>
  )
} 
<span>{validation.delegates.err=true? validation.delegates.text:null}</span>

{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<div className='navigateReg nextStep2' style={{textAlign:"end"}}>

<button type="submit" className="dataEdit"> {t("orders.next")}</button>

<span style={{margin:"0 1em",display:"inline-block"}}></span>
<Link to="/register-1">
<button className='cancelEdit'> {t("orders.prev")}</button></Link>
</div>
</form>

</div>

</div>

</div>
</div>
</div>

</div>
    );
}

export default RegisterStep2;