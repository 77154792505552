
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './screens/Login'
import Register from './screens/Register';
import Menu from './screens/Menu';
import MyOrders from './screens/Profile/MyOrders';
import MyWallet from './screens/Profile/MyWallet';
import ChargeWallet from './screens/Profile/ChargeWallet';
import OrderDetails from './screens/OrderDetails';
import MyProfile from './screens/Profile/MyProfile';
import Dashboard from './screens/Dashboard';
import Verify from './screens/Verify';
import ChangePass from './screens/Profile/ChangePass';
import AddOrder from './screens/AddOrder';
import ForgetPassOtp from './screens/ForgetPassOtp';
import VerifyPhone from './screens/Profile/VerifyPhone';
import MyAddresses from './screens/Profile/MyAddresses';
import ConfirmReg from './screens/ConfirmReg';
import ForgetPassword from './screens/ForgetPassword';
import OrderReceived from './screens/OrderReceived';
import PaymentSuccess from './screens/PaymentSuccess';
import RegisterStep1 from './screens/RegisterStep1';
import RegisterStep2 from './screens/RegisterStep2';
import RegisterStep3 from './screens/RegisterStep3';
import PaymentCheck from './screens/PaymentCheck';
import AdvPaymentReg from './screens/AdvPaymentReg';
import { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import NotFound from './screens/NotFound';
function App() {
const [loading,setLoading]=useState(false)
const [userName,setUserName]=useState(localStorage.getItem("name"))
  document.addEventListener("wheel", function(event){
    if(document.activeElement.type === "number"){
        document.activeElement.blur();
    }
});

  return (
    <div className="App">
       <BrowserRouter>
       <Navbar setLoading={setLoading} userName={userName}/>
       {loading?
               <div style={{height:"80vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
      <div style={loading?{ display:"none"}:null}>


            <Routes> 
   <Route exact path="/" element={<Login setUserName={setUserName}/>}/>
   <Route exact path="*" element={<NotFound/>}/>

   <Route exact path="/register" element={<Register/>}/>
   <Route exact path="/register-1" element={<RegisterStep1/>}/>
   <Route exact path="/register-2" element={<RegisterStep2/>}/>
   <Route exact path="/register-3" element={<RegisterStep3/>}/>
   <Route exact path="/adv-payment" element={<AdvPaymentReg/>}/>
   <Route exact path="/payment-check" element={<PaymentCheck  />}/>

   <Route exact path="/menu" element={<Menu/>}/>
   <Route exact path="/order-received" element={<OrderReceived/>}/>

   <Route exact path="/my-orders" element={<OrderDetails/>}/>
   <Route exact path="/profile/my-wallet" element={<MyWallet/>}/>
   <Route exact path="/charge-wallet" element={<ChargeWallet/>}/>
   <Route exact path="/order-details/:id" element={<OrderDetails/>}/>
   <Route exact path="/profile/my-profile" element={<MyProfile setUserName={setUserName}/>}/>
   <Route exact path="/profile/my-addresses" element={<MyAddresses/>}/>
   <Route exact path="/confirm" element={<ConfirmReg setUserName={setUserName}/>}/>
   <Route exact path="/payment-success" element={<PaymentSuccess/>}/>

   <Route exact path="/verify-phone" element={<VerifyPhone/>}/>

   <Route exact path="/dashboard" element={<Dashboard/>}/>
   <Route exact path="/add-order" element={<AddOrder/>}/>

   <Route exact path="/verify" element={<Verify/>}/>
   <Route exact path="/otp" element={<ForgetPassOtp/>}/>
   <Route exact path="/change-password" element={<ChangePass/>}/>
   <Route exact path="/forget-password" element={<ForgetPassword/>}/>

</Routes>      </div> </BrowserRouter>
    </div>
  );
}

export default App;
