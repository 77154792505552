 
import React, {useState,useRef,useEffect} from 'react';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import img1 from "../../../images/file1.jpeg"
import img2 from "../../../images/file2.jpeg"
import img3 from "../../../images/file3.jpeg"
import Compress from 'compress.js';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
 import {BsUpload} from "react-icons/bs";
import * as yup from "yup"
import {useFormik} from "formik";
import { register3 ,userData} from '../../../api';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ImageModal from  '../../../components/ImageModal'
import {GrDocumentPdf}  from 'react-icons/gr';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Resizer from "react-image-file-resizer";
import SuccessModal from '../../../components/SuccessModal';

import { IoImageOutline  } from 'react-icons/io5';
import { BsCloudUpload } from 'react-icons/bs';

function RegisterStep3(props) {
    const compress = new Compress()
    const [success, setSuccess]=useState(false);

    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(true);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);

const [modal,setModal]=useState(false);
const [img, setImg]=useState({
    documents:"",
    registrationTaxCertificate:"",
    commercialRegistration:"",
    chamberCommerce:"",
    zakatIncomeCertificate:"",
    bankAccountDocument:"",

}

);
const [data, setData]=useState({
    documents:"",
    registrationTaxCertificate:"",
    commercialRegistration:"",
    chamberCommerce:"",
    zakatIncomeCertificate:"",
    bankAccountDocument:"",

});

const [update, setUpdate]=useState(false);

      const history=useNavigate();
  
      const Schema= yup.object().shape({
        documents: yup.string(),
   commReg: yup.string(),
        chamber: yup.string(),
        certification: yup.string(),
        bank: yup.string(),
        certificate: yup.string(),
  
      
      })
      

 
      useEffect(() => {
        const token = localStorage.getItem("token")

        userData(token,localStorage.getItem("id")).then(response => {
         
  setData(
 response.data.data.attachments
       )

          setLoading(false)
      
 
        }).catch(function(error){
 
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("fieldsArr")
          
            history("/")
         }
        
         
         }); 
         },[update]);


      const formik = useFormik({
      initialValues:{
        documents: '',
        commReg: '',
        chamber:'',
        certification:'',
        bank:'',
        certificate:'',

     
      },
      validationSchema: Schema,
      onSubmit
      });
   
      const documentsRef=useRef();
      const commRegRef=useRef();
      const chamberRef=useRef();
      const bankRef=useRef();
      const certificateRef=useRef();
      const certificationRef=useRef();
      const formData = new FormData()
       

 

     
      async function resizeImageFn(file,name) {
        compress.compress([file], {
            size: 1, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 1500, // the max width of the output image, defaults to 1920px
            maxHeight: 1500, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
            type: file.type
          }).then((data) => {

            const blob = new Blob([data[0].data], { type: data[0].ext });

const file = new File([blob], data[0].alt, { type: data[0].ext});

console.log(img)
logFileSize(file)

return setImg((prevState) => {
    return { ...prevState, [name]: file};
  });

          }).catch(function(error){
 
            if (error.response.status === 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("phone");
              localStorage.removeItem("name");
              localStorage.removeItem("fieldsArr")
            
              history("/")
           }
          
           
           }); 
        }
      /*  async function resizeImageFn(file, name) {
            try {
              const data = await compress.compress([file], {
                size: 1,
                quality: 1,
                maxWidth: 1500,
                maxHeight: 1500,
                resize: true,
                rotate: false,
                type: file.type,
              });
          
              const blob = new Blob([data[0].data], { type: file.type });
              const compressedFile = new File([blob], data[0].alt, { type: file.type });
          
             

              console.log(logFileSize(compressedFile));
              return setImg((prevState) => {
                return { ...prevState, [name]: compressedFile };
              });
            } catch (err) {
              console.log(err);
            }
          }*/
       
          function logFileSize(file) {
            const fileSizeInBytes = file.size;
            const fileSizeInKB = fileSizeInBytes / 1024;
            const fileSizeInMB = fileSizeInKB / 1024;
          
            console.log(`File size: ${fileSizeInMB.toFixed(2)} MB`);
          }

        
       async function onSubmit(event){
        setLoading(true);

      if(documentsRef.current.files[0]) {
        console.log(documentsRef.current.files[0])

        formData.append('documents',documentsRef.current.files[0],documentsRef.current.files[0].name)

        }

          if( certificationRef.current.files[0]) {
            formData.append('registrationTaxCertificate',certificationRef.current.files[0])}

            if(commRegRef.current.files[0]) {
                formData.append('commercialRegistration',commRegRef.current.files[0])}

                if( chamberRef.current.files[0]) {
                    formData.append('chamberCommerce',chamberRef.current.files[0])}

                    if( certificateRef.current.files[0]) {
                        formData.append('zakatIncomeCertificate',certificateRef.current.files[0])}

                        if( bankRef.current.files[0]) {
                            formData.append('bankAccountDocument',bankRef.current.files[0])}
      
   register3(localStorage.getItem("token"),localStorage.getItem("id"),formData).then((response) => {
         setLoading(false)
setUpdate(!update)
props.setEdit(false)
setSuccess(true)

}).catch(function (error) {
 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }
  
   

                                 
    setMsgAr("حدث خطأ   ");
    setMsgEn("Something went wrong");
  setErrorMsgModel(true);
                                   
 setLoading(false);
                                 

      })
    }
    const imgRef = useRef(null);

    async function handleResizeImg(e,name){
        formik.handleChange(e)

        if(e.target.files[0]){
     resizeImageFn(e.target.files[0],name)}
    }
/*
 const file = await resizeImageFn(e.target.files[0],name);
 console.log('File:', file);

      const reader = new FileReader();

      reader.addEventListener('load', (event) => {
        imgRef.current.src = event.target.result;
        console.log(event.target.result)

      });
  
      reader.readAsDataURL(file); }*/

 
   

   return (
       <div className={i18n.language=="ar"?'regStep3Ar':'regStep3'}>

{success? <SuccessModal setActive={props.setActive} setEdit={props.setEdit} txt={t("profile.succss3")} setModal={setSuccess}/>:null}

             {modal?<ImageModal img={img} setModal={setModal}/>:null}
                {loading?
      <div style={{height:"110vh",margin:"0"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div className='container-fluid'>
{props.edit?null:
<div className='formTopper row'>
<p> {t("profile.myData")}</p>

</div>}
      <div className={props.edit?null: "dataContainer row"}>

   <form className={props.edit?"row":"row disabledFiles"}  style={loading?{display:"none"}:null} onSubmit={formik.handleSubmit}>
   
    <div className='col-xl-4 col-sm-6'>
    
    <div className={formik.values.commReg==""?"inputDiv":"inputDiv fileUploaded"}>

    <label>   <AiOutlineInfoCircle size={20}  color="#E47100" onClick={()=> {setImg(img2); setModal(true);}}/> {t("register3.commReg")}</label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input style={props.edit?null:{pointerEvents:"none"}} type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.commReg")} value={formik.values.commReg} id="commReg" name="commReg"  ref={commRegRef}  onChange={(e)=>handleResizeImg(e,'commercialRegistration')}     className={formik.errors.commReg && formik.touched.commReg ?"invalid":null}/>
{
props.edit?
<button  className='uploadImg'>{t("profile.upload")} <BsCloudUpload/></button>
:
<a target="_blank" href={data.commercialRegistration}>

<button style={{pointerEvents:"all"}} type='button'  className='uploadImg'>{t("register3.view")} <IoImageOutline className="imgIcn"/></button>
</a>
}
</div>
{
props.edit?
<a target="_blank" href={data.commercialRegistration}>
    {data.commercialRegistration.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.commercialRegistration} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }


</a>:null}
<span>{formik.errors.commReg && formik.touched.commReg?t(`${formik.errors.commReg}`):null}</span>
</div></div>

<div className='col-xl-4 col-sm-6'>
    
<div className={formik.values.chamber==""?"inputDiv":"inputDiv fileUploaded"}>
<label>   <AiOutlineInfoCircle size={20}  color="#E47100" onClick={()=>{setImg(); setModal(true)}}/> {t("register3.chamber")}</label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input style={props.edit?null:{pointerEvents:"none"}}  type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.chamber")} value={formik.values.chamber} id="chamber" name="chamber"  ref={chamberRef}   onChange={(e)=>handleResizeImg(e,'chamberCommerce')}     className={formik.errors.chamber && formik.touched.chamber ?"invalid":null}/>

{
props.edit?
<button  className='uploadImg'>{t("profile.upload")} <BsCloudUpload/></button>
:
<a target="_blank" href={data.chamberCommerce}>

<button style={{pointerEvents:"all"}} type='button'  className='uploadImg'>{t("register3.view")} <IoImageOutline className="imgIcn"/></button>
</a>
}

</div>
{
props.edit?
<a target="_blank" href={data.chamberCommerce}>

{data.chamberCommerce.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.chamberCommerce} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }

</a>:null}
<span>{formik.errors.chamber && formik.touched.chamber?t(`${formik.errors.chamber}`):null}</span>
</div></div>

<div className='col-xl-4 col-sm-6'>
    
<div className={formik.values.certificate==""?"inputDiv":"inputDiv fileUploaded"}>
<label>  <AiOutlineInfoCircle size={20} color="#E47100"  onClick={()=>{setImg(); setModal(true)}}/> {t("register3.certificate")} </label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input style={props.edit?null:{pointerEvents:"none"}}  type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.certificate")} value={formik.values.certificate} id="certificate" name="certificate"  ref={certificateRef}    onChange={(e)=>handleResizeImg(e,'zakatIncomeCertificate')} className={formik.errors.certificate && formik.touched.certificate ?"invalid":null}/>
{
props.edit?
<button  className='uploadImg'>{t("profile.upload")} <BsCloudUpload/></button>
:
<a target="_blank" href={data.zakatIncomeCertificate}>

<button style={{pointerEvents:"all"}} type='button'  className='uploadImg'>{t("register3.view")} <IoImageOutline className="imgIcn"/></button>
</a>
}

</div>{
props.edit?
<a target="_blank" href={data.zakatIncomeCertificate}>
    
{data.zakatIncomeCertificate.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.zakatIncomeCertificate} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }
</a>:null}
<span>{formik.errors.certificate && formik.touched.certificate?t(`${formik.errors.certificate}`):null}</span>
</div></div>

<div className='col-xl-4 col-sm-6'>
    
<div className={formik.values.bank==""?"inputDiv":"inputDiv fileUploaded"}>
    <label>   <AiOutlineInfoCircle size={20} color="#E47100" onClick={()=> {setImg(img1); setModal(true);}}/> {t("register3.bank")} </label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input style={props.edit?null:{pointerEvents:"none"}}  type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.bank")} value={formik.values.bank} id="bank" name="bank"  ref={bankRef}  onChange={(e)=>handleResizeImg(e,'bankAccountDocument')}    className={formik.errors.bank && formik.touched.bank ?"invalid":null}/>
{
props.edit?
<button  className='uploadImg'>{t("profile.upload")} <BsCloudUpload/></button>
:
<a target="_blank" href={data.bankAccountDocument}>

<button style={{pointerEvents:"all"}} type='button'  className='uploadImg'>{t("register3.view")} <IoImageOutline className="imgIcn"/></button>
</a>
}


</div>
{
props.edit?
<a target="_blank" href={data.bankAccountDocument}>
{data.bankAccountDocument.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.bankAccountDocument} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }

</a>:null}
<span>{formik.errors.bank && formik.touched.bank?t(`${formik.errors.bank}`):null}</span>
</div></div>
<div className='col-xl-4 col-sm-6'>
    
<div className={formik.values.certification==""?"inputDiv":"inputDiv fileUploaded"}>
    <label> <AiOutlineInfoCircle size={20} color="#E47100" onClick={()=> {setImg(img3); setModal(true);}}/>  {t("register3.certification")} </label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input  style={props.edit?null:{pointerEvents:"none"}}  type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.certification")} value={formik.values.certification} id="certification" name="certification"  ref={certificationRef}    onChange={(e)=>handleResizeImg(e,'registrationTaxCertificate')} className={formik.errors.certification && formik.touched.certification ?"invalid":null}/>

{
props.edit?
<button  className='uploadImg'>{t("profile.upload")} <BsCloudUpload/></button>
:
<a target="_blank" href={data.registrationTaxCertificate}>

<button style={{pointerEvents:"all"}} type='button'  className='uploadImg'>{t("register3.view")} <IoImageOutline className="imgIcn"/></button>
</a>
}

</div>
{
props.edit?
<a target="_blank"  href={data.registrationTaxCertificate}>
{data.registrationTaxCertificate.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.registrationTaxCertificate} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }

</a>:null}
<span>{formik.errors.certification && formik.touched.certification?t(`${formik.errors.certification}`):null}</span>
</div></div>

<div className='col-xl-4 col-sm-6'>
    
<div className={formik.values.documents==""?"inputDiv":"inputDiv fileUploaded"}>
<label>   <AiOutlineInfoCircle size={20} color="#E47100" onClick={()=>{setImg(); setModal(true)}}/> {t("register3.documents")}</label>
<div  className={i18n.language=="ar"?'uploadInp':'uploadInp uploadInpEn'}> 
<input style={props.edit?null:{pointerEvents:"none"}}  type="file" accept=".pdf, image/jpeg, image/png, image/gif" placeholder={t("register3.documents")} value={formik.values.documents} id="documents" name="documents"  ref={documentsRef}  onChange={(e)=>handleResizeImg(e,'documents')}   className={formik.errors.documents && formik.touched.documents ?"invalid":null}/>
{
props.edit?
<button  className='uploadImg'>{t("profile.upload")} <BsCloudUpload/></button>
:
<a target="_blank" href={data.documents}>

<button style={{pointerEvents:"all"}} type='button'  className='uploadImg'>{t("register3.view")} <IoImageOutline className="imgIcn" /></button>
</a>
}
</div>
{
props.edit?
<a target="_blank" href={data.documents}>
{data.documents.slice(-3) ==="pdf"?
    <GrDocumentPdf size={25} />:
    <img src={data.documents} style={{maxHeight:"30px",marginTop:"0.5em"}}/>
    }
</a>:null}
<span>{formik.errors.documents && formik.touched.documents?t(`${formik.errors.documents}`):null}</span>
</div></div>

  
{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
{props.edit?

<div style={{marginTop:"2em"}} className="formBtns">
<button type="submit" className="dataEdit">{t("profile.save")}</button>
<span> </span>
<button type="button" className="cancelEdit" onClick={()=> {formik.resetForm();props.setEdit(false); setErrorMsgModel(false) }}>  {t("profile.cancel")}</button></div>
:
null
}
</form>

</div></div>
</div>

    );
}

export default RegisterStep3;