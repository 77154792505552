import '../styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

function CleartCart(props) {
   
    const { t, i18n } = useTranslation();

  function handleDel(){

props.handleClear()
  }
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"fit-content",minWidth:"287px",paddingTop:"2em",position:"fixed"}}>
        

<p className='alrt'>{t("menu.delete")}</p>
<div className='modalBtns'>
<button  onClick={handleDel}  className='del'>{t("menu.confirm")}</button>
<button  onClick={()=>props.setModal(false)}  className='cls'>{t("menu.cancel")}</button>

</div>
        </div>
      </div>
  
    );
  }
  
  export default CleartCart;
  
  