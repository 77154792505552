import '../styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

import {RiShoppingCartFill } from 'react-icons/ri';
import { useState,useEffect } from 'react';
import { addToCart } from '../api';
import { BsCart2 } from "react-icons/bs";

import { IoCloseSharp } from "react-icons/io5";

import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
function AddMealModal(props) {
   
    const { t, i18n } = useTranslation();
const [size,setSize]=useState(0)

const [selectedSize,setSelectedSize]=useState( props.data.sizes[0].id)
const [quantity,setQuantity]=useState(1)
let history=useNavigate()
const [price,setPrice]=useState(props.data.sizes[0].isAdditionalRequired? props.data.sizes[0].additionals[0].price: props.data.sizes[0].price)
const [requiredAdd,setRequireAdd]=useState(props.data.sizes[0].isAdditionalRequired)
  const [loading,setLoading]=useState(false)
const [calories,setCalories]=useState(props.data.sizes[0].isAdditionalRequired? props.data.sizes[0].additionals[0].calories: props.data.sizes[0].calories)
const [availableQuan,setAvailableQuan]=useState(props.data.sizes[0].isAdditionalRequired? props.data.sizes[0].additionals[0].quantity: props.data.sizes[0].quantity)
  const[mealData,setMealData]=useState({})
  const[disableButton,setDisableButton]=useState(false)
  const[selectedAdditional,setSelectedAdditional]=useState(props.data.sizes[0].isAdditionalRequired? props.data.sizes[0].additionals[0].id:null)
  const[selectedAdditionalIndex,setSelectedAdditionalIndex]=useState(props.data.sizes[0].isAdditionalRequired? 0:null)


const[chosenExtras,setChoseExtras]=useState({
  selectedExtras: [],
  selectedExtrasNameAr: [],
  selectedExtrasNameEn: []


})

//on changing meal size
//quantity is reset to 1
//additions are unchecked
//if size requires additionals : calories , available quantity and price are reset , add button is disabled (unless an additional is chosen)
//else if size doesn't require additionals : calories , available quantity and price are set to the size's values , add button is enabled
//chosen size data is stored
function handleSizeChange(id,index, additionals,isAdditionalRequired,price, calories,arName, enName,quantity){

  setQuantity(1)
  setSelectedSize(id)
  setSize(index)

 
    var elements = document.getElementsByClassName('mealAdditional');
    for(var i=0; i<elements.length; i++) { 
     
      elements[i].checked=false;
    }
 
  if (isAdditionalRequired === true) {
    setCalories(props.data.sizes[index].additionals[0].calories)
    setRequireAdd(true)
    setAvailableQuan(props.data.sizes[index].additionals[0].quantity)
    setPrice(props.data.sizes[index].additionals[0].price)
    setSelectedAdditional( props.data.sizes[index].additionals[0].id)
    setSelectedAdditionalIndex(0)
    for(var i=0; i<elements.length; i++) { 
      if(i==0){
      elements[i].checked=true;}
    }



   /* if (additionals.length !== 0) {
      setDisableButton(true)
      setSelectedAdditional("")
   
     
    }*/
} else {
     
  setRequireAdd(false)
  setPrice(parseFloat(price).toFixed(2))
  setCalories(calories)
  setSelectedAdditionalIndex(null)

  setAvailableQuan(quantity)
  /*
  setSelectedAdditionalName({

    selectedAdditionalNameAr: "-",
        selectedAdditionalNameEn: "-",
  })*/
  setSelectedAdditional("")
   

};

setMealData({
  selectedOptionSize: id,
  selectedSize: id,
  sizeAr: arName,
  sizeEn: enName,
  selectedSizeNameAr: arName,
  selectedSizeNameEn: enName,
  mealAdditionals: [],
});

}
 

//on choosing additional
//quantity is reset to 1
//price,calories and available quantity are set
//button is enabled
//selected additonal data is stored
function handleAdditionalChange(id, price, calories, arName, enName, quantity, e,index){

  setPrice(parseFloat(price).toFixed(2))
  setQuantity(1)

  setSelectedAdditional(id)
  setSelectedAdditionalIndex(index)

  setCalories(calories)
  setAvailableQuan(quantity)



setSelectedAdditional(parseInt(id))
/*
setSelectedAdditionalName({

  selectedAdditionalNameAr: arName,
  selectedAdditionalNameEn: enName,
})
*/

}


//on change in extras (check or uncheck)
//loops on all extras
//checked extras are added to an array
//arrays of extras are stored
function handleExtras(e){
  var checkboxes = document.getElementsByName("mealExtra");
     var arNamearr = [];
     var enNamearr = [];
       
     var idNamearr = [];
       
       for (var i = 0, max = checkboxes.length; i < max; i += 1) {
        
          if (checkboxes[i].type === "checkbox" && checkboxes[i].checked) {
            arNamearr.push(JSON.parse(checkboxes[i].value).selectedExtrasNameAr);
            enNamearr.push(JSON.parse(checkboxes[i].value).selectedExtrasNameEn);
            idNamearr.push(JSON.parse(checkboxes[i].value).selectedExtras);
          }
       }
     
       setChoseExtras({selectedExtras: idNamearr,
        selectedExtrasNameAr: arNamearr,
        selectedExtrasNameEn: enNamearr})
    
     
}



//on clicking add to cart
//addToCart api call takes (data) object and token
function HandleAddToCart(){
  const token= localStorage.getItem("token");
  setDisableButton(true)

setLoading(true)
   

  let data={
 
    branchId:  parseInt(localStorage.getItem("branchId")),
    typeId: 2,
    addressId:  parseInt(localStorage.getItem("addressId")) ,
 platform:"external-sales",
    appVersion: "1.0.0", 
    productId: props.data.id,
    quantity: quantity, 
    sizeId: selectedSize,
    applicationId:6,
    additionalId: selectedAdditional,
    extras: chosenExtras.selectedExtras.sort()
  } 




addToCart(token,data).then((response) => {


props.setLoading(true)
props.setCartChange(!props.cartChange)
setDisableButton(false)
setLoading(false)

    
}).catch(function(error){
  setDisableButton(false)
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")
  
    history("/")
 }

 
 }); 



} 

function handleQuantity(e,type){ 
 if(type!=='btn'){
  if(!e.includes("٠") && !e.includes("١") && !e.includes("٢") && !e.includes("٣") && !e.includes("٤") && !e.includes("٥") && !e.includes("٦") && !e.includes("٧") && !e.includes("٨") && !e.includes("٩") ){

  let oldQuantity= quantity;
 if(e<1){
   setQuantity(oldQuantity)
 }
 else{
  setQuantity(e)
 }}}

 else{
  setQuantity(e)

 }
}

function handleUncheck(index){
console.log(selectedAdditionalIndex)
  let radio = document.getElementsByName('mealAdditional');


if(selectedAdditionalIndex== index &&  radio[index].checked){
  radio[index].checked=false

  setSelectedAdditionalIndex(null)
setPrice(props.data.sizes[size].price)
setCalories(props.data.sizes[size].calories)
  setAvailableQuan( props.data.sizes[size].quantity)
  setQuantity(1)
  setSelectedAdditional(null)
}

}

    return (
        <div className={i18n.language=="ar"?"addModal":"addModalEn"}>

        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",width:"90vw ",maxWidth:"1100px",padding:"32px",position:"fixed",top:"51%"}}>
        <button className="close-modal" onClick={()=>  {props.setModal(false)}}>
              <IoCloseSharp size={40}/>
            </button>

<div className='container-fluid'>
<div className='row'>
<div className='col-md-6' style={i18n.language=="ar"?{paddingLeft:"16px"}:{paddingRight:"16px"}}>
<p className='meal-name'>{i18n.language=="ar"?props.data.arName:props.data.enName}</p>
<p className='meal-detail'>{i18n.language=="ar"?props.data.arDescription:props.data.enDescription}</p>
 <div className='detailsBorder'>


<p  className='meal-size'>{t("menu.size")}</p>
<div className='row gy-3'>

{props.data.sizes && props.data.sizes.map((data,index) =>
  <div key={index} className={requiredAdd?'col-6 ':'col-12 flexPrice'}>
    <div className='flexOption'>
<input type="radio" name="mealSize"  id={"size"+data.id}  defaultChecked={index === 0} onChange={()=> handleSizeChange(data.id,index, data.additionals, data.isAdditionalRequired, data.price, data.calories, data.arName, data.enName, data.quantity)}/>
    <label  htmlFor={"size"+data.id} style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}>{i18n.language=="ar"?data.arName:data.enName}</label>
  </div>
  
    {!data.isAdditionalRequired && !requiredAdd?  <p>{data.price}  {i18n.language=="ar"?props.data.arCurrency:props.data.enCurrency}</p>:null}
</div>

)}

</div>
 
{ props.data.sizes[size].additionals.length>0 ?
  <>

<p style={{marginTop:"1em"}}  className='meal-size'>{t("menu.additionals")}</p>
<div className='row gy-3'>

{props.data.sizes[size].additionals && props.data.sizes[size].additionals.map((data,index) =>
  <div className='col-12 flexPrice' key={index}>
      <div className='flexOption'> 

<input type="radio" onClick={requiredAdd?null:()=>handleUncheck(index)}  defaultChecked={requiredAdd? index===0:false} name="mealAdditional" className="mealAdditional" id={"additional"+data.id}  onChange={(e) =>handleAdditionalChange(data.id, data.price, data.calories, data.arName, data.enName, data.quantity, e,index)}/>
  
    <label  htmlFor={"additional"+data.id} style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}>{i18n.language=="ar"?data.arName:data.enName}</label>
</div>
<p>{data.price}  {i18n.language=="ar"?props.data.arCurrency:props.data.enCurrency}</p>
</div>

)}

</div>
</>:null
}

{props.data.extras.length>0?
  <>

<p style={{marginTop:"1em "}}  className='meal-size'>{t("menu.extras")}</p>
<div className='row'>

{props.data.extras && props.data.extras.map((data,index) =>
  <div className='col-6 flexOption' key={index}>
<input value={JSON.stringify({selectedExtras: data.id,
        selectedExtrasNameAr: data.arName,
        selectedExtrasNameEn: data.enName})} type="checkbox" name="mealExtra" id={"extra"+data.id} onChange={(e)=>handleExtras(e)} />
    <label  htmlFor={"extra"+data.id} style={i18n.language=="ar"?{marginRight:"0.5em"}:{marginLeft:"0.5em"}}>{i18n.language=="ar"?data.arName:data.enName}</label>

</div>

)}

</div>
</>:null
}





</div>

</div>
<div className='col-md-6 order-md-last order-first' style={i18n.language=="ar"?{textAlign:"start",paddingRight:"16px"}:{paddingLeft:"16px"}}>

<div className='meal-img' style={{ backgroundImage: `url(${props.data.image})` }}>
</div>
<p className='cal'>{t("menu.totalCalories")} : </p>
<p className='calTotal'> {calories} {t("menu.calorie")}</p>

<p style={{marginTop:"1em"}}  className='meal-size'>{t("menu.chooseQuan")}</p>

<div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>


<div className=' quanBtns'>


  
{/*
<input value={quantity} onChange={(e)=>handleQuantity(e.target.value)}/>*/}
<button className="quantity-btn2"onClick={()=>quantity>1?handleQuantity(quantity-1,'btn'):null}>-</button>

<p>{quantity}</p>

<button className="quantity-btn1" onClick={()=>handleQuantity(quantity+1,'btn')}>+</button>



</div>

<p className='total'>{price*quantity} {i18n.language=="ar"?props.data.arCurrency:props.data.enCurrency}</p>




</div>
<div style={{marginTop:"1em"}} className='row mealBottom'>
<div className='col-12' style={{textAlign:"start"}}>
{loading?
      <div className='loader profileLoader' style={{height:"0"}}>
      <BeatLoader color={'#E47100'} loading={loading}  size={20} /></div>:
<button className='addMeal' disabled={disableButton} onClick={HandleAddToCart}><BsCart2 style={i18n.language=="ar"?{marginLeft:"12px"}:{marginRight:"12px"}} size={26}/> {t("menu.add")} </button>
}
</div>

</div>
</div></div>

</div>


</div>
        
      </div>
  
    );
  }
  
  export default AddMealModal;
  
  