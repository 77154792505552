 
import React, {useState,useEffect,useRef} from 'react';

import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { RiArrowRightSFill,RiArrowLeftSFill } from 'react-icons/ri';
import { GoogleMap,useLoadScript,Marker} from "@react-google-maps/api"
import { companyActivities,activityTypes,register1 ,userData} from '../../../api';
import { areas as allAreas} from '../../../api';
import SuccessModal from '../../../components/SuccessModal';
import { BsChevronDown } from 'react-icons/bs';

import axios from 'axios';
import * as yup from "yup"
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";

/*import TermsModal from '../Components/TermsModal';*/
import { Link } from 'react-router-dom';
function RegisterStep1(props) {
  const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw"
  }) 
  const markerRef=useRef()
  const [update,setUpdate]=useState(false);
  const [success, setSuccess]=useState(false);

  const [activities,setActivities]=useState([]);
  const [type,setType]=useState([]);
  const [areasArr,setAreasArr]=useState([]);
  const [data,setData]=useState(
    {
      company: '',
      activity: '',
      type:'',
      areas:'',
      landmark:'',
      landline: '',
      email: '',
      commercial: '',
      source: '',
      date:'',
      commerce:'',
      branches:'',
      establishment: '',
      address: '',
    }
  );

 
  const [validation,setValidation]=useState(
    {
      facilityName:"",
      activityId:"",
       facilityTypeId:"",
      areaId:"",
      landmark:"",
      landline: "",
      email: "",
      commercialRegistrationNo:"",
      source:"",
      date:"",  
      branchNo:"",
      chamberCommerceNo:"",
     establishmentDate: "",
      address:""
    }
  );
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(true);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [msgArp, setMsgArp]=useState();
const [msgEnp, setMsgEnp]=useState();
const [errorMsgModelp, setErrorMsgModelp]=useState(false);

const [address,setAddress]=useState("")
const [resp,setResp]=useState()
const [position,setPostion]=useState(

  {
lat:23.8,
lng:45.0


  }
)  

      const history=useNavigate();
      let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;
      let numRegEx= /^[0-9]+$/;

      const maxD= `${new Date().getFullYear()}-${parseInt(new Date().getMonth()+1)<10?`0${new Date().getMonth()+1}`:new Date().getMonth()+1}-${parseInt(new Date().getDate()-1)<10?`0${new Date().getDate()-1}`:new Date().getDate()-1}` //to set maximum date in datePicker which is 3 days after today's date
      let emailRegx =/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      let commercialRegx =/^[0-9]+$/;

      const Schema= yup.object().shape({
      company: yup.string().required(("Login.required_company")).min(3,("register.min_company")).max(150,("register.max_company")).matches(nameRegEx,{message:("validation.name_not_valid")}),
      activity: yup.string().required(("Login.required_companyActiv")),
      type: yup.string().required(("register.required_companyType")),
      areas: yup.string().required(("register.required_areas")),
      landmark: yup.string().required(("register.required_landmark")),
      landline: yup.string().required(("register.required_landline")).max(10,("register.max_landline")),
      email: yup.string().required(("register.required_email")).max(50,("register.max_email")).matches(emailRegx,{message:("register.invalid_email")}),
      commercial: yup.string().required(("register.required_commercial")).max(10,("register.max_commercial")).matches(commercialRegx,{message:("register.invalid_number")}),
      source: yup.string().required(("register.required_source")).matches(nameRegEx,{message:("validation.char")}),
      date: yup.string().required(("register.required_date")),
      commerce: yup.string().required(("register.required_commerce")).max(10,("register.max_commerce")),
      branches: yup.string().required(("register.required_branches")).max(10,("register.max_branches")).matches(commercialRegx,{message:("validation.numbers")}),
      establishment: yup.string().required(("register.required_establishment")),
      address: yup.string().required(("register.required_address")).max(500,("register.max_address")),

      }) 
      


      useEffect(() => {
        const token = localStorage.getItem("token")

            userData(token,localStorage.getItem("id")).then(response => {
            
    

            allAreas(response.data.data.city.id,token).then(response => {
              setAreasArr(response.data.data.data)
                    
                   }) 
                   setResp(response.data.data)
  setData( {
                  company: response.data.data.facilityName,
                  activity: response.data.data.facilityActivity.id,
                  type:response.data.data.facilityActivity.FacilityActivityType.id,
                  areas:response.data.data.area.id,
                  landmark:response.data.data.landmark,
                  landline: response.data.data.landline,
                  email: response.data.data.user.email,
                  commercial: parseInt(response.data.data.commercialRegistrationNo),
                  source: response.data.data.commercialRegistrationSource,
                  date:response.data.data.commercialRegistrationDate,
                  commerce:parseInt(response.data.data.chamberCommerceNo),
                  branches:response.data.data.branchNo,
                  establishment: response.data.data.establishmentDate,
                }
              )
              setPostion(
                {
              lat:parseFloat(response.data.data.latitude),
              lng:parseFloat(response.data.data.longitude)
              
              
                })
            setAddress(response.data.data.address)
            
            
              setLoading(false)
    
          
     })

            companyActivities().then(response => {

              setActivities(response.data)
                   
                   }).catch(function(error){
 
                    if (error.response.status === 401) {
                      localStorage.removeItem("token");
                      localStorage.removeItem("phone");
                      localStorage.removeItem("name");
                      localStorage.removeItem("fieldsArr")
                    
                      history("/")
                   }
                  
                   
                   }); 
                   activityTypes().then(response => {
    
    
    setType(response.data)
    setLoading(false)
      
      })

         },[update]);


      const formik = useFormik({
        initialValues:{
          company: data.company,
          activity: data.activity,
          type:data.type,
          areas:data.areas,
          landmark:data.landmark,
          landline: data.landline,
          email: data.email,
          commercial: data.commercial,
          source: data.source,
          date:data.date,
          commerce:data.commerce,
          branches:data.branches,
          establishment: data.establishment,
          address: '',
        },
        validationSchema: Schema,
        enableReinitialize: true,
      onSubmit
      });
   
      const companyRef=useRef();
      const activityRef=useRef();
      const typeRef=useRef();
      const areasRef=useRef();
      const landmarkRef=useRef();
      const landlineRef=useRef();
      const emailRef=useRef();
      const commercialRef=useRef();
      const sourceRef=useRef();
      const dateRef=useRef();
      const commerceRef=useRef();
      const branchesRef=useRef();
      const establishmentRef=useRef();
      const addressRef=useRef();

//On submitting for registration (takes:name,phone,countryId,password,password confirmation and agreement to terms (bool))
//user is given a token that is stored to be used in /verify where the user is redirected

async function onSubmit(e){
  e.preventDefault();
  
  const token = localStorage.getItem("token")
if(address==""){
setMsgAr(t("register.required_address"));
                                  setMsgEn(t("register.required_address"));
                                  setErrorMsgModel(true);
                                
}
else{

    setLoading(true);

    const data={
      facilityName: companyRef.current.value,
      activityId:  parseInt(activityRef.current.value),
      facilityTypeId: parseInt(typeRef.current.value),
      areaId: parseInt(areasRef.current.value),
      landmark: landmarkRef.current.value,
      latitude:position.lat,
      longitude:position.lng,
      commercialRegistrationNo: parseInt(commercialRef.current.value),
      landline: landlineRef.current.value,
      source: sourceRef.current.value,
      email: emailRef.current.value,
      date: dateRef.current.value,
      address: address,
      chamberCommerceNo: parseInt(commerceRef.current.value),
      establishmentDate: formik.values.establishment,
      branchNo: parseInt(branchesRef.current.value)
    }
    register1(token,localStorage.getItem("id"),data,i18n.language).then((response) => {
      props.setEdit(false); 
     setLoading(false)
     setSuccess(true)

     resetValidation();

}).catch(function (error) {
 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }
  
   
 
  let errorArr=error.response.data.errors;

  const updatedValidation = { ...validation };
  for (const key in updatedValidation) {
    if (key in errorArr) {
      updatedValidation[key] = errorArr[key][0];
    } else {
      updatedValidation[key] = "";
    }
  }
  console.log(updatedValidation)
  setValidation(updatedValidation);
 setLoading(false)
}
                                
                                )

                            }

  } 
                                function onMarkerDragEnd(evt){
                                  var newLat = evt.latLng.lat();
                                  var newLng = evt.latLng.lng();
                                handleLocationChange(newLat,newLng)
                                 setPostion({
                                
                                  lat:newLat,
                                  lng:newLng
                                 })
                                
                                };


                                function handleLocationChange(lat,lng) {
                              
                              
                                axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat+ ',' +lng+ '&language=en&region=SA&key=AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw')
                                .then((response) => {
                                 
                                  setAddress( response.data.results[0].formatted_address)
                                  if(i18n.language=="en"){
                                 
      formik.setValues({
        ...formik.values,
        landmark: response.data.results[0].formatted_address,
      });
                                  
                                  }
                                   else{
                                    axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat+ ',' +lng+ '&language=ar&region=SA&key=AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw')
                                    .then((response) => {
                                  
      formik.setValues({
        ...formik.values,
        landmark: response.data.results[0].formatted_address,
      });
                                              
                                     
                                    })
                                   }
                                 
                                })
                                
                                
                               
                                
                                    }

 
                                    useEffect(() => {
  
                                   formik.setValues({
                                        company: data.company,
                                        activity: data.activity,
                                        type: data.type,
                                        areas: data.areas,
                                        landmark: data.landmark,
                                        landline: data.landline,
                                        email: data.email,
                                        commercial: data.commercial,
                                        source: data.source,
                                        date: data.date,
                                        commerce: data.commerce,
                                        branches: data.branches,
                                        establishment: data.establishment,
                                        address: '',
                                      });
                                  
                                    
                                    }, [data]);
                                    
                                    

                                    
  function resetValidation(){
 setValidation(
    {
      facilityName:"",
      activityId:"",
       facilityTypeId:"",
      areaId:"",
      landmark:"",
      landline: "",
      email: "",
      commercialRegistrationNo:"",
      source:"",
      date:"",
      chamberCommerceNo:"",
     establishmentDate: "",
      address:""
    }
  );
 }
   return (
    <div className={i18n.language=="ar"?"profileReg1Ar container-fluid":"container-fluid profileReg1En"}>
     {loading?
      <div style={{height:"110vh",margin:"0"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
      {success? <SuccessModal setActive={props.setActive} setEdit={props.setEdit} txt={t("profile.succss2")} setModal={setSuccess}/>:null}

      {props.edit?null:
<div className='formTopper row'>
<p> {t("profile.myData")}</p>

</div>}
<div className={props.edit?null: "dataContainer row"}>

      <form className={props.edit?"row gy-3 ":"row gy-3 disabledForm "} style={loading?{display:"none"}:null} onSubmit={formik.handleSubmit}>
   
   <div className='col-xl-4 col-sm-6'>

      <div className="inputDiv">
      <label> {t("register.company")} :</label>

      <input type="text" placeholder={t("Login.company")} value={formik.values.company} id="company" name="company"  ref={companyRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur} className={(formik.errors.company && formik.touched.company) || !validation.facilityName==""?"invalid":null}/>
   <span>{formik.errors.company && formik.touched.company && (validation.facilityName=="")?t(`${formik.errors.company}`):null}</span>
   <span>{!validation.facilityName==""? validation.facilityName:null}</span>

   </div>
   </div>
 
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.companyActiv")} :</label>
   <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

   <select value={formik.values.activity} id="activity" name="activity"  ref={activityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.activity && formik.touched.activity) || !validation.activityId==""?"invalid chosenSelect":"chosenSelect"}>
   <option selected disabled value="">{t("Login.companyActivChoose")}</option>
   {activities && activities.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.name_ar:data.name_en} </option>

   
  )
}
   
   </select>
   <div class="select-arrow">
<BsChevronDown size={20}/>

</div></div>
   <span>{formik.errors.activity && formik.touched.activity && (validation.activityId=="")?t(`${formik.errors.activity}`):null}</span>
   <span>{!validation.activityId==""? validation.activityId:null}</span>

   </div></div>
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.companyType")} :</label>
   <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

   <select value={formik.values.type} id="type" name="type"  ref={typeRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.type && formik.touched.type)|| !validation.facilityTypeId==""?"invalid chosenSelect":"chosenSelect"}>
   <option selected disabled value="">{t("register.companyTypeSelect")}</option>
   {type && type.map((data,index)=>


<option key={index}  value={data.id}  >{data.name} </option>

   
  )
}
   </select>
   <div class="select-arrow">
<BsChevronDown size={20}/>

</div></div>
   <span>{formik.errors.type && formik.touched.type && (validation.facilityTypeId=="")?t(`${formik.errors.type}`):null}</span>
   <span>{!validation.facilityTypeId==""? validation.facilityTypeId:null}</span>

   </div></div>
  
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.areas")} :</label>
   <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

   <select value={formik.values.areas} id="areas" name="areas"  ref={areasRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.areas && formik.touched.areas)|| !validation.areaId==""?"invalid chosenSelect":"chosenSelect"}>
   <option selected disabled value="">{t("register.areasSelect")}</option>
  

   {areasArr && areasArr.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} 
</option>

  
  )
}
   </select>
   <div class="select-arrow">
<BsChevronDown size={20}/>

</div></div>
   <span>{formik.errors.areas && formik.touched.areas && (validation.areaId=="")?t(`${formik.errors.areas}`):null}</span>
   <span>{!validation.areaId==""? validation.areaId:null}</span>

   </div></div>
   <div className='col-xl-4 col-sm-6'>
   <div className="inputDiv">
   <label> {t("register.landline")} :</label>
   <input type="text" placeholder={t("register.landline")} value={formik.values.landline} id="landline" name="landline"  ref={landlineRef} required={true}  
   onChange={(event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    formik.setFieldValue('landline', numericValue);
  }} onBlur={formik.handleBlur}  className={(formik.errors.landline && formik.touched.landline) || !validation.landline=="" ?"invalid":null}/>
   <span>{formik.errors.landline && formik.touched.landline  && (validation.landline=="")?t(`${formik.errors.landline}`):null}</span>
   <span>{!validation.landline==""? validation.landline:null}</span>

   </div>
 
   
   </div>
 
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.email")} :</label>
   <input type="text" placeholder={t("register.email")} value={formik.values.email} id="email" name="email"  ref={emailRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.email && formik.touched.email)|| !validation.email=="" ?"invalid":null}/>
   <span>{formik.errors.email && formik.touched.email && (validation.email=="")?t(`${formik.errors.email}`):null}</span>
   <span>{!validation.email==""? validation.email:null}</span>

   </div></div>
  
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.commercial")} :</label>
   <input type="text" placeholder={t("register.commercial")} value={formik.values.commercial} id="commercial" name="commercial"  ref={commercialRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.commercial && formik.touched.commercial) || !validation.commercialRegistrationNo==""  ?"invalid":null}/>
   <span>{formik.errors.commercial && formik.touched.commercial && (validation.commercialRegistrationNo=="")?t(`${formik.errors.commercial}`):null}</span>
   <span>{!validation.commercialRegistrationNo==""? validation.commercialRegistrationNo:null}</span>

   </div></div>

   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.source")} :</label>
   <input  placeholder={t("register.source")} value={formik.values.source} id="source" name="source"  ref={sourceRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.source && formik.touched.source)|| !validation.source==""?"invalid":null}/>
   <span>{formik.errors.source && formik.touched.source && (validation.source=="")?t(`${formik.errors.source}`):null}</span>
   <span>{!validation.source==""? validation.source:null}</span>

   </div></div>
   
  
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.date")} :</label>
   <input max={new Date().toISOString().split("T")[0]}  type="date" placeholder={t("register.date")} value={formik.values.date} id="date" name="date"  ref={dateRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.date && formik.touched.date) || !validation.date=="" ?"invalid":null}/>
   <span>{formik.errors.date && formik.touched.date && (validation.date=="")?t(`${formik.errors.date}`):null}</span>
   <span>{!validation.date==""? validation.date:null}</span>

   </div>
   </div>
 
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.commerce")} :</label>
   <input type="number" placeholder={t("register.commerce")} value={formik.values.commerce} id="commerce" name="commerce"  ref={commerceRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.commerce && formik.touched.commerce) || !validation.chamberCommerceNo==""?"invalid":null}/>
   <span>{formik.errors.commerce && formik.touched.commerce && (validation.chamberCommerceNo=="")?t(`${formik.errors.commerce}`):null}</span>
   <span>{!validation.chamberCommerceNo==""? validation.chamberCommerceNo:null}</span>

   </div></div>

   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.branches")} :</label>
   <input type="number" placeholder={t("register.branches")} value={formik.values.branches} id="branches" name="branches"  ref={branchesRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.branches && formik.touched.branches)|| !validation.branchNo=="" ?"invalid":null}/>
   <span>{formik.errors.branches && formik.touched.branches && (validation.branchNo=="")?t(`${formik.errors.branches}`):null}</span>
   <span>{!validation.branchNo==""? validation.branchNo:null}</span>

   </div></div>
 
   <div className='col-xl-4 col-sm-6'>
   
   <div className="inputDiv">
   <label> {t("register.establishment")} :</label>
   <input type="date" max={maxD} placeholder={t("register.establishment")} value={formik.values.establishment} id="establishment" name="establishment"  ref={establishmentRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.establishment && formik.touched.establishment) || !validation.establishmentDate==""  ?"invalid":null}/>
   <span>{formik.errors.establishment && formik.touched.establishment && (validation.establishmentDate=="")?t(`${formik.errors.establishment}`):null}</span>
   <span>{!validation.establishmentDate==""? validation.establishmentDate:null}</span>

   </div></div>

   <div className='col-12'>
   
   <div className="inputDiv" style={{flexWrap:"wrap"}}>
   <label> {t("register.landmark")} :</label>
   <input style={{flexGrow:"1"}} disabled type="text" placeholder={t("register.chooseLand")} value={formik.values.landmark} id="landmark" name="landmark"  ref={landmarkRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.landmark && formik.touched.landmark) || !validation.address=="" || !validation.landmark=="" ?"invalid":null}/>
   <span>{formik.errors.landmark && formik.touched.landmark && (validation.address=="" && validation.landmark=="")?t(`${formik.errors.landmark}`):null}</span>
   <span>{!validation.landmark==""? validation.landmark:null}</span>
<span>{!validation.address==""? validation.address:null}</span>

   </div>
   </div>

 
   <div className="inputDiv" style={{width:"95%"}}>
   </div>
   {isLoaded?
   <div style={i18n.language=="ar"?{margin:"auto",marginTop:"1em",paddingLeft:"0"}:{margin:"auto",marginTop:"1em",paddingRight:"0"}}>
   
           <GoogleMap zoom={10} center={{lat:position.lat,lng:position.lng}} mapContainerClassName="map"
          
           options={{
               mapTypeControl: false
           }}
           >
   <Marker   
   draggable={props.edit?true:false}
   ref={markerRef}
   position={{lat:position.lat,lng:position.lng}} 
   
   onDragEnd={(e) =>onMarkerDragEnd(e)}
   
   />
   
           </GoogleMap>
           </div>:null}
   
   <div> 
   </div>
   {i18n.language=="ar"?
<span style={{marginTop:"2em"}} >{errorMsgModel? msgAr:null}</span>:
<span style={{marginTop:"2em"}} >{errorMsgModel? msgEn:null}</span>}


{props.edit?

   <div style={{marginTop:"1em"}} className="formBtns">
  
   <button type="button" onClick={(e)=>onSubmit(e)}  style={{marginTop:"2em",maxWidth:"300px"}} className="dataEdit"> {t("profile.save")}</button>
   <span> </span>
   <button type="button" className="cancelEdit" onClick={()=>{resetValidation(); setUpdate(!update);props.setEdit(false);}}>  {t("profile.cancel")}</button></div>:
 
null  
  }




   </form></div></div>
    );
}

export default RegisterStep1;