import '../styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

function ErrorModal(props) {
   
    const { t, i18n } = useTranslation();

  
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} style={{background:"transparent"}} className="modalOverlay"></div>
        <div  className="modal-content errModal" style={{height:"unset",maxWidth:"90vw"}} >
        
<p className='alrt'>{props.msg}</p>
 
        </div>
      </div>
  
    );
  }
  
  export default ErrorModal;
  
  