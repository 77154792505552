import React, {useEffect, useState} from 'react';

import success from "../images/success-web.svg";
import failed from"../images/faild-web.svg";

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'; 
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import { payAgain_checkout } from '../api';
import { checkPayment } from '../api';
function PaymentCheck(){
 const  [loading,setLoading]=useState(true);
 const { t, i18n } = useTranslation();
 const [cardValid,setCardValid]=useState(false)
 const [msgAr, setMsgAr]=useState();
 const [msgEn, setMsgEn]=useState();
 const [errorMsgModel, setErrorMsgModel]=useState(false);
 const [warning, setWaringing]=useState(false);
 const [arErr, setArrErr]=useState("");
 const [enErr, setEnrErr]=useState("");

  const [state,setState]=useState(false);
  const paymentType= localStorage.getItem("checkPayment")
  
   const [paymentLoad,setPaymentLoad]=useState(false);
   const search = window.location.search;
   const params = new URLSearchParams(search);
   let sessionId = params.get('cko-session-id');
   const token= localStorage.getItem('token');

   let history = useNavigate();
 

    useEffect(() => {
      const token= localStorage.getItem('token');

      window.scrollTo(0, 0);
    
      checkPayment(token,sessionId,localStorage.getItem("transactionType")).then((response) => {
if(response.data.data.approved==true){
  setState(true)  
}
else{
  setState(false)  
  if(response.data.data.message=="warning"){
    setWaringing(true)
    setArrErr("لقد تمت عملية الدفع بالفعل")
    setEnrErr(response.data.data.error)

   }
}
             
                setLoading(false);
               
             }).catch(function (error) {
              setState(false)  
              setLoading(false);
      
             
            });
         
      
      } ,[ ]);


      function handleProceed () {
        var id=localStorage.getItem("orderId");
        history(generatePath("/order-details/:id", { id }));
        
      };   
      function payAgain (paymentToken) {
        
        payAgain_checkout(token,paymentToken,localStorage.getItem("orderId")).then((response) => { 
        
        
          if(response.data.url !== "" && response.data.url !== null){
            window.location.href = response.data.url
          }
          else if(response.data.message=="warning" && response.data.data=="This order has been paid." ){
            setErrorMsgModel(true); 
            setMsgAr(  "لقد تم دفع هذا الطلب بالفعل");
            setMsgEn("This order has been paid.");
setPaymentLoad(false) 
Frames.init()
           }
       else if(response.data.message=="warning"){
            setErrorMsgModel(true); 
            setMsgAr(  " حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
            setMsgEn("Transaction has been failed,please try again");
setPaymentLoad(false) 
Frames.init()
        }
        else{
          setState(true)  }
         
        }).catch(function (err) {
          setErrorMsgModel(true); 
          setMsgAr(  " حدث فشل في عملية الدفع ، برجاء المحاولة مرة اخري");
          setMsgEn("Transaction has been failed,please try again");
setPaymentLoad(false)
Frames.init()

      })
      }
        return (
          <div>{loading?<div className='loader'>
          <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
          <>

<div style={{marginTop:"5em",marginBottom:"5em"}} className='container orderSuccess'>
  
{state? <div className='shareForm '>
   <img style={{marginBottom:"1em"}} src={success}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.sucess")}
  </p>
  {
    paymentType=="order"?
    <button style={{display:"inline-block"}} onClick={handleProceed}> {t("payment.track_order")}</button>
:
<Link to="/profile/my-wallet">
<button style={{display:"inline-block"}}> {t("payment.backWallet")}</button>

</Link>


  }
   
   </div>:

<>
{
  warning?
  <div className='shareForm '>
  <img style={{marginBottom:"1em"}}  src={failed}/>

 <p style={{color:"rgb(99, 50, 53)"}}>
 {i18n.language=="ar"? arErr:enErr}
 </p>

 {
    paymentType=="order"?
    <button style={{display:"inline-block"}} onClick={handleProceed}> {t("payment.track_order")}</button>
:
<Link to="/profile/my-wallet">
<button style={{display:"inline-block"}}> {t("payment.backWallet")}</button>

</Link>


  }

  </div>:
<div className='shareForm '>
   <img style={{marginBottom:"1em"}}  src={failed}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.notSuccess_payment")}
  </p>
  {
    paymentType=="order"?

<div className="creditCard" style={{margin:"auto",marginTop:"2em",maxWidth:"600px"}}>
              <Frames

cardValidationChanged= {(e) => {


    setCardValid(e.isValid)
   
}}
                config={{ 
                    publicKey: `${process.env.REACT_APP_PAYMENT_KEY}`,
                    schemeChoice: true,
                }}
                cardTokenized={(e) => {
                  payAgain(e.token)
                }}
            >
                <CardNumber />
                <div className="date-and-code">
                    <ExpiryDate />
                    <Cvv />
                </div>

                {/* Or if you want to use single frames: */}
                {/* <CardFrame /> */}

                { paymentLoad?  <div style={{height:"6vh",marginTop:"2em",alignItems:"center",flexDirection:"column"}} className='loader'>
    <BeatLoader color={'#E47100'} loading={paymentLoad}  size={30} />
    <p className="paymentLoad">{t("payment.processing")}</p>
    </div>:  
    <button disabled={!(cardValid)}
                    id="pay-button"
                    
                    onClick={() => {
                      setPaymentLoad(true)
                        Frames.submitCard();
                     
                    }}
                >
             {t("payment.pay_again")}
                </button>}
            </Frames>
            {i18n.language=="ar"?
<span className="errMsg" style={{fontSize:"16px"}}>{errorMsgModel? msgAr:null}</span>:
<span  className="errMsg" style={{fontSize:"16px"}}>{errorMsgModel? msgEn:null}</span>}
            </div>:   
            
            <Link to="/profile/my-wallet">
            <button style={{display:"inline-block"}}> {t("payment.backWallet")}</button>
            
            </Link>  
            
            }





   </div>}
   </>
    
   }
    </div>
                 

     </>   
                }
         
                </div>
           
        )
    }


    export default PaymentCheck;
