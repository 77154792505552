  

import React, { useState} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import gift from "../images/present.svg"
import party from "../images/Party.svg"

function DiscountModal(props) {

const { t, i18n } = useTranslation();
const [msgEn, setMsgEn]=useState();
const [msgAr, setMsgAr]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
  
const price= {
  total: localStorage.getItem("totalPrice"),
  discount: localStorage.getItem("discountPrice")
}

const currency= {ar: localStorage.getItem("currencyAr"),
en: localStorage.getItem("currencyEn")}


    return (
 
 
        <div  className="Modal">
        
          <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
          <div  className="modal-content" style={{height:"auto",padding:"0",width:"50vw ", maxWidth:"400px",minWidth:"337px"}}>
          
            <div >
              
             <div style={{textAlign:"center",flexDirection:"column"}} className='modalBody'>
             <div className=''>
          <img className="freeImg" src={gift} style={{height:"185px"}} />
    
        
    <div style={{padding:"0 0.5em"}}>
     <p className="gift-p1">{t("process.gift1")}</p> <span></span> 
     <img src={party} style={{height:"30px",width:"auto"}}/>
    <p className='gift-p2' style={{marginBottom:"1em"}}>    {localStorage.getItem('offerType')=="discount"? t("process.offer") :localStorage.getItem('offerType')=="freeMeals"? t("process.gift2"): t("process.cashBack")}</p>
 
    {localStorage.getItem('offerType')=="discount"?
    
    <div className="" >
    <div className='offerdetail' style={{marginBottom:"0"}}>
         
        <p className='offerAfter' >{price.discount}  {i18n.language=="ar"?currency.ar:currency.en}</p>
       
        <p className='offer-middle' >{t("process.instead")}</p>
        <p className='offerBefore'>{price.total}  {i18n.language=="ar"?currency.ar:currency.en}</p>
      
        </div> 
       </div>
:

localStorage.getItem('offerType')=="freeMeals"?
<ul style={{textAlign:"start"}}>

{props.freeMeals && props.freeMeals.map((data, index)=>

<li  className='alrt'>{t("menu.free")} &nbsp;
{i18n.language=="ar"? data.arName:data.enName}</li>

)}


</ul>

:null

}

    </div>
    
     
   
          </div>
           <div className='modalBtns'>
{
  errorMsgModel?
<span style={{display:"block"}} className='err'>{i18n.language=="en"? msgEn:msgAr}</span>:null}
           <button style={{marginTop:"1em"}} className='confirm ' onClick={()=>{props.createOrder(); props.setModal(false);}}  >     {t("process.confirm")}</button>

        </div>
            </div>
          
     
            </div>
        
          </div>
        </div>
    

    );
}

export default DiscountModal;