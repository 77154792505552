import '../styles/Login.css';
import food from "../images/login/Food.svg"
import logo from "../images/login/logo.svg"
import brown from "../images/login/background.svg"
import decoration from "../images/decoration.svg"

import chicken2 from "../images/chicken2.svg"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { companyActivities ,register} from '../api';
import { BeatLoader } from 'react-spinners';
 import { countries } from '../api';
import * as yup from "yup"
import { BsChevronDown } from 'react-icons/bs';

import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
/*import TermsModal from '../Components/TermsModal';*/
import { Link } from 'react-router-dom';
function Register() {
    const [hidden,setHidden]=useState(false);
    const [hidden2,setHidden2]=useState(false);
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(true);
    const [activities,setActivities]=useState([]);
const [msgAr, setMsgAr]=useState();
const [msgEn, setMsgEn]=useState();
const [errorMsgModel, setErrorMsgModel]=useState(false);
const [msgArp, setMsgArp]=useState();
const [msgEnp, setMsgEnp]=useState();
const [errorMsgModelp, setErrorMsgModelp]=useState(false);
const [validation,setValidation]=useState(
  {
    name: {
      err:false,
      text:""
    },
    phone: {
      err:false,
      text:""
    }, facilityName: {
      err:false,
      text:""
    }, activityId: {
      err:false,
      text:""
    },
    cityId: {
      err:false,
      text:""
    }
  
  })

const [city,setCity]=useState([]);

      const history=useNavigate();

      let phoneRegEx =/^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
      let nameRegEx= /^[\u0621-\u064Aa-zA-Z\-_\s]+$/;

      const Schema= yup.object().shape({
      phone: yup.string().required(("validation.phone_required")).matches(phoneRegEx,("validation.phone_not_valid")),
      password: yup.string().min(6, ("validation.at_least")).required(("validation.password_required")),
      name: yup.string().required(("validation.name_required")).matches(nameRegEx,{message:("validation.name_not_valid")}).min(3, ("validation.name_atleast")),
     
     
      company: yup.string().required(("Login.required_company")).matches(nameRegEx,{message:("validation.name_not_valid")}),

      activity: yup.string().required(("Login.required_companyActiv")),
      city: yup.string().required(("Login.required_city")),


      confirmPassword: yup.string().oneOf([yup.ref('password'), null], ("validation.password_must_match")).required(("validation.confirm_required")),
   
      })
      


      const formik = useFormik({
      initialValues:{
        name: '',
       phone: '',
       company:'',
       activity:'',
       city:'',
        password: '',
        confirmPassword: '',
    
      },
      validationSchema: Schema,
      onSubmit
      });
   
      const passRef=useRef();
          const phoneRef=useRef();
          const passConfirmRef=useRef();
          const nameRef=useRef();

          const cityRef=useRef();
          const companyRef=useRef();
          const activityRef=useRef();

         
      
          useEffect(() => {
            companyActivities().then(response => {
  
              setActivities(response.data)
                   
                   })
     countries().then(response => {
  
  
 setCity(response.data.data[0].cities)
 setLoading(false)
      
      }).catch(function(error){
 
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("phone");
          localStorage.removeItem("name");
          localStorage.removeItem("fieldsArr")
        
          history("/")
       }
      
       
       }); 

             },[]);




//for hiding and showing password
             function show() {
        setHidden(!hidden);
        var x = document.getElementById("pass1");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }
//for hiding and showing confirm password
      function show2() {

        setHidden2(!hidden2);
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }





//On submitting for registration (takes:name,phone,countryId,password,password confirmation and agreement to terms (bool))
//user is given a token that is stored to be used in /verify where the user is redirected

      async function onSubmit(event){
        setLoading(true);
        const data={
     
      
          name:nameRef.current.value,
          phone:phoneRef.current.value,
          facilityName:companyRef.current.value,
          activityId:activityRef.current.value,
          cityId:cityRef.current.value,
          password:passRef.current.value,
          password_confirmation:passConfirmRef.current.value,
        }
        register(data,i18n.language).then((response) => {
         
          localStorage.setItem("token",response.data.data.access_token)
localStorage.setItem("name",response.data.data.user.name)
localStorage.setItem("phone",response.data.data.user.phone)
localStorage.setItem("userType","")
localStorage.setItem("lastStep",null)
localStorage.setItem("id",response.data.data.id)

          var frm = document.getElementById('regForm');
          frm.reset(); 
      
          history("/confirm");
         

 

}).catch(function (error) {
 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }
  
   
 
  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)
    
                                  }
                                    
                                    )}


 
   return (
        <div>
        
              

      
{loading?
      <div style={{height:"110vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div style={loading?{display:"none"}:null}  className='row'>
<div className='col-md-6 homeContainer regContainer'>
    <div className='loginTitle'>
<p >{t("Login.registeration")} </p></div>
<form className='loginForm ' id="regForm"  name='regForm' onSubmit={formik.handleSubmit}>
<label> {t("Login.yourName")} </label>


<input type="text" placeholder= {t("Login.yourName")} value={formik.values.name} id="name" name="name"  ref={nameRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.name && formik.touched.name) ||validation.name.err==true ?"invalid":null}/>
<span>{formik.errors.name && formik.touched.name?t(`${formik.errors.name}`):null}</span>
<span>{validation.name.err=true? validation.name.text:null}</span>

 

<label>  {t("Login.byPhone")}</label>

<input type="text" placeholder={t("Login.byPhone")} value={formik.values.phone} id="phone" name="phone"  ref={phoneRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.phone && formik.touched.phone) || validation.phone.err==true ?"invalid":null}/>
<span>{formik.errors.phone && formik.touched.phone?t(`${formik.errors.phone}`):null}</span>
<span>{validation.phone.err=true? validation.phone.text:null}</span>

{errorMsgModelp?
  <span>{i18n.language=="ar"? msgArp:msgEnp}</span>:null
}


<label>  {t("Login.company")}</label>

<input type="text" placeholder={t("Login.company")} value={formik.values.company} id="company" name="company"  ref={companyRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.company && formik.touched.company) ||validation.facilityName.err==true ?"invalid":null}/>
<span>{formik.errors.company && formik.touched.company?t(`${formik.errors.company}`):null}</span>
<span>{validation.facilityName.err=true? validation.facilityName.text:null}</span>

<label>  {t("Login.companyActiv")}</label>
<div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

<select value={formik.values.activity} id="activity" name="activity"  ref={activityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.activity && formik.touched.activity) ||validation.activityId.err==true?"invalid":formik.values.activity==""?null:"chosenSelect"}>
<option selected disabled value="">{t("Login.companyActivChoose")}</option>
{activities && activities.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.name_ar:data.name_en} </option>

  
  )
}

</select>
<div class="select-arrow">
<BsChevronDown size={20}/>

</div>
</div>
<span>{formik.errors.activity && formik.touched.activity?t(`${formik.errors.activity}`):null}</span>
<span>{validation.activityId.err=true? validation.activityId.text:null}</span>


<label>  {t("Login.city")}</label>


<div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

<select value={formik.values.city} id="city" name="city"  ref={cityRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.city && formik.touched.city) ||validation.cityId.err==true?"invalid":formik.values.city==""?null:"chosenSelect"}>
<option selected disabled value="">{t("Login.cityChoose")}</option>
{city && city.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>

  
  )
} 

</select>
<div class="select-arrow">
<BsChevronDown size={20}/>

</div>
</div>
<span>{formik.errors.city && formik.touched.city?t(`${formik.errors.city}`):null}</span>
<span>{validation.cityId.err=true? validation.cityId.text:null}</span>




<label>   {t("Login.pass")}</label>
<div className='loginPass'>

<input id="pass1" style={{width:"100%"}} type="password" value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
{hidden?
<AiOutlineEyeInvisible onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'}style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span>

</div>
<label>     {t("Login.confirmPass")}</label>
<div className='loginPass' >


<input id="confirmPassword"  style={{width:"100%"}}  type="password" value={formik.values.confirmPassword}  name="confirmPassword"  ref={passConfirmRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword?"invalid":null}/>
{hidden2?
<AiOutlineEyeInvisible onClick={show2} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show2} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<span>{formik.errors.confirmPassword && formik.touched.confirmPassword?t(`${formik.errors.confirmPassword}`):null}</span>

</div>




{i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}
<button type="submit" style={{marginTop:"2em"}}> {t("Login.register")}</button>


</form>
<div className='register'>
<Link to="/">
<p>       {t("Login.haveAccount")} <span className='regColor'>{t("Login.start")}</span>  </p></Link>
</div>
</div>
<div className='col-md-6 loginBckg'>
<div  className='bckg'  >
<div  className={i18n.language=="ar"?'decorationLogin':'decorationLogin decorationLoginEn'}  style={{backgroundImage:`url(${decoration})`}}>
</div>
<div className="chickenDecore" >
  <p>{t("Login.bckgText")}</p>
<div   style={{backgroundImage:`url(${chicken2})`}}>
</div>
</div>


</div>
</div>
</div>



</div>
    );
}

export default Register;