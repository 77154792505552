import "../../styles/UserProfile.css";

import { useRef ,useState} from "react";
import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import {useFormik} from "formik";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { BeatLoader } from "react-spinners";
import { changePassword } from "../../api";
import { useNavigate } from "react-router";
import SuccessModal from "../../components/SuccessModal";
function ChangePass(props) {
    const [hidden3,setHidden3]=useState(false);
     let history=useNavigate()
    const [loading,setLoading]=useState(false);
    const [success, setSuccess]=useState(false);

    const [hidden,setHidden]=useState(false);
    const [hidden2,setHidden2]=useState(false);
    const [msgEn, setMsgEn]=useState();
    const [msgAr, setMsgAr]=useState();
    const [errorMsgModel, setErrorMsgModel]=useState(false);

    const { t, i18n } = useTranslation();

   
   
    const Schema= yup.object().shape({
      oldPassword: yup.string().min(6, ("validation.at_least")).required(("validation.password_required")),
        password: yup.string().min(6, ("validation.at_least")).required(("validation.password_required")),
        confirmPassword: yup.string()
.oneOf([yup.ref('password'), null], ("validation.password_must_match"))
.required(("validation.confirm_required"))
    })
    


    //for showing and hiding password when clicking on eye icon
    function show() {

        setHidden(!hidden);
        var x = document.getElementById("pass1");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

    //for showing and hiding password when clicking on eye icon
      function show2() {

        setHidden2(!hidden2);
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

    //for showing and hiding password when clicking on eye icon
      function show3() {

        setHidden3(!hidden3);
        var x = document.getElementById("pass3");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }


      const formik = useFormik({
        initialValues:{
          oldPassword: '',
          password: '',
          confirmPassword: '',
         
        },
        validationSchema: Schema,
        onSubmit
        });
   
        const passRef=useRef();
        const oldPassRef=useRef();
          
            const passConfirmRef=useRef();
            
      
//takes new and old password
//edits user password
//this component is then set to false (shows user profile instead)
function onSubmit(){
  setLoading(true)

  const token= localStorage.getItem("token");

  
const values={

  password: passRef.current.value,
  oldPassword: oldPassRef.current.value,
  password_confirmation: passConfirmRef.current.value


}

changePassword( values, token).then((response) => {
          console.log(response);
          setSuccess(true)

         // props.setChangePass(false)

      }).catch(function (error) {
 
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("fieldsArr")
          
            history("/")
         }
        
         
    
        setMsgAr('كلمة المرور القديمة غير صحيحة')
        setMsgEn(error.response.data.error)
        setErrorMsgModel(true)
     setLoading(false)

      })

  


}


    return (
       
      
  

    <div className="passChange" style={{padding:0}}>
      {success? <SuccessModal  setActive={props.setActive} setEdit={props.setEdit}setLoading={setLoading}  setChangePass={props.setChangePass} type="pass" txt={t("profile.succssPass")} setModal={setSuccess}/>:null}

    {loading?
      <div style={{height:"60vh",margin:"0"}} className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}


<div style={loading?{display:"none"}:null}>
    <div  >
    <div style={{textAlign:"start"}}>
<p className="addressTitle">     {t("profile.change_pass")}</p></div>
    </div>
<form  onSubmit={formik.handleSubmit}>

<div className="flexForm">


    


 
<div className="inputDiv">
    <label>     {t("profile.old_pass")}</label>
    <div style={{position:"relative"}}>
<input id="pass1"  type="password"  value={formik.values.oldPassword}  name="oldPassword"  ref={oldPassRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.oldPassword && formik.touched.oldPassword?"invalid":null}/>
{hidden?
<AiOutlineEyeInvisible style={{color:"#ACACAC"}}  onClick={show} size={16}className={i18n.language=="ar"?'eye': 'eyeEn'}/>:
<AiOutlineEye style={{color:"#ACACAC"}}  onClick={show} size={16} className={i18n.language=="ar"?'eye': 'eyeEn'}/>}
<span>{formik.errors.oldPassword && formik.touched.oldPassword?t(`${formik.errors.oldPassword}`):null}</span>

</div>

</div>

<div className="inputDiv">
    <label>     {t("Login.pass")}</label>
    <div style={{position:"relative",margin:"0 5%"}}>
<input id="pass3"  type="password"  value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
{hidden3?
<AiOutlineEyeInvisible style={{color:"#ACACAC"}}  onClick={show3} size={16}className={i18n.language=="ar"?'eye': 'eyeEn'}/>:
<AiOutlineEye style={{color:"#ACACAC"}}  onClick={show3} size={16} className={i18n.language=="ar"?'eye': 'eyeEn'}/>}
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span>

</div>

</div>

<div className="inputDiv">
<label>     {t("Login.confirmPass")}</label>
<div style={{position:"relative"}}>

<input id="confirmPassword"  type="password" name="confirmPassword"    value={formik.values.confirmPassword}   ref={passConfirmRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword?"invalid":null}/>
{hidden2?
<AiOutlineEyeInvisible style={{color:"#ACACAC"}}  onClick={show2} size={16} className={i18n.language=="ar"?'eye': 'eyeEn'} />:
<AiOutlineEye style={{color:"#ACACAC"}}  onClick={show2} size={16} className={i18n.language=="ar"?'eye': 'eyeEn'}/>}
<span>{formik.errors.confirmPassword && formik.touched.confirmPassword?t(`${formik.errors.confirmPassword}`):null}</span>

</div>
</div>

</div>

    {i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}

<div className="formBtns" style={{marginTop:"1em"}}>
<button type="submit" className="dataEdit">{t("profile.save")}</button>
<span> </span>
<button type="button" onClick={()=>props.setChangePass(false)}  className="cancelEdit">{t("profile.cancel")}</button></div>
</form>

</div></div>
 


 
    );
  }
  
  export default ChangePass;