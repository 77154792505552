import React, {useEffect, useState} from 'react';

import success from "../images/success-web.svg";
import failed from"../images/faild-web.svg";

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import {payment ,pay_again} from "../api";
import { Link } from 'react-router-dom'; 
function PaymentSuccess(){
 const  [loading,setLoading]=useState(true);
 const { t, i18n } = useTranslation();
 
  const [state,setState]=useState(false);

   const [paymentLoad,setPaymentLoad]=useState(false);
const paymentType= localStorage.getItem("tapPayment","order")

   const search = window.location.search;
   const params = new URLSearchParams(search);

   const TranId = params.get('TranId');
   let history = useNavigate();
  
    //CustomerInfo api call to get customer info for webengage and analytics
    //payment api call checks if operation was succeccfull and which type was it (order / wallet)
    //depending on type of operation different html is rendered
    //if operation failed if user was previously charging their wallet, user is redirected to  /charge-wallet (ChargeWallet component in /Pages)
    //else display is changed showing failure of operation
    useEffect(() => {

      window.scrollTo(0, 0);
  

        const search = window.location.search;
        const params = new URLSearchParams(search);
        let tapId = params.get('tap_id');
 
        if(tapId !== ""){

        
            payment(tapId ,localStorage.getItem("token")).then((response) => {

                setState(true)
              
                setLoading(false);
               
              
    
        
            }).catch(function (error) {


             

                    setState(false)
                    setLoading(false);
                         

                


        
             
            });
          
            




        } else {
            setState(false)
            setLoading(false);
           
        }
      
      
      } ,[ ]);



      //if operation was successful and user clicks on track order
      //user is redirected to /order-track (OrderTracking component in /Pages) with order id as params

        function handleProceed () {
            var id=localStorage.getItem("orderId");
            history(generatePath("/order-details/:id", { id }));
            
          };  
  

      //if operation failed and user clicks on pay again
      //pay_again api call takes order id and redirects user to third party service
   function payAgain(){ 
    setPaymentLoad(true);
        let values = {
            transactionId: TranId
        };   
        pay_again(localStorage.getItem("orderId"),localStorage.getItem("token")).then((response) => {

             

           window.location.href = response.data.data.url
          
        }).catch(function (error) {
            // console.log(error.response)
            setPaymentLoad(false);
        });
    }

     
        return (
          <div>{loading?<div className='loader'>
          <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
          <>

<div style={{marginTop:"5em",marginBottom:"5em"}} className='container orderSuccess'>
  
{state? <div className='shareForm '>
   <img style={{marginBottom:"1em"}} src={success}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.sucess")}
  </p>
  
  <Link to="/profile/my-wallet">
<button style={{display:"inline-block"}}> {t("payment.backWallet")}</button>

</Link>
   </div>:


<div className='shareForm '>
   <img style={{marginBottom:"1em"}}  src={failed}/>
 
  <p style={{color:"rgb(99, 50, 53)"}}>
  {t("payment.notSuccess_payment")}
  </p>

{paymentLoad?

<div style={{marginTop:"50px"}} className='loaderDiv'>

<BeatLoader color={'#E47100'} loading={paymentLoad}  size={30} /></div>:
<>
{paymentType=="order"?<button style={{display:"inline-block"}} onClick={payAgain}> {t("payment.pay_again")}</button>

:
<Link to="/profile/my-wallet">
<button style={{display:"inline-block"}} > {t("payment.backWallet")}</button>
</Link>
}</>

}
   </div>
    
    
   }
    </div>
                

     </>   
                }
      
                </div>
           
        )
    }


    export default PaymentSuccess;
