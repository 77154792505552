   
import React, {useState,useEffect} from 'react';
import invoiceImg from '../images/invoice.png'
import { useTranslation } from 'react-i18next';
function Invoice(props) {

const [loading,setLoading]=useState(true)
const [invoice,setInvoice]=useState(false)
 const { t, i18n } = useTranslation();



   return (
     <div>
   
  
       <div className='container-fluid fluid'>
       <div className='vat invoiceDiv'style={{marginBottom:"5em",paddingTop:"0"}}>
<div className='invoiveLogo'  style={{ backgroundImage: `url(${invoiceImg})`}} >
   </div> 
<p className='invoiceTitle'> {t("process.invoiceTitle")}</p>
<div className='invoiceData'>
<div>
   <p>{t("process.branchName")}</p>
   <p className='invoice-value'>{i18n.language=="ar"?props.data.branchArName:props.data.branchEnName}</p>
</div> 
<div>
   <p>{t("process.branchPhone")}</p>
   <p className='invoice-value'>{props.data.branchPhone}</p>
</div>
<div>
   <p>{t("process.commercialReg")}</p>
   <p className='invoice-value'>87271-2</p>
</div>
<div>
   <p>{t("profile.orderId")}</p>
   <p className='invoice-value'>{props.data.id}</p>
</div>
<div> 
   <p>{t("profile.type")}</p>
   <p className='invoice-value'> { t("addOrder.home")}</p>
</div>
<div>
   <p>{t("profile.date")}</p>
   <p className='invoice-value'>{props.data.createdAt}</p>
</div>
<div>
   <p>{t("profile.payment")}</p>
   <p className='invoice-value'>{props.data.paymentMethod=="cash"?t("process.cash"):props.data.paymentMethod=="tap"?t("process.credit"):t("process.wallet")}</p>
</div>

{invoice.notes!==""?<div>
   <p >{t("menu.notes")} </p> 
   <p className='invoice-value' style={{maxWidth:"220px",lineHeight:"24px",textAlign:"start"}}>{props.data.notes}</p>
</div>:null}
</div>

   <div className="upTable invoiceTableDiv ">
   <table className="invoiceTable table  align-middle table-striped table-hover table-borderless ">
 <thead>
   <tr>

  
     <th scope="col" colSpan={2}>{t("process.meal")}</th>
     <th scope="col">{t("process.quantity")}</th>
     <th scope="col">{t("process.price")}</th>
   
   </tr>
 </thead>
 <tbody> 
 {props.data.products && props.data.products.map((data,index)=>


<tr>
   {i18n.language=="ar"?
   <td colSpan={2}>
       
       {data.productArName}  {" - "+data.sizeArName} 
                           {data.additionals.length !== 0 ? " - "+ data.additionals.map((item) => item.additionalArName) : " "}
                           {data.extras.length !== 0 ?" - "+ data.extras.map((item) => item.arName + " ") : "  "}
                     
       </td>:
   <td colSpan={2}>
         {data.productEnName} - {data.sizeEnName}
                           {data.additionals.length !== 0 ?" - "+ data.additionals.map((item) => item.additionalEnName) : " "}
                           {data.extras.length !== 0 ? " - "+data.extras.map((item) => item.enName + " ") : " "}
                     
   </td>
   
   }
 
   <td>{data.quantity}</td>
   <td>{data.price}</td>
</tr>


)}
</tbody></table></div>



<div className='invoiceData' style={{padding:"1em 0"}}>
<div>
   <p>{t("process.price")}</p>
   <p className='invoice-value'>{props.data.originalPrice.toFixed(1)} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div> 

{props.data.discount > 0 ?
      <div>                      
   <p>{t("process.discount")}</p>
   <p className='invoice-value'>{parseFloat(props.data.discount).toFixed(1)} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div>
                               
                               :null}
<div>
   <p>{t("process.tax")+" "+ `(${props.data.tax}%)`}</p>
   <p className='invoice-value'>{props.data.taxValue} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div>


<div>
   <p>{t("process.delivery_charge")}</p>
   <p className='invoice-value'>{props.data.deliveryPrice} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div>

{props.data.paymentMethod=="wallet"?
<>
<div>
   <p>{t("process.total")}</p>
   <p className='invoice-value'>{props.data.totalPrice} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div>
<div>
   <p>{t("process.walletPay")}</p>
   <p className='invoice-value'>{props.data.walletPay} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div>

<div> 
   <p className='invoiceTotal1'>{t("process.remainPrice")} </p>
   <p className='invoiceTotal2'>{props.data.remainPrice==""?0 :props.data.remainPrice} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div> </>
:

<div> 
   <p className='invoiceTotal1'>{t("process.total")} </p>
   <p className='invoiceTotal2'>{props.data.totalPrice.toFixed(1)} {i18n.language=="ar"?props.data.currencyAr:props.data.currencyEn}</p>
</div> 
 
}

</div>
<div className='caution'>
<p className='invoiceTotal2' style={{marginBottom:"0"}}>{t("process.caution1")}</p>
<p className='invoiceTotal1'>{t("process.caution2")}</p>
</div> </div>
 </div></div>
   );
} 

export default Invoice;
