
import "../../styles/UserProfile.css";
import { useTranslation } from 'react-i18next';
import { IoAddCircleOutline } from "react-icons/io5";
import { useEffect,useState } from "react";
import { delete_address ,getAddresses} from '../../api'
import UserNav from "../../components/UserNav";
import LogoutModal from "../../components/LogoutModal";
import { BeatLoader } from "react-spinners";
import DeleteModal from "../../components/DeleteModal";
import { RiDeleteBin5Line } from "react-icons/ri";
import AddAddress from "./AddAddress";
import ErrorModal from "../../components/ErrorModal";
import { useNavigate } from "react-router";

import { FiEdit } from "react-icons/fi";

function MyAddresses(data) {
  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(true);
  const [ addAddress, setAddAddress]= useState(false);
  const [address,setAddresses]=useState([]);
  const [reload,setReload]=useState(false);
  const [modal,setModal]=useState(false)
  const [delModal,setDelModal]=useState(false)
  const [delId,setDelId]=useState()
let history= useNavigate()
  const [error,setError]=useState(false)

  
//fetch user addresses
  useEffect(() => {
    const token= localStorage.getItem("token");

    getAddresses(token).then(response => {
     const data=response.data.data;
     setAddresses(data);
setLoading(false);

  }).catch((err)=>{

    if(err.response.data.error=="not_verified"){
      setError(true);
setLoading(false)
    }
    else if (err.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     
   
  })
  
   
  
  
   },[reload,addAddress]);


   useEffect(() => {
if(localStorage.getItem("prevScreen")=="order"){
  setAddAddress(true)

}

  },[]);
     
   //on confirimg delete address in DelAddress modal 
   //delete_address api takes address id (this id gets set when user clicks on an AddressCard delete button)
function handleDelete(){

  const token= localStorage.getItem("token");
  setLoading(true);

  delete_address(delId,token).then(response => {
  
 setReload(!reload); //to rerender screen and update displayed addresses
 }).catch(function(error){
 
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("name");
    localStorage.removeItem("fieldsArr")
  
    history("/")
 }

 
 }); 

}





    return ( 
     <>


<div> 
      {modal?
      <LogoutModal setModal={setModal} setCartItem={data.setCartItem}/>
      :null
     }
     {error?
     <ErrorModal setModal={setError} msg={i18n.language=="ar"?"Please verify your account":"يرجى التحقق من حسابك"}/>:null
    }
        {delModal?
      <DeleteModal handleDelete={handleDelete} setModal={setDelModal}/>
      :null
     }
      <div className={i18n.language=="ar"?"userProfile":"userProfile userProfileEn"}>
      <UserNav setModal={setModal} nav={1} /> 
      {loading || error?
      <div className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <>
      {!addAddress?
<div className="aboutusBody  profileBody container-fluid fluid">
  <div className="upTable-buttonMobile">
  <button onClick={()=> {setAddAddress(true); localStorage.setItem("prevScreen","address")}}   className="upTable-button "><IoAddCircleOutline size={20}/> {t("profile.add_address")}</button>

  </div>

   
    <div className="addressContainer">

    <div className='formTopper row' style={{zIndex:"2"}}>
    <div className="upAddress"> 
    <p></p>
    <button onClick={()=> {setAddAddress(true); localStorage.setItem("prevScreen","address")}}   className="upTable-button upTable-addressBtn"><IoAddCircleOutline size={20}/> {t("profile.add_address")}</button>


    
    </div> 
<p style={{bottom:"-32px"}}> {t("profile.address")}</p>

</div>

  <div className="row  upAdressCard"> 
  
      {address && address.map((data,index)=>

  

<div className="col-12 noPadding">
<div style={index==0?{height:"100%",padding:"25px"}:{height:"100%",padding:"25px",paddingTop:"0"}} >
    <div className="upAdressCard-div">
    <p >{i18n.language=="ar"?data.arAddress: data.enAddress}</p>

    <div>
 <button onClick={()=>{
 setDelId(data.id)
 setDelModal(true)
 }
}  className="upAdressCard-btn1"><RiDeleteBin5Line size={20}/>{t("profile.delete")} </button> 


</div>
    
    </div>

  
    </div>
</div>
   
     )
} 

 {address.length==0?
 <p className="noAddress">
{t("addOrder.noAddress")}
 </p>:null}
  </div>
</div>
   
</div>:
<>

<div className="aboutusBody profileBody container-fluid fluid">
 
   
    <div >
   < AddAddress setAddAddress={setAddAddress}/>
      {/*

     
  <div className="addAddress"> 
  <div>
  <p>{t("profile.address")}</p>
   <textarea/>
   <button>{t("profile.address")}</button>  
   </div>
</div> */}
</div>
   
</div>
</>
}
</>
}

</div>
         </div>
</>
    );
  }
  
  export default MyAddresses;