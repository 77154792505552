  
import '../styles/ChangePass.css';
import key from "../images/login/KeyIcon.svg"
import changePass from "../images/login/changePass.svg"
import React, {useState,useEffect,useRef} from 'react';
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";

import { useTranslation } from 'react-i18next';

import { BeatLoader } from 'react-spinners';
import * as yup from "yup"
import {useFormik} from "formik";
import {userData,forgetPassChange} from "../api";
import { useNavigate } from "react-router-dom";
function ForgetPassword() {
  const { t, i18n } = useTranslation();
    const [hidden,setHidden]=useState(false);
    const [hidden2,setHidden2]=useState(false);
    let history = useNavigate();
 const [loading,setLoading]=useState(false);
    const [msgAr, setMsgAr]=useState();
    const [msgEn, setMsgEn]=useState();
    const [otp, setOtp]=useState(false);
    const [errorMsgModel, setErrorMsgModel]=useState(false);
    
 

    const Schema= yup.object().shape({
      password: yup.string().min(6, ("validation.at_least")).required(("validation.password_required")),
   
      confirmPassword: yup.string()
      .oneOf([yup.ref('password'), null], ("validation.password_must_match"))
      .required(("validation.confirm_required"))
      
      })
  
  const formik = useFormik({
    initialValues:{
  
      password: '',
      confirmPassword: '',
   
    },
    validationSchema: Schema,
    onSubmit
  
    }); 
    console.log(formik.errors);
    const passRef=useRef();
   
      const passConfirmRef=useRef();

//to show or hide password
    function show() {

        setHidden(!hidden);
        var x = document.getElementById("pass1");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }
//to show or hide password confirmation
      function show2() {

        setHidden2(!hidden2);
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

      useEffect(() => {
        localStorage.setItem("prevScreen","redirect"); //for use in loggin (if prevScreen = redirect logging in redirects to home screen instead of previous screen)
      
       },[]);
 
//(takes password) , forgetPassChange api changes the password 
//then c is used to fetch customer data then redirecting them to home screen

 
      async function onSubmit(){
        setLoading(true)
        const token= localStorage.getItem("token");
        console.log(passRef.current.value)
         const data={
        
          password: passRef.current.value
         }
         forgetPassChange(token,data).then((response) => {
          userData(token).then((response) => {
        
           if(response.data.data.status !=="approved"){

            localStorage.setItem("userType","")
            history("/confirm")
          }
          else{
    if(response.data.data.externalSaleType=="prepayment"){
      if(response.data.data.lastCompletedStep==null){
        history("/adv-payment")
    
      }
      else{
        history("/profile/my-profile")
    
      }
    }
    else{
      if(response.data.data.lastCompletedStep==null){
        history("/register-1")
      }
      else if(response.data.data.lastCompletedStep==1){
        history("/register-2")
    
      }
      else if(response.data.data.lastCompletedStep==2){
        history("/register-3")
    
      }
      else{
        history("/profile/my-profile")
    
      }
    }
          }

         })
       }).catch(function (error) {
        setLoading(false)
                                   console.log(error)
                                 });
       }
       
  


   return (
        <div>
       
        <div className='loginContainer forgetPasswordContainer container-fluid fluid'>
            
<div style={{padding:"3.5em  2em",position:"relative"}} className='container-fluid login'>
{loading?
      <div style={{height:"50vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:null}
<div  style={loading?{display:"none"}:null} className='row'>
<div className='col-md-6 passAlign'>
<img src={key}/>
<p className='forgot'>       {t("Login.new-pass")}</p>
 
<form className='passForm' onSubmit={formik.handleSubmit}>
    <div style={i18n.language=="ar"?{textAlign:"right",display:"block"}:{textAlign:"left",display:"block"}}>
<label  style={{marginTop:"1.5em"}}>   {t("Login.enter-new-pass")}</label></div>
<div className='loginPass' style={{position:"relative",width:"100%"}}>
<input id="pass1" style={{width:"100%"}}  type="password" value={formik.values.password}  name="password"  ref={passRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.password && formik.touched.password?"invalid":null}/>
{hidden?
<AiOutlineEyeInvisible onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<span>{formik.errors.password && formik.touched.password?t(`${formik.errors.password}`):null}</span></div>
 
</div>
<div style={i18n.language=="ar"?{textAlign:"right",display:"block"}:{textAlign:"left",display:"block"}}>
<label  style={{marginTop:"1em"}}>     {t("Login.confirmPass")}</label></div>
<div className='loginPass' style={{position:"relative",width:"100%"}}>

<input id="confirmPassword"   style={{width:"100%"}}  type="password" value={formik.values.confirmPassword}  name="confirmPassword"  ref={passConfirmRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={formik.errors.confirmPassword && formik.touched.confirmPassword?"invalid":null}/>
{hidden2?
<AiOutlineEyeInvisible onClick={show2} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>:
<AiOutlineEye onClick={show2} size={30} className={i18n.language=="ar"?'eye': 'eyeEn'} style={i18n.language=="ar"?{right:"auto",left:"8px"}:null}/>}
<div className='fpFormLabel' style={i18n.language=="ar"?{textAlign:"right"}:null}>
<span>{formik.errors.confirmPassword && formik.touched.confirmPassword?t(`${formik.errors.confirmPassword}`):null}</span></div>

</div>

{i18n.language=="ar"?
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgAr:null}</span></div>:
<div style={i18n.language=="ar"?{textAlign:"right"}:{textAlign:"left"}}>
<span>{errorMsgModel? msgEn:null}</span></div>}
<div></div>
<button style={{width:"100%"}} type="submit"> {t("Login.confirm")}</button>
</form>
</div>

<div className={i18n.language=="ar"?'col-md-6 passPhoneImage':"col-md-6 passPhoneImageEn"}>
  
<img src={changePass}/>

</div>
</div>
</div>
</div>
</div>
    );
}

export default ForgetPassword;