import "../../styles/UserProfile.css";
import coin from "../../images/TransactionCoin.svg"
import bg from "../../images/TransactionBg.svg"
import { useTranslation } from 'react-i18next';
import {  Get_Wallet ,myTransactions} from '../../api'
import { useState,useEffect } from "react";
import UserNav from "../../components/UserNav";
/*import Logout from "../Components/Logout";*/
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import LogoutModal from "../../components/LogoutModal";
import { useNavigate } from "react-router";
import walletImg from "../../images/wallet.svg"
import { TableRow } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
import ChargeWallet from "./ChargeWallet";
function MyWallet(props) {
  const { t, i18n } = useTranslation();

  const[wallet,setWallet]=useState(0);
 let history = useNavigate()
  const[loading,setLoading]=useState(true);
  const [modal,setModal]=useState(false)
 
  const [transaction,setTransactions]=useState([])



  //Get_Wallet api call takes user token => used for getting wallet amount
  useEffect(()=>{

 
    const token= window.localStorage.getItem('token');
  
    myTransactions(token).then(response => {

      setTransactions(response.data.data)
    })
    Get_Wallet(token).then(response => {
  
      if(response.data.data.balance){
      setWallet( parseFloat(response.data.data.balance).toFixed(2));
    
      }
   setLoading(false)
    }).catch(function(error){
 
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     
     }); 
  
  },[ ])
  
    return (
    
      <div> 
      {modal?
      <LogoutModal setModal={setModal} setCartItem={props.setCartItem} />
      :null
     }
      <div className={i18n.language=="ar"?"userProfile":"userProfile userProfileEn"}>
      <UserNav setModal={setModal} nav={2} /> 
      {loading?
      <div className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <div>

<div className="aboutusBody profileBody container-fluid fluid">
  <div className="bodyCard">
    <div className="buttonCard" style={{height:"102px"}}>
    <p  className="upTable-title" style={{fontSize:"32px"}}> {t("profile.myWallet")}</p>

    
      </div>

      <div className="container-fluid">
        <div className="row gy-4">
        <div className="col-xl-6 order-xl-first order-last">
  <ChargeWallet/>
</div>
<div className="col-xl-6 walletCard">
<div style={{width:"100%"}}>

<div className={i18n.language=="ar"?"transactionCredit ":"transactionCredit transactionCreditEn"}> 

<p className="tc-p1"> {t("profile.current")}:</p>
<p className="tc-p22">{wallet} {t("profile.sar")}</p>
 
</div>
<div className="walletBottom">
  <div>
  <p className="walletName">{t("Login.yourName")}:</p>
  <p className="walletNameTxt">{localStorage.getItem("name")}</p>
    </div>
  
<div className="walletLogo" style={{ backgroundImage: `url(${walletImg})` }}></div>

  </div>
</div>
</div>

        </div>
</div>
   



{transaction.length>0?
<div className="tarnsactionTableCard">
  <p>{t("profile.walletHistory")}</p>
  <div className="tableContainer">

  <table>
  <thead className="table-header">
      <tr>
    <th>{t("profile.amount")}</th>
    <th>{t("profile.balanceType")}</th>
    <th>{t("profile.balanceDate")}</th></tr></thead>
    <tbody>
      {transaction && transaction.map((data,index)=>
      
      <tr key={index}>
      <td  >{data.amount} {t("profile.sar")}</td>
    <td className={data.type=="in"?"amount":"deduct"}>{data.type=="in"?t("profile.in"):t("profile.out")}</td>
      <td>{data.createdAt}</td>
      </tr>
      )}
     
  
    </tbody>
  </table></div>
  </div>:null}
    </div>


</div>
           

</div>
} 

</div>

         </div>

  
    );
  }
  
  export default MyWallet;