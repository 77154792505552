import '../styles/Login.css';
import { userData } from '../api';
import React, {useState,useEffect,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { useNavigate } from 'react-router';
function ConfirmReg(props) {
    const { t, i18n } = useTranslation();
    const [loading,setLoading]=useState(true);
let history=useNavigate()
    useEffect(() => {
        const token = localStorage.getItem("token")

        userData(token,localStorage.getItem("id")).then(response => {
          props.setUserName(response.data.data.user.name)
          localStorage.setItem("name",response.data.data.user.name)
            localStorage.setItem("userType",response.data.data.externalSaleType)
            localStorage.setItem("lastStep",response.data.data.lastCompletedStep)
            if(response.data.data.externalSaleType!=="" && response.data.data.externalSaleType!==null){
            if(response.data.data.externalSaleType=="prepayment"){
                if(response.data.data.lastCompletedStep==null){
                  history("/adv-payment")
              
                }
                else{
                  history("/add-order")
                    localStorage.removeItem("branchId");
                    localStorage.removeItem("addressId")
          
        
                }
              }
              else{
                if(response.data.data.lastCompletedStep==null){
                  history("/register-1")
                }
                else if(response.data.data.lastCompletedStep==1){
                  history("/register-2")
              
                }
                else if(response.data.data.lastCompletedStep==2){
                  history("/register-3")
              
                }
                else{
                  history("/add-order")
                    localStorage.removeItem("branchId");
                    localStorage.removeItem("addressId")
          
       
                }
              }}
              else{
                setLoading(false)

              }
      
 
        }).catch(function(error){
 
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("fieldsArr")
          
            history("/")
         }
        else{
            setLoading(false)

        }
         
         }); 
         },[]);

   return (
        <div>
          {loading?
      <div style={{height:"110vh"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

        <div className='loginContainer container-fluid fluid'>
   
   <div className='successMsg'>
       <div>
<p>{t("Login.success")}</p>
</div>
   </div>
  
</div>}

</div>
    );
}

export default ConfirmReg;