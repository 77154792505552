 
import "../../styles/UserProfile.css";
import { useRef,useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import * as yup from "yup"
import {useFormik} from "formik";
import { BiEdit } from "react-icons/bi";
/*import ProfileChangePass from "./ProfileChangePass";*/
import UserNav from "../../components/UserNav";
/*import Logout from "../Components/Logout";*/
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import LogoutModal from "../../components/LogoutModal";
import RegisterStep1 from './ExternalTabs/RegisterTsep1';
import RegisterStep2 from './ExternalTabs/RegisterStep2';
import RegisterStep3 from './ExternalTabs/RegisterStep3';
import BasicData from './ExternalTabs/BasicData';
import { useNavigate } from "react-router";
import { AiOutlinePlus } from "react-icons/ai";
import SuccessModal from "../../components/SuccessModal";
import { FiEdit } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { BsChevronDown } from 'react-icons/bs';

import { countries,registerAdv,branches,userData,companyActivities} from '../../api';
import Documents from "./ExternalTabs/Documents";
function MyProfile(props) {

  const markerRef=useRef()
    const { t, i18n } = useTranslation();
 let history= useNavigate()
   const[loading,setLoading]=useState(true);
   const [modal,setModal]=useState(false)
   const [city,setCity]=useState([]);
   const [activities,setActivities]=useState([]);
   const [chosenBranches,setChosenBranches]=useState([]);
   const [chosenBranchesNames,setChosenBranchesNames]=useState({});
   const [initNames,setInitNames]=useState({});
   const [success, setSuccess]=useState(false);

const[initialChosenBranches,setInitialChosenBranches]=useState([]);
    const [branchArr,setBranchArr]=useState([]);
    const [data,setData]=useState()
    const [facilityName,setFacilityName]=useState("")
    const [msgAr, setMsgAr]=useState();
    const [msgEn, setMsgEn]=useState();
    const [errorMsgModel, setErrorMsgModel]=useState(false);
    const [edit, setEdit]=useState(false);

    const [update,setUpdate]=useState(false)

    const [basicData,setBasicData]=useState(

      {
        client:'',
        clientMobile: '',
        location: '',
        companyActiv:'',
        city: '',
     
      }
    )
    const [validation,setValidation]=useState(
      {
        facilityName: {
          err:false,
          text:""
        },
        activityId: {
          err:false,
          text:""
        }, cityId: {
          err:false,
          text:""
        }, branches: {
          err:false,
          text:""
        }
      
      })
   const [active,setActive]=useState(0)
   const type=localStorage.getItem("userType")=="prepayment"?1:0;
   const [position,setPostion]=useState(

      {
    lat:23.8,
    lng:45.0
    
    
      }
    ) 

  
    const Schema= yup.object().shape({
       companyActiv: yup.string().required(("advPayment.required_companyActiv")),
       city: yup.string().required(("Login.required_city")),
 
       
     })
     
 
     useEffect(() => {
       setEdit(false)
      const token= localStorage.getItem("token")
      localStorage.removeItem("prevScreen")
      companyActivities().then(response => {

        setActivities(response.data)
             
             })
       countries().then(response => {
    
    
   setCity(response.data.data[0].cities)
        
        }).catch(function(error){

          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("fieldsArr")
          
            history("/")
         }
        
         
         }); 
        userData(token,localStorage.getItem("id")).then(response => {
          localStorage.setItem("name",response.data.data.user.name)
          props.setUserName(response.data.data.user.name)
          localStorage.setItem("phone",response.data.data.user.phone)

          branches( response.data.data.city.id).then(response => {
    
    
            setBranchArr(response.data.data)
                 
                 })

          setData(response.data.data)
          setFacilityName(response.data.data.facilityName)
          if(response.data.data.externalSaleType=="prepayment"  ){
          setBasicData({
            client: response.data.data.user.name,
            clientMobile:  response.data.data.user.phone,
            location:response.data.data.branches.length>0?response.data.data.branches[0].id:null,
            companyActiv: response.data.data.clientActivity.id,
            city: response.data.data.city.id,
         
          })
          let brnchArr = [];
          let namesEn = [];
          let namesAr = [];

          for (let i = 0; i < response.data.data.branches.length; i++) {  
            brnchArr.push(response.data.data.branches[i].id);
            namesEn.push(response.data.data.branches[i].enName)
            namesAr.push(response.data.data.branches[i].arName)
            }
            
            setInitialChosenBranches(brnchArr)
            setChosenBranchesNames({
              namesEn:namesEn,
              namesAr:namesAr
            })
            setInitNames({
              namesEn:namesEn,
              namesAr:namesAr
            })

            setChosenBranches(brnchArr)
        }
          else{
            setBasicData({
              client: response.data.data.user.name,
              clientMobile:  response.data.data.user.phone,
              location:response.data.data.branchNo,
              companyActiv: response.data.data.facilityActivity.id,
              city: response.data.data.city.id,
           
            })
          }
          setLoading(false)

        }).catch(function(error){
       
                   if (error.response.status === 401) {
                     localStorage.removeItem("token");
                     localStorage.removeItem("phone");
                     localStorage.removeItem("name");
                     localStorage.removeItem("fieldsArr")
                   
                     history("/")
                  }
                })

               },[update]);
     const formik = useFormik({
     initialValues:{
    
       companyActiv:basicData.companyActiv,
       city: basicData.city,
    
     },
     validationSchema: Schema,
     enableReinitialize: true,
     onSubmit
     });
     const cityRef=useRef();
 
   
     const locationRef=useRef();
     const companyActivRef=useRef();
  
 
    function handleChangeCity(id){
      setChosenBranches([])
setChosenBranchesNames({
  namesEn:[],
  namesAr:[]
})
 
     branches(id).then(response => {
    
    
       setBranchArr(response.data.data)
            
            }).catch(function(error){
 
              if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("phone");
                localStorage.removeItem("name");
                localStorage.removeItem("fieldsArr")
              
                history("/")
             }
            
             
             }); 
    }


    function handleReset(){
     
      formik.resetForm();
      checkList = document.getElementById('list1');
      checkList.classList.remove('visible');
      branches(basicData.city).then(response => {
     
     
        setBranchArr(response.data.data)
        setChosenBranches(initialChosenBranches)
        setChosenBranchesNames(initNames)
             
             }).catch(function(error){
 
              if (error.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("phone");
                localStorage.removeItem("name");
                localStorage.removeItem("fieldsArr")
              
                history("/")
             }
            
             
             }); 
  
    }

    function onSubmit(){
      const token=localStorage.getItem("token")
      if(chosenBranches.length==0){
        setMsgAr("برجاء اختيار الفروع")
        setMsgEn("Please choose from branches list")
        setErrorMsgModel(true)
      }
       else{
     setLoading(true);
     const data={
      facilityName:facilityName,
       activityId:parseInt(companyActivRef.current.value),
       cityId:parseInt(cityRef.current.value),
       branches: chosenBranches
     }
     registerAdv(token, localStorage.getItem("id"),data,i18n.language).then((response) => {
       setUpdate(!update); 
       setErrorMsgModel(false)
     
       setSuccess(true)
}).catch(function (error) {
 
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("phone");
      localStorage.removeItem("name");
      localStorage.removeItem("fieldsArr")
    
      history("/")
   }
  
   

  let errorArr=error.response.data.errors;

  Object.keys(errorArr).forEach((key) => {
 
   if (validation.hasOwnProperty(key)) {
     setValidation((prevState) => ({
       ...prevState,
       [key]: {
         err: true,
         text: errorArr[key][0],
       },
     }));
   }
 });
 setLoading(false)

})}
    }

     


    var checkList;

    function dropDown(){
  
  
      checkList = document.getElementById('list1');
      if (checkList.classList.contains('visible')){
      checkList.classList.remove('visible');}
      else{
        checkList.classList.add('visible');}
     

        console.log(chosenBranches)
        var elements = document.getElementsByClassName('checkBranches');
      
      
        for(var i=0; i<elements.length; i++) { 
          for(var j=0; j<chosenBranches.length; j++) { 
          if(elements[i].value == chosenBranches[j].toString()){
         
            elements[i].checked=true;
        }}
        }
    
}


    function handleBranches(e){
      var checkArr=[]
      var check = document.getElementsByClassName('checkBranches');
    
      for(var i=0; i<check.length; i++) { 
        if(check[i].checked){
       
          checkArr.push(parseInt(check[i].value))
      
      }
      }
     
      setChosenBranches(checkArr)
     
    
    
    }

    
    return (
      <div> 
            
            {success? <SuccessModal setActive={setActive} setEdit={setEdit} txt={t("profile.succss2")} setModal={setSuccess}/>:null}

      {modal?
      <LogoutModal setModal={setModal} setCartItem={props.setCartItem}/>
      :null
    } 
      <div className={i18n.language=="ar"?"userProfile userProfileHeight":" userProfileHeight userProfile userProfileEn"}>
      <UserNav setModal={setModal} nav={5} /> 
      {loading?
      <div className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      
      <>
      {type==0?
  <div className="aboutusBody profileBody container-fluid fluid">
     <div className='contReg'>
{/*
   <div className="orderDetNav">
              <Link to="/add-order" onClick={()=>{
          localStorage.removeItem("branchId");
          localStorage.removeItem("addressId")

   }}> {t("addOrder.profile")} </Link> 
      
              { i18n.language=="ar"?<IoIosArrowBack className="arrow"  color="#98A2B3"/>:<IoIosArrowForward className="arrow"  color="#98A2B3"/> } <a style={{fontWeight:"bold"}}>{t("profile.personal")}</a>
                </div>  
  */}
    <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

<select defaultValue={0} onChange={(e)=>setActive(e.target.value)}>
  <option value={0}>{t("profile.basic")}</option>
  {  data.externalSaleType===null || ( data.externalSaleType=="prepayment" && data.lastCompletedStep!=="completed") || ( data.externalSaleType=="postpaid" && data.lastCompletedStep!=="completed")?null:
<>
  <option  value={1}>{t("register.step1")}</option>
  <option  value={2}>{t("register2.step2")}</option>
  <option  value={3}>{t("register3.step3")}</option>
  <option  value={5}>{t("docs.docs")}</option>
  </>  } 
</select><div class="select-arrow">
<BsChevronDown size={16}/>

</div></div>

<div>

{ data.externalSaleType==null || ( data.externalSaleType=="prepayment" && data.lastCompletedStep=="completed") || ( data.externalSaleType=="postpaid" && data.lastCompletedStep=="completed")? null:
   <Link to={data.externalSaleType=="prepayment"?"/adv-payment":data.lastCompletedStep=="second"?"/register-3":data.lastCompletedStep=="first"?"/register-2":"/register-1"}>
        <button style={edit?{marginBottom:"0"}:null} className="contRegBtn"><AiOutlinePlus size={20}/> {t("register.continue")} </button></Link>

  }
{!edit?
     <>
     {active==5?null:
     <button className="contd" onClick={()=>setEdit(true)}> <FiEdit size={18}/>{t("profile.editUsr")}</button>}
     </>

: <div></div>} 
</div>
  
 
  </div>
  
 
      <div className="userDataExt" >
      <div className="usrTabs">
        <div>
   <p onClick={()=>{setActive(0); }} className={active==0?"activeTab":null}> {t("profile.basic")}</p>
   {  data.externalSaleType===null || ( data.externalSaleType=="prepayment" && data.lastCompletedStep!=="completed") || ( data.externalSaleType=="postpaid" && data.lastCompletedStep!=="completed")?null:
<>
     <p onClick={()=>{setActive(1); }} className={active==1?"activeTab":null}> {t("register.step1")}</p>
     <p onClick={()=>{setActive(2); }} className={active==2?"activeTab":null}> {t("register2.step2")}</p>
     <p onClick={()=>{setActive(3); }} className={active==3?"activeTab":null}> {t("register3.step3")}</p>
     <p onClick={()=>{setActive(5); }} className={active==5?"activeTab":null}> {t("docs.docs")}</p>

     </>  }    </div>
     
     {!edit?
     <>
     {active==5?null:
     <button className="contd" onClick={()=>setEdit(true)}> <FiEdit size={18}/>{t("profile.editUsr")}</button>}
     </>

: <div></div>}      </div>
  {active==0?
  
  <BasicData setEdit={setEdit} setActive={setActive} edit={edit} setUpdate={setUpdate} update={update} setLoading={setLoading} data={data}/>:null}
  
  
  {active==1?
  <RegisterStep1 setActive={setActive} setEdit={setEdit} edit={edit} setUpdate={setUpdate} update={update}/>
  
  :null}
  
  
  
  
  
  
  {active==2?
  <RegisterStep2 setActive={setActive} setEdit={setEdit} edit={edit} setUpdate={setUpdate} update={update}/>:null}
  
  
  
  {active==3?
    <RegisterStep3 setActive={setActive} setEdit={setEdit} edit={edit} setUpdate={setUpdate} update={update}/>
  :null}
  
  {active==5?
    <Documents setActive={setActive} setEdit={setEdit} edit={edit} setUpdate={setUpdate} update={update}/>
  :null}
  </div>
     </div>:
     
  <div className="aboutusBody profileBody container-fluid fluid">
    <div className='contReg'>
     {/*
    <div className="orderDetNav">
              <Link to="/add-order" onClick={()=>{
          localStorage.removeItem("branchId");
          localStorage.removeItem("addressId")

   }}> {t("addOrder.profile")} </Link> 
      
              { i18n.language=="ar"?<IoIosArrowBack className="arrow"  color="#98A2B3"/>:<IoIosArrowForward className="arrow"  color="#98A2B3"/> } <a style={{fontWeight:"bold"}}>{t("profile.personal")}</a>
  </div> */}
                
             
 <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

<select defaultValue={0} onChange={(e)=>setActive(e.target.value)}>
  <option value={0}>{t("profile.basic")}</option>
  { data.lastCompletedStep!=="completed"?null:
   <>
  <option  value={1}>{t("register.step1")}</option>

  <option  value={5}>{t("docs.docs")}</option>
  </>  } 
</select><div class="select-arrow">
<BsChevronDown size={16}/>

</div></div>

<div>

{  data.externalSaleType=="" || (  data.externalSaleType=="prepayment" && data.lastCompletedStep=="completed") || (  data.externalSaleType=="postpaid" && data.lastCompletedStep=="completed")? null:
      <Link to={ data.externalSaleType=="prepayment"?"/adv-payment":data.lastCompletedStep=="second"?"/register-3":data.lastCompletedStep=="first"?"/register-2":"/register-1"}>  
      
      <button  style={edit?{marginBottom:"0"}:null} className="contRegBtn"><AiOutlinePlus size={20}/> {t("register.continue")} </button></Link>
 
 
}
{!edit?
     <>
     {active==5?null:
     <button className="contd" onClick={()=>setEdit(true)}> <FiEdit size={18}/>{t("profile.editUsr")}</button>}
     </>

: <div></div>} 
</div>
  
  

</div>
  
      <div className="userDataExt" >
      <div className="usrTabs" >
        <div>

    
   <p onClick={()=>{setActive(0); }} className={active==0?"activeTab":null}> {t("profile.basic")}</p>
   { data.lastCompletedStep!=="completed"?null:
   <>
     <p onClick={()=>{setActive(1); }}className={active==1?"activeTab":null}> {t("register.step1")}</p>
     <p onClick={()=>{setActive(5); }} className={active==5?"activeTab":null}> {t("docs.docs")}</p>
</>
  }
    </div>
    {!edit?
       <>
       {active==5?null:
       <button className="contd" onClick={()=>setEdit(true)}> <FiEdit size={18}/>{t("profile.editUsr")}</button>}
       </>

: <div></div>}
   </div>
  {active==0?<BasicData  setUserName={props.setUserName} setActive={setActive} type="prepayed" setUpdate={setUpdate} edit={edit} setEdit={setEdit} update={update} setLoading={setLoading} data={data}/>:null}
  
  {active==5?
    <Documents setActive={setActive} setEdit={setEdit} edit={edit} setUpdate={setUpdate} update={update}/>
  :null}
  {active==1?
  <>
  

  <div className='container-fluid'>
 

 {edit?null: <div className='formTopper row'>
   

<p> {t("profile.myData")}</p>

</div>}
<div className={edit?null: "dataContainer row"}>

   <form className={edit?"row gy-4 ":"row gy-4  disabledForm"} onSubmit={formik.handleSubmit}>
    
  <div className=" col-xl-4 col-sm-6">
  <div className="inputDiv">
  <label>  {t("Login.companyActiv")}:</label>
  <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

  <select defaultValue={formik.values.companyActiv} value={formik.values.companyActiv} id="companyActiv" name="companyActiv"  ref={companyActivRef} required={true} onChange={formik.handleChange} onBlur={formik.handleBlur}  className={(formik.errors.companyActiv && formik.touched.companyActiv) ||  validation.activityId.err==true?"invalid chosenSelect":"chosenSelect"}>
  <option selected disabled value="">{t("Login.companyActivChoose")}</option>
  {activities && activities.map((data,index)=>


<option key={index}  value={data.id}  >{i18n.language=="ar"?data.name_ar:data.name_en} </option>

  
  )
}
  
  </select><div class="select-arrow">
<BsChevronDown size={20}/>

</div></div>
  <span>{formik.errors.companyActiv && formik.touched.companyActiv?t(`${formik.errors.companyActiv}`):null}</span>
  <span>{validation.activityId.err=true? validation.activityId.text:null}</span>

  
  </div>
  </div>
  <div className=" col-xl-4 col-sm-6">
  <div className="inputDiv ">
  <label>  {t("Login.city")}:</label>
   
  
  <div className={i18n.language=="ar"?'select-container':'select-container select-containerEn'}>

  <select defaultValue={formik.values.city} value={formik.values.city} id="city" name="city"  ref={cityRef} required={true} onChange={  (e)=>{ handleChangeCity(e.target.value); formik.handleChange(e)}} onBlur={formik.handleBlur}  className={(formik.errors.city && formik.touched.city) || validation.cityId.err==true?"invalid chosenSelect":"chosenSelect"}>
  <option selected disabled value="">{t("Login.cityChoose")}</option>
  {city && city.map((data,index)=>
  
  
  <option key={index}  value={data.id}  >{i18n.language=="ar"?data.arName:data.enName} </option>
  
    
    )
  } 
  
  </select>
  <div class="select-arrow">
<BsChevronDown size={20}/>

</div></div>

  <span>{formik.errors.city && formik.touched.city?t(`${formik.errors.city}`):null}</span>
  <span>{validation.cityId.err=true? validation.cityId.text:null}</span>

  
  </div></div>
  <div className={ edit? i18n.language=="ar"?"col-xl-4 col-sm-6" :"menuEn col-xl-4 col-sm-6":i18n.language=="ar"?" col-12":"menuEn col-12"}>
  
  <div className="inputDiv branchAlign">


  <label>  {t("advPayment.location")}:</label>
 {edit?
<div  id="list1" className="dropdown-check-list" tabIndex="100">
  <div style={i18n.language=="ar"?{paddingLeft:"0.5em"}:{paddingRight:"0.5em"}} className='srchNote'>
  <span onClick={dropDown} className="anchor">{ t("profile.choose")}  
  
  </span>


  </div>
 
  <ul className="items"> 
  <li style={{position:"relative"}}>

</li>

{branchArr && branchArr.map((data,index)=>

<li  key={index}  >
<input type="checkbox" className='checkBranches'   value={data.id} onChange={(e)=>handleBranches(e)}/>
        <span> {i18n.language=="ar"?data.arName:data.enName}</span> 
     </li>


  
  )
} 
{branchArr.length==0?
<p className='noResult'>{ t("advPayment.noBranches")}</p>:null}

        </ul>
</div>:
<p className="selectedBranches">{i18n.language=="ar"?chosenBranchesNames.namesAr.join(', '):chosenBranchesNames.namesEn.join(', ')}</p>
}



  
  <span>{formik.errors.location && formik.touched.location?t(`${formik.errors.location}`):null}</span>
  <span>{validation.branches.err=true? validation.branches.text:null}</span>

  </div>
  
  
  
  </div>
  
  
  <div> 
  </div>
  {i18n.language=="ar"?
<span>{errorMsgModel? msgAr:null}</span>:
<span>{errorMsgModel? msgEn:null}</span>}

{edit?
  <div style={{marginTop:"2em"}} className="formBtns">


  <button type="submit" className="dataEdit">{t("profile.save")}</button>
  <span> </span>
  <button type="button" className="cancelEdit" onClick={()=>{setEdit(false); handleReset()}}>  {t("profile.cancel")}</button></div>

:
null
}

  </form> </div></div></>:null}
  
  
  
  
  
  
  
  
  </div>
     </div>
     }
     </>

} 

</div>
         </div>

 
    );
  }
  
  export default MyProfile;