import '../styles/Dashboard.css';

import { useTranslation } from 'react-i18next';

function LimitError(props) {
   
    const { t, i18n } = useTranslation();

 
    return (
        <div >
         
        <div onClick={()=>props.setModal(false)} className="modalOverlay"></div>
        <div  className="modal-content" style={{height:"auto",maxWidth:"90vw",paddingTop:"2em",position:"fixed"}}>
        

<p className='alrt'>{props.txt}</p>
<div className='modalBtns'>
<button onClick={()=>props.setModal(false)}  className='del'>{t("menu.confirm")}</button>

</div>
        </div>
      </div>
  
    );
  }
  
  export default LimitError;
  
  