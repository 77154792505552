
import { useTranslation } from 'react-i18next';
import {  Get_Wallet ,myTransactions} from '../../../api'
import { useState,useEffect } from "react";
import UserNav from "../../../components/UserNav";
/*import Logout from "../Components/Logout";*/
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import LogoutModal from "../../../components/LogoutModal";
import { getDocuments } from '../../../api';
import { useNavigate } from "react-router";
import { IoDocumentAttachOutline} from "react-icons/io5";
import AddDocument from '../../../components/AddDocument';
import SuccessModal from '../../../components/SuccessModal';

import Stack from '@mui/material/Stack';
import PaginationItem from '@mui/material/PaginationItem';
import Pagination from '@mui/material/Pagination';
import { MDBDataTable } from 'mdbreact';
function Documents(props) {
  const { t, i18n } = useTranslation();

  const[wallet,setWallet]=useState(0);
 let history = useNavigate()
  const[loading,setLoading]=useState(true);
  const [modal,setModal]=useState(false)
  const [add,setAdd]=useState(false)
  const [success,setSuccess]=useState(false)
  const [page,setPage]=useState(1);
  const[resp,setResp]=useState()
  const[update,setUpdate]=useState(false)

  const [transaction,setTransactions]=useState([])
  const [doumentsArr,setDocumentsArr]=useState([])
  const token= window.localStorage.getItem('token');



  //Get_Wallet api call takes user token => used for getting wallet amount
  useEffect(()=>{
    setLoading(true)
 
    getDocuments(token).then(response => {
      setResp(response.data.data)


      if( response.data.data.toString()==[]){ //otherwise causes an error
        setDocumentsArr([])
   
      }
      else{
   
        setDocumentsArr(Object.values(response.data.data.data))
      }
setDocumentsArr(Object.values(response.data.data.data))
        setLoading(false)
     }).catch(function(error){
 
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("fieldsArr")
      
        history("/")
     }
    
     
     }); 
  
  },[update])

  function handleDate(orderDate){
    const isoDate = orderDate;
const date = new Date(isoDate);

const year = date.getFullYear();
const month = date.getMonth() + 1;
const day = date.getDate();
const hours = date.getHours();
const minutes = date.getMinutes();
const seconds = date.getSeconds();
return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}
  

function changePage(page){
  window.scrollTo(0, 0);

  setLoading(true)
setPage(page)
getDocuments(token,page).then((response) => {
 
  setResp(response.data.data)

  if( response.data.data.toString()==[]){ //otherwise causes an error
    setDocumentsArr([])

   }
   else{

   setDocumentsArr(Object.values(response.data.data.data))
  }

  setLoading(false)
}).catch(function(error){
  if (error.response.status === 401) {
     history("/")
  }

});

}

    return (
    
      <div> 
      {modal?
      <LogoutModal setModal={setModal} setCartItem={props.setCartItem} />
      :null
     }
           {success? <SuccessModal setUpdate={setUpdate} update={update} setEdit={props.setEdit} setActive={props.setActive} type="document" txt={t("docs.success")} setModal={setSuccess}/>:null}

     {add?<AddDocument setModal={setAdd} setSuccess={setSuccess}/>:null}
      <div className={i18n.language=="ar"?"userProfile":"userProfile userProfileEn"}>
      {loading?
      <div style={{margin:"0",height:"300px"}} className='loader profileLoader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:
      <div>

  <div style={{boxShadow:" 0px 4px 12px 0px rgba(0, 0, 0, 0.10)"}}>
    <div className="buttonCard">
    <p  className="upTable-title"> {t("docs.myDocs")}</p>

<button onClick={()=>setAdd(true)}> {t("docs.request")} </button>


      </div>
   
{doumentsArr.length>0?
<div className="tarnsactionTableCard" style={{border:"none",boxShadow:"none",padding:"0"}}>
  <div className="tableContainer">

  <table>
  <thead className="table-header">
      <tr>
    <th>{t("docs.date")}</th>
    <th>{t("docs.type")}</th>
    <th>{t("docs.notes")}</th>
    <th>{t("docs.doc")}</th>

    </tr></thead> 
    <tbody>
      {doumentsArr && doumentsArr.map((data,index)=>
      
      <tr key={index}>
             <td>{handleDate(data.createdAt)}</td>
    <td>{data.type=="other"?t("docs.type1"):data.type=="association request"? t("docs.type2"):t("docs.type3")}</td>
    <td>{data.note==null?t("docs.none"):data.note}</td>
    <td style={{color:"#E47100"}}>{data.attachments==null?t("docs.waitting"):
   data.attachments && data.attachments.map((data,index)=>
    <a  key={index} className='docLink' href={data.attachment} target="_blank">
    <IoDocumentAttachOutline style={{cursor:"pointer"}} size={24}/>
    
    </a>
    )
    
    }</td>

   
      </tr>
      )}
     
  
    </tbody>
  </table></div>
  {resp.last_page>1?
  <Stack spacing={0}>
      <Pagination 
      renderItem={(item) => (
        <PaginationItem
          slots={{ previous: i18n.language=="ar"?'prevAr':"prevEn" , next: i18n.language=="ar"?'nextAr':"nextEn" }}
          {...item}
        />)}

      className={i18n.language=="ar"?'arArrow':null} defaultPage={1} page={page}  count={ resp.last_page} onChange={(e,value)=>{ changePage(value)}} shape="rounded" variant="outlined" 
      
      />
      </Stack>:null}

  </div>:
  
    <p style={{paddingBottom:"26px"}} className="noAddress">
   {t("docs.noDocs")}
    </p>
    }
    </div>


</div>
           


} 

</div>

         </div>

  
    );
  }
  
  export default Documents;