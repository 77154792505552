import '../../styles/UserProfile.css';

import { useTranslation } from 'react-i18next';
import axios from 'axios';

import ErrorModal from '../../components/ErrorModal';
import { GoogleMap,useLoadScript,Marker} from "@react-google-maps/api"

import {useState,useEffect,useRef}  from 'react';
import { useNavigate } from "react-router-dom";
import { branches,addAddress,areaByBranch} from '../../api';
import { BeatLoader } from 'react-spinners';
import Select from "react-select";
import { countries } from '../../api';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function AddAddress(props) {
  const {isLoaded}= useLoadScript({googleMapsApiKey:"AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw"
}) 

const markerRef=useRef()
    const { t, i18n } = useTranslation();
    const [address,setAddress]=useState({
      arAddress:"",
      enAddress:""
    })

    const[arMsg,setArMsg]=useState()
    const[enMsg,setEnMsg]=useState()
const[errModal,setErrModal]=useState(false)
const[city,setCity]=useState([])
const[country,setCountry]=useState()
const [position,setPostion]=useState(

  {
lat:23.8,
lng:45.0


  }
)  
const[selectedCity,setSelectedCity]=useState({
  arName: "",
  enName: ""})
const[selectedCountry,setSelectedCountry]=useState({
  arName: "",
  enName: ""})
const[bh,setBh]=useState(false)
const [areaValue,setAreaValue]=useState("")
const[areas,setAreas]=useState([])
const[branch,setBranch]=useState([])
const[selectedArea,setSelectedArea]=useState(null)

const [selectedBranch,setSelectedBranch]=useState("");
    const[loading,setLoading]=useState(true);
    const[submitLoading,setSubmitLoading]=useState(false);
     const streetRef=useRef()
     const noteRef=useRef()
     const floorRef=useRef()
     const appartRef=useRef()
     const buildRef=useRef()
     const titleRef=useRef()

   let history= useNavigate();
     


    
      //cities=> takes user token and returns cities
      useEffect(() => {
   
        const token= localStorage.getItem("token");
    countries(token).then((response) => {
        setCountry(response.data.data)
        setCity(response.data.data[0].cities)

        setSelectedCountry(  {
          arName: response.data.data[0].arName,
          enName: response.data.data[0].enName})

      setLoading(false)
      }).catch(function(error){
 
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("phone");
            localStorage.removeItem("name");
            localStorage.removeItem("fieldsArr")
          
            history("/")
         }
        
         
        
      
      });
      },[]) 






//takes(title, area id, branch id (required)) 
//and (floor no., building no., street name, appartment no. and address notes (not required))
//if success user is redirected to customer addresses
function onSubmit(e)

{
  console.log(position)

  e.preventDefault()
  
  setSubmitLoading(true)
   
    const token= localStorage.getItem("token");
if(address.arAddress==""){
  setArMsg("يرجى اختيار العنوان أولاً.  ")
  setEnMsg("Please choose address first")
    setErrModal(true);
    setSubmitLoading(false)
    setTimeout(function() {
    
      setErrModal(false)
    }, 2500)
}
else if (noteRef.current.value === "") {
    setArMsg("يرجى إدخال وصف العنوان أولاً.")
    setEnMsg("Please enter address description first")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
  
}

else {


 
    let values = {}
    
  
  values = {
    arAddress: `${address.arAddress}`+' - ملاحظات: '+noteRef.current.value,
    enAddress:address.enAddress+' - Notes : '+noteRef.current.value,
    longitude:position.lng,
    latitude:position.lat,
    title:"external sales"
   }

    addAddress(token,values).then((response) => {
        if (response.data.message === "success") {
          setArMsg(   "تم اضافة العنوان")
          setEnMsg( "Address has been added")
            setErrModal(true);
          
            setTimeout(function() {
      if(localStorage.getItem("prevScreen")=="order"){
        localStorage.removeItem("prevScreen")
        history(-1)
      }
      else{
        localStorage.removeItem("prevScreen")
        props.setAddAddress(false)
      }
            }, 2500)
          
          } else {
            setArMsg(   "حدث خطأ")
    setEnMsg( "Something went wrong")
      setErrModal(true);
      setSubmitLoading(false)
      setTimeout(function() {
      
        setErrModal(false)
      }, 2500)
        
        } 

    }).catch(function (error) {
 
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("phone");
          localStorage.removeItem("name");
          localStorage.removeItem("fieldsArr")
        
          history("/")
       }
      
       
     
      


    })
}
};

function onMarkerDragEnd(evt){
  var newLat = evt.latLng.lat();
  var newLng = evt.latLng.lng();
handleLocationChange(newLat,newLng)
 setPostion({

  lat:newLat,
  lng:newLng
 })

};


function handleLocationChange(lat,lng) {


axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat+ ',' +lng+ '&language=en&region=SA&key=AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw')
.then((response) => {
 
  setAddress(currentState => ({
    ...currentState,
    enAddress:  response.data.results[0].formatted_address,
  }));    
 
})
axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat+ ',' +lng+ '&language=ar&region=SA&key=AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw')
.then((response) => {
 

  setAddress(currentState => ({
    ...currentState,
    arAddress:  response.data.results[0].formatted_address,
  }));           
 
})




    }

    return (
      <div className={i18n.language=="ar"?'addrssAdd':"addrssAdd addrssAddEn"}>
         
          {errModal?<ErrorModal setModal={setErrModal} msg={i18n.language=="ar"?arMsg:enMsg}/>:null}
          {loading?
               <div style={{height:"80vh",margin:"0"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={loading}  size={30} /></div>:

<div style={{marginTop:"2em"}} className='container'>


<div className='customerBody addressAdd'>
   
{submitLoading?
               <div style={{height:"80vh",margin:"0"}} className='loader'>
      <BeatLoader color={'#E47100'} loading={submitLoading}  size={30} /></div>:null}

<div style={submitLoading?{display:"none"}:null}>
<p style={{marginBottom:"1em"}} className="addTitle">{t("profile.add_address")}</p>
<form onSubmit={(e)=>onSubmit(e)}>
<div className='row'>


 

<div className='col-12'>
<label>{t("profile.address_details")}</label>
<textarea ref={noteRef}/>
</div>
<div className='col-12'>

{isLoaded?
   <div style={{margin:"auto"}}>
   
           <GoogleMap  zoom={10} center={{lat:position.lat,lng:position.lng}} mapContainerClassName="map2"
          
           options={{
               mapTypeControl: false
           }}
           >
   <Marker   
   draggable={true}
   ref={markerRef}
   position={{lat:position.lat,lng:position.lng}} 
   
   onDragEnd={(e) =>onMarkerDragEnd(e)}
   
   />
   
           </GoogleMap>
           </div>:null}               
</div>


<div className="formBtns" style={{marginTop:"1em"}}>
<button type="submit" className="dataEdit">{t("profile.save")}</button>
<span> </span>
<button type="button" onClick={()=> {props.setAddAddress(false); localStorage.removeItem("prevScreen")}}  className="cancelEdit">{t("profile.cancel")}</button></div>


</div>
</form></div>
</div>

  
       </div>}</div>
    );
  }
   
  export default AddAddress;
  
  